import { useTranslation } from 'react-i18next';
import styles from './OpenAssignmentsOverview.module.scss';
import classNames from 'classnames';
import { ArrowLeftIcon, ArrowRightIcon, Button, Select, SelectOptionType } from '@bp/ui-components';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useMemo, useRef, useState } from 'react';
import { AssignmentsGantt } from '../AssignmentsGantt/AssignmentsGantt';
import { SubmissionStatus, useBpCalendarEventsQuery, useBpSubmissionsQuery } from 'client/bp-graphql-client-defs';
import { useParams } from 'react-router-dom';
import {
  compareDayMonthYear,
  dayjsToDayMonthYear,
  DayMonthYear,
  getTimespan,
  isBetween,
  isSame,
} from 'utils/dateCalculations';
import { GanttFlyoutEntry } from '../GanttFlyout/GanttFlyout';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useContainerDimensions } from 'utils/dimensions';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { getCategoryText, getRecurrenceEventText } from '../../utils/eventHelper';

// 0 = no bar, 1 = start, 2 = middle, 3 = end, 4 = both (start + end)
export type GanttBar = 0 | 1 | 2 | 3 | 4;

export type GanttDay = {
  dayMonthYear: DayMonthYear;
  date: Dayjs;
  events: GanttFlyoutEntry[];
  isToday?: boolean;
};

export type GanttAssignment = {
  key: string;
  title?: string;
  status?: SubmissionStatus;
  span?: DayMonthYear[];
  bars?: GanttBar[];
};

type PartialDay =
  | {
      uuid: string;
      date: string;
    }
  | null
  | undefined;

export type OpenAssignmentsType = {
  uuid: string;
  title: string;
  visibleFrom?: PartialDay;
  visibleTo?: PartialDay;
  dueDate?: PartialDay;
  created: string;
};

type OpenAssignmentsOverviewProps = {
  assignments: OpenAssignmentsType[];
  daysToShow?: number;
  onNavigate: (uuid: string) => void;
};

export const OpenAssignmentsOverview: FC<OpenAssignmentsOverviewProps> = ({
  onNavigate,
  assignments,
  daysToShow = 7,
}) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('EVENT');
  const { courseUuid } = useParams<{ courseUuid: string }>();
  const submissionsContext = useMemoizedCacheTag('SUBMISSION');

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);
  const isTablet = width < 1175;

  const today = dayjs();
  const [weekStart, setWeekStart] = useState(today.weekday(0));
  const [sorting, setSorting] = useState<'byDue' | 'byCreated'>('byDue');

  const weekEnd = weekStart.add(daysToShow, 'days').startOf('day');
  const start = weekStart.startOf('day');

  const sortOptions: SelectOptionType[] = [
    {
      label: t('assignments.sortByDue'),
      value: 'byDue',
    },
    {
      label: t('assignments.sortByCreated'),
      value: 'byCreated',
    },
  ];

  const [{ data: submissionsQueryResult }] = useBpSubmissionsQuery({
    variables: {
      where: {
        owner: { uuid: pimAuthClaims.getProfile().uuid },
        assignment: {
          uuid_IN: assignments.map((a) => a.uuid),
        },
      },
    },
    context: submissionsContext,
  });

  const ownerSubmissions = submissionsQueryResult?.submissions;

  const [{ data: eventsData }] = useBpCalendarEventsQuery({
    variables: {
      where: courseUuid
        ? {
            holderConnection: {
              Group: {
                node: {
                  uuid: courseUuid,
                },
              },
            },
          }
        : {
            organization: {
              uuid: organizationUuid,
            },
            attendees_SOME: {
              uuid: pimAuthClaims.getProfile().uuid,
            },
          },
    },
    context: context,
  });

  const events: GanttFlyoutEntry[] = useMemo(() => {
    return (
      eventsData?.calendarEvents
        .filter(
          (e) =>
            !e.categories?.includes('lesson') &&
            dayjs(e.start).isBefore(weekEnd) &&
            dayjs(e.start)
              .add(parseInt(e.duration ?? '') ?? 0, 'seconds')
              .isAfter(start),
        )
        .map((event) => {
          const start = dayjs(event.start);
          const end = dayjs(event.start).add(parseInt(event.duration ?? '') ?? 0, 'seconds');
          return {
            uuid: event.uuid,
            title: event.title ?? '',
            category: getCategoryText(event.categories ?? []),
            description: event.description ?? '',
            startDate: `${dayjsToDayMonthYear(start).name}, ${start.format('DD.MM.YYYY')}`,
            endDate: `${dayjsToDayMonthYear(end).name}, ${end.format('DD.MM.YYYY')}`,
            startTime: `${start.format('HH:mm')}`,
            endTime: `${end.format('HH:mm')}`,
            start: start.toDate(),
            end: end.toDate(),
            recurrence: getRecurrenceEventText(event.recurrenceRule?.frequency),
          };
        }) ?? []
    );
  }, [eventsData?.calendarEvents, start, weekEnd]);

  const timespan: GanttDay[] = useMemo(() => {
    const days: Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      days.push(weekStart.add(i, 'day'));
    }
    return days.map((day) => {
      return {
        dayMonthYear: dayjsToDayMonthYear(day, isTablet),
        date: day,
        events: events.filter((e) => {
          return isSame(e.start, day) || isBetween(day, e.start, e.end) || isSame(e.end, day);
        }),
        isToday: day.isToday() ? day.isToday() : undefined,
      };
    });
  }, [isTablet, weekStart, events]);

  const ganttAssignments: GanttAssignment[] = useMemo(() => {
    return assignments
      .filter((a) => {
        const startDay = dayjs(a.visibleFrom?.date).startOf('day');
        return startDay.isBefore(today.add(1, 'day').startOf('day'));
      })
      .sort((a, b) => {
        const compA = dayjs(sorting === 'byDue' ? a.dueDate?.date : a.visibleFrom?.date);
        const compB = dayjs(sorting === 'byDue' ? b.dueDate?.date : b.visibleFrom?.date);
        if (compA.isBefore(compB)) return -1;
        else if (compB.isBefore(compA)) return 1;
        return 0;
      })
      .map((assignment) => {
        const from = dayjs(assignment.visibleFrom?.date);
        const due = dayjs(assignment.dueDate?.date);
        const span = getTimespan(from, due);
        // get bar segments
        const bars: GanttBar[] = [];
        timespan.forEach((weekDay) => {
          const weekDate = weekDay.dayMonthYear;
          let day: DayMonthYear | null = null;
          span.forEach((date) => {
            const isInSpan = compareDayMonthYear(weekDate, date);

            if (isInSpan) {
              day = date;
              return;
            }
          });
          if (day) {
            const isStart = compareDayMonthYear(span[0], day);
            const isEnd = compareDayMonthYear(span[span.length - 1], day);
            if (isStart && isEnd) bars.push(4);
            else if (isStart) bars.push(1);
            else if (isEnd) bars.push(3);
            else bars.push(2);
          } else {
            bars.push(0);
          }
        });

        // get status from Submission
        const status =
          ownerSubmissions?.find((submission) => submission.assignment[0].uuid === assignment.uuid)?.status ??
          SubmissionStatus.New;

        // return calculated GanttAssignment
        return {
          key: assignment.uuid,
          title: assignment.title,
          status: status,
          span: span,
          bars: bars,
        };
      });
  }, [ownerSubmissions, assignments, sorting, timespan, today]);

  const classes = classNames(styles['open-assignments-overview'], { [styles.tablet]: isTablet });
  return (
    <div className={classes} ref={ref}>
      <div className={styles.header}>
        <div className={styles.date}>
          <Button
            disabled={weekStart.year() === today.year() && weekStart.subtract(1, 'week').week() < today.week()}
            onClick={() => setWeekStart(weekStart.subtract(1, 'week').weekday(0))}
            hierarchy='tertiary'
            icon={<ArrowLeftIcon />}
          />
          <div className={styles.week}>{`${weekStart.format('DD.MM.YY')} - ${weekStart
            .add(6, 'day')
            .format('DD.MM.YY')}`}</div>
          <Button
            onClick={() => setWeekStart(weekStart.add(1, 'week').weekday(0))}
            hierarchy='tertiary'
            icon={<ArrowRightIcon />}
          />
        </div>
        <div className={styles.actions}>
          <Button
            disabled={weekStart.year() === today.year() && weekStart.week() === today.week()}
            onClick={() => setWeekStart(today.weekday(0))}
            className={styles['today-button']}
            hierarchy='secondary'
          >
            {t('common.today')}
          </Button>
          <Select
            className={styles.select}
            dense
            name='sorting'
            onChange={(value) => {
              setSorting((value as SelectOptionType).value as 'byDue' | 'byCreated');
            }}
            options={sortOptions}
            defaultValue={sortOptions[0]}
          />
        </div>
      </div>
      <div className={classNames(styles.days, 'has-scrollbar')}>
        {timespan.map((day) => {
          const date = day.dayMonthYear;
          return (
            <div
              key={`${date.day}${date.month}${date.year}`}
              className={classNames(styles.day, {
                [styles.active]: day.isToday,
              })}
            >
              <div>{date.name}</div>
              <div className={styles.bold}>{`${dayjs(day.date).format('DD')}.`}</div>
            </div>
          );
        })}
      </div>
      <AssignmentsGantt
        mode={isTablet ? 'tablet' : 'desktop'}
        timespan={timespan}
        assignments={ganttAssignments}
        onNavigate={onNavigate}
      />
    </div>
  );
};
