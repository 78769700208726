import React, { memo, useState } from 'react';
import classNames from 'classnames';
import styles from './SidebarWrapper.module.scss';
import { Sidebar } from '../Sidebar/Sidebar';

export const SidebarWrapper = memo(({ children }: { children: React.ReactNode }) => {
  const [isFixed, setIsFixed] = useState(false);

  const classes = classNames(styles['sidebar-wrapper'], { [styles.fixed]: isFixed });

  return (
    <div className={classes} data-cy='layoutWrapper'>
      <Sidebar onFixed={setIsFixed} />
      {children}
    </div>
  );
});
SidebarWrapper.displayName = 'SidebarWrapper';
