import styles from './MeetingsLinks.module.scss';
import classNames from 'classnames';
import { MeetingActiveIcon, MeetingDefaultIcon, MessagesDefaultIcon, SpinnerIcon } from '@bp/ui-components';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { InstantMeetingModal } from '../Modals/InstantMeetingModal';
import React, { useState } from 'react';
import { BpEventType } from '../EventsList/EventsList';
import { backendApi } from '../../utils/backendApi';
import { GroupRoles } from '@bp/pim-auth-constants';
import { showErrorToast } from '../../utils/showErrorToast';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { AvailableService } from '../../hooks/useAvailableServicesForOrganization';

type MeetingsLinksProps = {
  groupUuid: string;
  onLessonClick: (() => void) | null;
  onMessageClick: (() => void) | null;
  runningEvent?: BpEventType;
  hasBlockingEvents?: boolean;
  availableServices: AvailableService[];
};

export const MeetingsLinks = ({
  groupUuid,
  onLessonClick,
  onMessageClick,
  runningEvent,
  hasBlockingEvents = false,
  availableServices,
}: MeetingsLinksProps) => {
  const matrixAvailable = useMatrixAvailable();
  const [instantMeetingModalOpen, setInstantMeetingModalOpen] = useState<boolean>(false);
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);

  const joinRunningMeeting = async () => {
    setLoading(true);
    const onlineLocation = runningEvent?.virtualLocations[0];
    if (onlineLocation) {
      const joinUrl = await backendApi.joinConference(onlineLocation.token, {
        displayName: pimAuthClaims.getProfile().displayName ?? '',
        email: pimAuthClaims.getProfile().email ?? '',
        profileUuid: pimAuthClaims.getProfile().uuid ?? '',
        moderator:
          pimAuthClaims
            .getRoles()
            .find(
              (r) =>
                r.target.uuid === runningEvent?.originUuid &&
                (r.name.includes(GroupRoles.Admin) || r.name.includes(GroupRoles.Editor)),
            ) !== undefined,
      });

      const url = await joinUrl.text();
      if (url.includes('https://')) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      } else {
        showErrorToast(t('meetings.noVirtualLocation'));
      }
    } else {
      showErrorToast(t('meetings.noVirtualLocation'));
    }
    setLoading(false);
  };

  return (
    <>
      <div className={styles['meetings-links']}>
        {onLessonClick && runningEvent === undefined && !hasBlockingEvents && (
          <div
            className={styles.link}
            onClick={() => {
              setInstantMeetingModalOpen(!instantMeetingModalOpen);
            }}
          >
            <MeetingDefaultIcon className={classNames('svg-icon', styles.icon)} />
            <div className={styles.text}>{t('meetings.onlineLesson')}</div>
          </div>
        )}

        {isLoading && (
          <div className={`${styles.link} ${styles.disabled}`}>
            <SpinnerIcon className={'svg-icon large spinning'} />
          </div>
        )}

        {!isLoading && (runningEvent || hasBlockingEvents) && (
          <div className={`${styles.link} ${styles.disabled}`}>
            <MeetingActiveIcon className={classNames('svg-icon', styles.icon)} />
            {runningEvent && (
              <div onClick={joinRunningMeeting} className={styles.text}>
                {t('meetings.running')}
              </div>
            )}
            {!runningEvent && hasBlockingEvents && (
              <div className={`${styles.text} ${styles.center}`}>{t('meetings.blocked')}</div>
            )}
          </div>
        )}

        {matrixAvailable && onMessageClick && (
          <div className={styles.link} onClick={onMessageClick}>
            <MessagesDefaultIcon className={classNames('svg-icon', styles.icon)} />
            <div className={styles.text}>{t('meetings.newMessage')}</div>
          </div>
        )}
      </div>

      {availableServices.length > 0 && (
        <InstantMeetingModal
          groupUuid={groupUuid}
          setInstantMeetingModalOpen={setInstantMeetingModalOpen}
          showModal={instantMeetingModalOpen}
          availableServices={availableServices}
        />
      )}
    </>
  );
};
