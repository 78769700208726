import { Assignment, SubmissionStatus, TeachingUnit } from '@bp/bp-graphql-types';
import { EmptyState, Grid, GridRow, NoTasksIcon } from '@bp/ui-components';
import { useBpGetAssignmentsByGroupQuery, useBpSubmissionsQuery } from '../../../../client/bp-graphql-client-defs';
import { AssignmentsTableStudent } from '../../../../components/AssignmentsTable/AssignmentsTableStudent';
import { AssignmentPreviewStudent } from '../../../../components/AssignmentPreview/AssignmentPreviewStudent';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { BpCard } from '../../../../components/BpCard/BpCard';
import styles from './StudentContent.module.scss';
import classNames from 'classnames';
import { BpHeader } from 'components/BpHeader/BpHeader';
import { useAutoUpdateQuery } from '../../../../hooks/useAutoUpdateQuery';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import dayjs from 'dayjs';
import { useDays } from '../../../../hooks/useDays';

export interface AssignmentWithStatus extends Assignment {
  status?: SubmissionStatus;
}

export const CourseAssignmentsStudentContent: FC = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const profileUuid = pimAuthClaims.getProfile().uuid;
  const { courseUuid } = useParams<{ courseUuid: string }>();
  const { isPast, today } = useDays();

  const navigate = useNavigate();

  const context = useMemoizedCacheTag('ASSIGNMENT');

  const [{ data, error }] = useAutoUpdateQuery(
    useBpGetAssignmentsByGroupQuery({
      context: context,
      variables: {
        where: {
          uuid: courseUuid,
        },
        teachingUnitWhere: { active: true },
        submissionWhere: { owner: { uuid: profileUuid } },
      },
    }),
    30,
  );

  error && showErrorToast(error);

  const [submissions] = useAutoUpdateQuery(
    useBpSubmissionsQuery({
      context: context,
      variables: {
        where: {
          owner: {
            uuid: pimAuthClaims.getProfile().uuid ?? '',
          },
          assignment: {
            uuid_IN: data?.groups[0]?.teachingUnits?.flatMap((t: TeachingUnit) => t.assignments.map((a) => a.uuid)),
          },
        },
      },
    }),
    30,
  );

  submissions.error && showErrorToast(submissions.error);

  const activeAssignments: AssignmentWithStatus[] = [];
  const pastAssignments: AssignmentWithStatus[] = [];

  const assignments = data?.groups[0]?.teachingUnits?.map((t: TeachingUnit) => t.assignments).flat() ?? [];

  assignments.forEach((assignment: Assignment) => {
    if (isPast(assignment.dueDate)) {
      pastAssignments.push(assignment);
    }
    if (
      dayjs(assignment.dueDate?.date).isSameOrAfter(today?.date, 'day') &&
      dayjs(assignment.visibleFrom?.date).isSameOrBefore(today?.date, 'day')
    ) {
      activeAssignments.push(assignment);
    }
  });

  const handleAssignmentClick = (uuid: string) => {
    navigate(`/courses/${courseUuid}/assignments/${uuid}`);
  };

  return (
    <div className={styles['course-assignments-student']}>
      <Grid>
        <GridRow>
          <BpHeader headline={t('assignments.active')} noMargin />
        </GridRow>
        <GridRow>
          <div className={classNames(styles['assignment-previews'])}>
            {activeAssignments.length === 0 ? (
              <EmptyState
                fitParent
                size='small'
                backgroundColor='var(--color-white)'
                subtitle={t('assignments.noAssignments')}
                iconColor='var(--color-primary-light)'
                icon={<NoTasksIcon />}
                padding='none'
                forcedWidth='350px'
                forcedHeight='167px'
              />
            ) : (
              activeAssignments.map((activeAssignment) => {
                return (
                  <AssignmentPreviewStudent
                    key={activeAssignment.uuid}
                    assignment={activeAssignment}
                    onNavigate={(uuid) => navigate(`${uuid}`)}
                  />
                );
              })
            )}
          </div>
        </GridRow>
        <GridRow>
          <BpCard header={{ headline: t('assignments.finishedAssignments') }} noPadding noMargin={false}>
            <AssignmentsTableStudent data={pastAssignments} onAssignmentClick={handleAssignmentClick} />
          </BpCard>
        </GridRow>
      </Grid>
    </div>
  );
};
