import { useDeleteBpSubjectMutation, useSubjectsQuery } from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { Button, DotsVerticalIcon, Dropdown, DropdownMenu, DropdownMenuItem, Modal, Table } from '@bp/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useConfirm } from '../../../hooks/useConfirm';
import { useTranslation } from 'react-i18next';
import { SubjectForm } from '../../../components/SubjectEditForm/SubjectForm';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import type { Subject } from '@bp/bp-graphql-types';

export type SubjectType = Pick<Subject, 'uuid' | 'name' | 'globalSubject'>;

export const InstitutionSubjectsSubpage = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
    defaultConfirmText: t('delete.confirmText'),
  });
  const context = useMemoizedCacheTag('SUBJECT');

  const [{ data: subjectsData }] = useSubjectsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  const subjects = subjectsData?.subjects ?? [];

  const [, deleteSubject] = useDeleteBpSubjectMutation();

  const [currentSubject, setCurrentSubject] = useState<SubjectType | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const tableColumns = useMemo(() => {
    return [
      {
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        canExpand: true,
      },
      {
        header: t('subjects.globalSubject'),
        id: 'globalSubject',
        accessorKey: 'globalSubject',
        size: 400,
      },
      {
        header: t('institution.dataSource'),
        id: 'managedBy',
        accessorKey: 'managedBy',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = useCallback((subject: SubjectType) => {
    setCurrentSubject(subject);
    setModalOpen(true);
  }, []);

  const memoizedHandleDelete = useCallback(
    async (subject: SubjectType) => {
      if (perms?.canDeleteSubject({ uuid: organizationUuid })) {
        const { error } = await deleteSubject({ uuid: subject.uuid }, context);
        error ? showErrorToast(error) : showSuccessToast(t('subjects.deleted'));
      }
    },
    [context, deleteSubject, organizationUuid, perms, t],
  );

  const dropDownMenu = (subject: SubjectType): DropdownMenuItem[] => {
    return [
      { label: t('common.edit'), onClick: () => handleEdit(subject) },
      {
        label: t('delete.title'),
        color: 'error',
        onClick: async () => {
          const res = await confirmDelete();
          if (res) {
            await memoizedHandleDelete(subject);
          }
        },
      },
    ];
  };

  const handleClose = () => {
    setCurrentSubject(undefined);
    setModalOpen(false);
  };

  return (
    <BpSubpage isForbidden={!perms?.canListSubjects({ uuid: organizationUuid })}>
      {pimAuthClaims.getOrganization() && (
        <Table
          breakpoint='580px'
          data-cy={'groupList'}
          showActionBar
          showBorderRadius
          showShadow
          isOnWhite={false}
          actionBarSettings={{
            showAddButton: perms?.canCreateSubject({ uuid: organizationUuid }),
            showExpertFilter: true,
            addButtonText: t('common.addType', { type: t('subjects.titleSingular') }),
          }}
          sorting={[{ id: 'name', desc: false }]}
          onAddClick={() => {
            setModalOpen(true);
          }}
          columns={tableColumns}
          data={subjects}
          lastCol={(row) => {
            return perms?.canDeleteSubject(pimAuthClaims.getOrganization()) ? (
              <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                <DropdownMenu data={dropDownMenu(row.original)} />
              </Dropdown>
            ) : null;
          }}
        />
      )}

      <Modal title={t('subjects.settings')} isOpen={modalOpen} onRequestClose={handleClose} width='s'>
        <SubjectForm closeModal={handleClose} subject={currentSubject} />
      </Modal>

      <ConfirmationDialog />
    </BpSubpage>
  );
};
