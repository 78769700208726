import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { BpSubmissionsDocument, BpSubmissionsQuery, useBpProfileQuery } from '../../client/bp-graphql-client-defs';
import { BpCard } from '../BpCard/BpCard';
import { useTranslation } from 'react-i18next';
import { EmptyState, Grid, GridRow, InvisibleIcon } from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useQuery } from 'urql';
import { FileTable } from '../FileTable/FileTable';
import { BpHeader } from '../BpHeader/BpHeader';
import { BpTipTapText } from 'components/BpTipTapText/BpTipTapText';

export const PublicSubmission: FC = () => {
  const { submissionUuid } = useParams();

  const { t } = useTranslation();
  const context = useMemoizedCacheTag('SUBMISSION');
  const [{ data }] = useQuery<BpSubmissionsQuery>({
    query: BpSubmissionsDocument,
    variables: {
      where: {
        public: true,
        uuid: submissionUuid,
      },
    },
    context,
  });

  const currentSubmission = data?.submissions[0];

  const material = data?.submissions[0]?.material ?? {
    text: t('workmaterials.noWorkmaterials'),
    fileEntries: [],
  };

  const profileContext = useMemoizedCacheTag('PROFILE');
  const [profile] = useBpProfileQuery({
    variables: { where: { uuid: data?.submissions[0]?.owner.uuid ?? null } },
    context: profileContext,
  });

  const name = profile.data?.profiles[0]?.displayName;

  return (
    <>
      <BpHeader headline={name ?? ''} />
      <Grid>
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('common.answer'),
            }}
          >
            {!currentSubmission ? (
              <EmptyState icon={<InvisibleIcon />} title={t('answers.noAnswers')} padding='l' forcedHeight='30vh' />
            ) : (
              <>
                {material.text && <BpTipTapText className='mb-4' content={material.text} />}
                <FileTable mode={'show'} files={material.fileEntries ?? []} isGroupEditor={false} />
              </>
            )}
          </BpCard>
        </GridRow>
      </Grid>
    </>
  );
};
