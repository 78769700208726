import { FC, useMemo } from 'react';
import { Assignment } from '@bp/bp-graphql-types';
import { useTranslation } from 'react-i18next';
import { Row, Table, TableColumns, useDefaultSorting } from '@bp/ui-components';
import { niceDate } from '../../utils/dateCalculations';
import { StatusChip } from '../StatusChip/StatusChip';
import styles from './AssignmentsTable.module.scss';
import { BpLink } from 'components/BpLink/BpLink';

type AssignmentsTableProps = {
  data: Assignment[];
  onAssignmentClick: (uuid: string) => void;
};

export const AssignmentsTableStudent: FC<AssignmentsTableProps> = ({ data, onAssignmentClick }) => {
  const { t } = useTranslation();

  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'title', desc: false }]);

  const createColumns = (): TableColumns<Assignment>[] => {
    return [
      {
        id: 'title',
        header: t('common.name'),
        accessorKey: 'title',
        size: 200,
        canExpand: true,
        cell: ({ row }: { row: Row<Assignment> }) => {
          return (
            <BpLink
              value={row.original.title}
              allowAfterDue={row.original.allowSubmissionAfterDueDate ?? false}
              onNavigate={() => onAssignmentClick(row.original.uuid)}
              isEditorOfCourse={false}
            />
          );
        },
      },
      {
        id: 'visibleFrom',
        accessorKey: 'visibleFrom',
        header: t('common.activeFrom'),
        size: 200,
        cell: ({ cell }) => {
          return niceDate(cell.getValue(), 'medium');
        },
      },
      {
        id: 'dueDate',
        accessorKey: 'dueDate',
        header: t('common.due'),
        size: 200,
        cell: ({ cell }) => {
          return niceDate(cell.getValue(), 'medium');
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: t('common.status'),
        size: 200,
        cell: ({ row }: { row: Row<Assignment> }) => {
          if (row.original.submissions[0])
            return <StatusChip status={row.original.submissions[0].status} isEditorOfCourse={false} />;

          return <></>;
        },
      },
    ];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(createColumns, []);
  const tableData = useMemo((): Assignment[] => data, [data]);

  return (
    <Table<Assignment>
      className={styles['assignments-table']}
      showSort
      showActionBar={true}
      actionBarSettings={{
        marginBottom: 'var(--spacing-3)',
      }}
      sorting={sorting}
      onSortingChange={onSortingChange}
      columns={memoizedColumns}
      data={tableData}
      hideHeader={tableData.length === 0}
      customPadding='var(--spacing-7)'
      emptyStateSettings={{
        title: t('assignments.noAssignmentsInTimespan'),
        subtitle: '',
        hideIcon: true,
        padding: 'xl',
      }}
    />
  );
};
