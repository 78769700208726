import { CombinedProfile } from 'pages/Profile/ProfilePage';
import styles from './AccessOverview.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type AccessOverviewProps = {
  combinedProfile: CombinedProfile;
};

export const AccessOverview = ({ combinedProfile }: AccessOverviewProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['access-overview']}>
      {!combinedProfile.threemaId && (
        <div className={classNames(styles.account, styles.threema)}>
          <div className={styles.header}>Threema</div>
          <div className={styles.credentials}>
            <div>
              <div className={styles.description}>{t('common.username')}:</div>
              {combinedProfile.workUser}
            </div>
            <div>
              <div className={styles.description}>{t('common.password')}:</div>
              {combinedProfile.workPass}
            </div>
            <div>
              <div className={styles.description}>Threema ID:</div>
              {combinedProfile.threemaId}
            </div>
          </div>
        </div>
      )}
      <div className={styles.account}>
        <div className={styles.header}>{t('app.download')}</div>
        <div className={styles.credentials}>
          <div>
            <a className={classNames(styles.link, styles.google)} href='https://play.google.com'>
              <img src='/app-store-google.png' alt='a link to the Google play store' />
            </a>
            <a className={classNames(styles.link, styles.apple)} href='https://www.apple.com/app-store/'>
              <img src='/app-store-apple.svg' alt='a link to the Apple app store' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
