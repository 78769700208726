import { urqlClient } from '../urqlClient';
import {
  BpAssignmentsDocument,
  BpAssignmentsQuery,
  BpAssignmentsQueryVariables,
} from '../../client/bp-graphql-client-defs';

export const assignmentLoader = (assignmentUuid?: string) => {
  return urqlClient
    .query<BpAssignmentsQuery, BpAssignmentsQueryVariables>(
      BpAssignmentsDocument,
      {
        where: { uuid: assignmentUuid },
      },
      { additionalTypenames: ['BP_Assignment'] },
    )
    .toPromise();
};
