import { Form, Formik } from 'formik';
import {
  useCreateThreemaBroadcastssMutation,
  useThreemaBroadcastsQuery,
  useUpdateThreemaBroadcastssMutation,
} from '../../client/bp-graphql-client-defs';
import { DatePicker, Input } from '@bp/ui-components';
import { LicensedProduct } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { ensureDate } from '../../utils/dateCalculations';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { validationSchema } from './threemaBroadcastFormScheme';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';

type ThreemaValuesType = {
  broadcastId: string | null;
  broadcastApiToken: string | null;
  notBefore: string;
  notAfter: string;
};

type ThreemaBroadcastFormProps = { data: LicensedProduct; closeModal: () => void };

export const ThreemaBroadcastForm = ({ data, closeModal }: ThreemaBroadcastFormProps) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('PRODUCTS');

  const [threemaBroadcastsQueryResult] = useThreemaBroadcastsQuery({
    variables: { where: { uuid: data.uuid ?? '0' } },
    context,
  });
  const currentBooking = threemaBroadcastsQueryResult.data?.threemaBroadcasts.shift();

  const [, updateThreemaBroadcasts] = useUpdateThreemaBroadcastssMutation();
  const [, createThreemaBroadcasts] = useCreateThreemaBroadcastssMutation();
  const handleClose = () => {
    closeModal();
  };

  const initialValues: ThreemaValuesType = {
    broadcastId: currentBooking?.thremaId ?? '',
    broadcastApiToken: currentBooking?.apiToken ?? '',
    notBefore: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notBefore).toUTCString(),
    notAfter: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notAfter).toUTCString(),
  };

  const onSubmit = async (values: ThreemaValuesType) => {
    if (perms?.canUpdateThreemaBroadcast({ uuid: organizationUuid })) {
      if (data.uuid === undefined) {
        const resp = await createThreemaBroadcasts(
          {
            input: [
              {
                apiToken: values.broadcastApiToken,
                thremaId: values.broadcastId,
                organization: {
                  connect: {
                    overwrite: true,
                    edge: {
                      notAfter: values.notAfter,
                      notBefore: values.notBefore,
                    },
                    where: { node: { uuid: organizationUuid } },
                  },
                },
              },
            ],
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      } else {
        const resp = await updateThreemaBroadcasts(
          {
            update: {
              apiToken: values.broadcastApiToken,
              thremaId: values.broadcastId,
              organization: {
                update: {
                  edge: {
                    notAfter: values.notAfter,
                    notBefore: values.notBefore,
                  },
                },
              },
            },
            where: { uuid: data.uuid },
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        const onClose = () => {
          resetForm();
          handleClose();
        };

        return (
          <>
            <Form>
              <DatePicker
                label={t('institution.bookedFromDate')}
                onChange={(e) => setFieldValue('notBefore', e?.toUTCString())}
                value={ensureDate(values.notBefore)}
                name={'notBefore'}
                showMonthYearDropdown
              />

              <DatePicker
                label={t('institution.bookedUntilDate')}
                onChange={(e) => setFieldValue('notAfter', e?.toUTCString())}
                value={ensureDate(values.notAfter)}
                name={'notAfter'}
                showMonthYearDropdown
              />

              <Input
                className='mb-2'
                label={t('settings.threemaBroadcastId')}
                name={'broadcastId'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.broadcastId?.toString()}
                error={errors.broadcastId}
              />

              <Input
                className='mb-2'
                label={t('settings.threemaBroadcastApiToken')}
                name={'broadcastApiToken'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.broadcastApiToken?.toString()}
                error={errors.broadcastApiToken}
              />

              <ModalBottomButtons errors={errors} closeButton={{ callback: onClose }} isLoading={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
