import styles from './ProfileSelection.module.scss';
import { ArrowHeadUpIcon, Checkbox } from '@bp/ui-components';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '@bp/bp-graphql-types';

type ProfileSelectionListHeadProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortDirChange: (direction: SortDirection) => void;
  sortDir: SortDirection;
};

export const ProfileSelectionListHead: FC<ProfileSelectionListHeadProps> = ({ onChange, sortDir, sortDirChange }) => {
  const { t } = useTranslation();

  const handleSorting = () => {
    sortDirChange(sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc);
  };

  return (
    <div className={styles['list-head']}>
      <Checkbox name='select-all' onChange={onChange} />
      <div className={styles.sorting} onClick={handleSorting}>
        {t('common.name')}
        <ArrowHeadUpIcon
          className={classNames([
            {
              [styles.rotate180]: sortDir === SortDirection.Desc,
            },
          ])}
        />
      </div>
    </div>
  );
};
