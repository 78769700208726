export enum Browsers {
  Firefox,
  Chrome,
  Safari,
  Unknown,
}

export function getBrowser(): Browsers {
  const isFirefox = () => {
    return agentHas('Firefox') || agentHas('FxiOS') || agentHas('Focus');
  };

  const isChrome = () => {
    return agentHas('CriOS') || agentHas('Chrome');
  };

  const isSafari = () => {
    return agentHas('Safari') && !agentHas('Chrome') && !agentHas('CriOS');
  };

  const agentHas = (keyword: string) => {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
  };

  let browser: Browsers = Browsers.Unknown;
  if (isFirefox()) browser = Browsers.Firefox;
  else if (isChrome()) browser = Browsers.Chrome;
  else if (isSafari()) browser = Browsers.Safari;

  return browser;
}
