import { Button } from '@bp/ui-components';
import classNames from 'classnames';
import { BpActionButton } from 'components/BpCard/BpCard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ModalBottomButtons.module.scss';
import { useWindowDimensions } from 'utils/dimensions';

type ModalBottomButtonsProps = {
  closeButton: BpActionButton;
  submitButton?: BpActionButton;
  additionalButtons?: BpActionButton[];
  isModal?: boolean;
  isLoading: boolean;
  errors: unknown;
  className?: string | undefined;
};

export const ModalBottomButtons: FC<ModalBottomButtonsProps> = ({
  closeButton,
  submitButton,
  additionalButtons,
  isModal = false,
  isLoading = false,
  errors,
  className,
}) => {
  const { t } = useTranslation();

  const { isPhone, isTablet } = useWindowDimensions();
  const isMobile = isPhone || isTablet;

  const classes = classNames(styles['modal-bottom-buttons'], { 'bp__modal-footer': isModal }, className);

  return (
    <div className={classes}>
      <Button
        hierarchy={isMobile ? 'secondary' : 'tertiary'}
        onClick={closeButton.callback}
        onKeyUp={(e) => e.key === 'Escape'}
      >
        {closeButton.text ?? t('common.cancel')}
      </Button>
      {additionalButtons?.map((button, index) => {
        return (
          <Button
            key={index}
            disabled={button.disabled}
            icon={button.icon}
            iconPosition={button.iconPosition ?? 'left'}
            hierarchy={isMobile ? 'secondary' : (button.hierarchy ?? 'secondary')}
            onClick={button.callback}
            type='button'
          >
            {button.text}
          </Button>
        );
      })}
      <Button
        isLoading={isLoading}
        type='submit'
        hierarchy='primary'
        disabled={(errors ? Object.values(errors).length > 0 : false) || submitButton?.disabled}
        {...(submitButton?.callback ? { onClick: submitButton.callback } : {})}
        onKeyUp={(e) => e.key === 'Enter'}
      >
        {submitButton?.text ?? t('common.save')}
      </Button>
    </div>
  );
};
