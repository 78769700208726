import { Button } from '@bp/ui-components';
import { useBpAssignmentsQuery } from '../../../client/bp-graphql-client-defs';
import { AssignmentForm } from '../../../components/AssignmentForm/AssignmentForm';
import { BpCard } from '../../../components/BpCard/BpCard';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CourseAssignmentStudentContent } from './StudentContent/CourseAssignmentStudentContent';
import { CourseAssignmentTeacherContent } from './TeacherContent/CourseAssignmentTeacherContent';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { type Submission } from '@bp/bp-graphql-types';

export const CourseAssignmentSubpage: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const perms = usePermissionChecker();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assignmentUuid, courseUuid } = useParams<{ assignmentUuid: string; courseUuid: string }>();
  const context = useMemoizedCacheTag('ASSIGNMENT');
  const [{ data }] = useBpAssignmentsQuery({
    variables: {
      where: {
        uuid: assignmentUuid,
      },
    },
    context,
  });
  const teachingUnitUuid = data?.assignments[0]?.holder.uuid ?? null;

  const [edit, setEdit] = useState(false);
  const onEdit = () => setEdit(true);
  const handleClose = () => setEdit(false);

  const onSubmissionClick = (submission: Submission) => {
    navigate(`/courses/${courseUuid}/assignments/${assignmentUuid}/submissions/${submission.uuid}`);
  };

  const navigateToPublicSubmission = (submission: Submission) => {
    navigate(`/courses/${courseUuid}/assignments/${assignmentUuid}/submissions/public/${submission.uuid}`);
  };

  return edit && teachingUnitUuid ? (
    <AssignmentForm
      courseUuid={courseUuid}
      assignmentUuid={assignmentUuid}
      onClose={() => handleClose()}
      currentTeachingUnitUuid={teachingUnitUuid}
      context={context}
      isGroupEditor={
        // TODO: load organization from course
        perms?.isCourseEditor({ uuid: courseUuid ?? '', organization: pimAuthClaims.getOrganization() }) ?? false
      }
    />
  ) : !edit && assignmentUuid ? (
    <>
      {perms?.canCreateAssignment({
        uuid: courseUuid ?? '',
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      }) ? (
        <CourseAssignmentTeacherContent
          assignmentUuid={assignmentUuid}
          onEdit={onEdit}
          onSubmissionClick={onSubmissionClick}
          navigate={navigate}
        />
      ) : (
        <CourseAssignmentStudentContent assignmentUuid={assignmentUuid} navigate={navigateToPublicSubmission} />
      )}
    </>
  ) : (
    <BpCard>
      <div>
        <div>Error: no Assignment- or TeachingUnit-UUID given!</div>
        <Button onClick={() => navigate(`/courses/${courseUuid} `)}>{t('common.back')}</Button>
      </div>
    </BpCard>
  );
};
