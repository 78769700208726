import i18next from 'i18next';

import { AdminRoles, BpAdminRoles } from '@bp/pim-auth-constants';

export const adminRolesAsString = (role: string) => {
  if (role === AdminRoles.ShadowAdmin) {
    return i18next.t('adminRoles.shadowAdmin');
  }
  if (role === AdminRoles.CollaborationAdmin) {
    return i18next.t('adminRoles.collaborationAdmin');
  }
  if (role === AdminRoles.GroupAdmin) {
    return i18next.t('adminRoles.groupAdmin');
  }
  if (role === AdminRoles.ProfileAdmin) {
    return i18next.t('adminRoles.profileAdmin');
  }
  if (role === BpAdminRoles.ThreemaAdmin) {
    return i18next.t('adminRoles.threemaAdmin');
  }
  if (role === BpAdminRoles.CloudAdmin) {
    return i18next.t('adminRoles.cloudAdmin');
  }
  if (role === BpAdminRoles.ZoomAdmin) {
    return i18next.t('adminRoles.zoomAdmin');
  }
  if (role === AdminRoles.OrganizationAdmin) {
    return i18next.t('adminRoles.schoolAdmin');
  }
  return '';
};
