import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useContext, useState } from 'react';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { useUpdateBpProfileMutation, useUpdatePasswordsMutation } from '../../client/bp-graphql-client-defs';
import { EyeSymbol } from './EyeSymbol';
import PasswordStrengthBar from 'react-password-strength-bar';
import styles from './ProfileForm.module.scss';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { CombinedProfile } from '../../pages/Profile/ProfilePage';
import * as Yup from 'yup';

type ProfileFormType = CombinedProfile & {
  currentPassword: string;
  newPassword: string;
  newPasswordCopy: string;
};

type ProfileFormProps = {
  profile: CombinedProfile | undefined;
  onClose: () => void;
};

export const ProfileForm = ({ profile, onClose }: ProfileFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemoizedCacheTag('PROFILE');
  const { threemaLicense, threemaBroadcastLicense } = useContext(OrganizationConfigContext);

  const [, updateProfile] = useUpdateBpProfileMutation();
  const [, updatePassword] = useUpdatePasswordsMutation();
  const [oldPwVisible, setOldPwVisible] = useState(false);
  const [newPwVisible, setNewPwVisible] = useState(false);
  const [passwordValidates, setPasswordValidates] = useState(true);

  const formikValues: ProfileFormType = {
    email: profile?.email ?? '',
    threemaId: profile?.threemaId ?? '',
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    currentPassword: '',
    newPassword: '',
    newPasswordCopy: '',
  };

  const handleSubmit = async (values: ProfileFormType) => {
    let closeModal: boolean = false;
    if (values.currentPassword !== '' && values.newPassword !== '' && values.newPasswordCopy === values.newPassword) {
      const validates = (closeModal = await handlePasswordUpdate(values));
      setPasswordValidates(validates);
    }
    if (values.email !== formikValues.email) closeModal = await handleEmailUpdate(values);
    if (values.threemaId !== formikValues.threemaId) closeModal = await handleThreemaUpdate(values);
    if (closeModal && onClose) onClose();
  };

  const handleEmailUpdate = async (values: ProfileFormType) => {
    if (typeof values.email === 'string') {
      const { error } = await updateProfile(
        {
          update: {
            email: values.email,
          },
          uuid: pimAuthClaims.getProfile().uuid,
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('profiles.emailSaved'));
    }
    return true;
  };

  const handleThreemaUpdate = async (values: ProfileFormType) => {
    return true;
  };

  const handlePasswordUpdate = async (values: ProfileFormType) => {
    const { error } = await updatePassword({
      where: {
        uuid: pimAuthClaims.getUser().uuid, // here we change the USER
      },
      update: {
        newPassword: values.newPassword,
        oldPassword: values.currentPassword,
      },
    });
    !error && showSuccessToast(t('profiles.passwordSaved'));
    return !error?.message.includes('PIM mutation failed, caused by: [GraphQL] Not allowed');
  };

  // placed here because the pipeline fails to recognize an import for this?
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.notValid')).required(t('validation.required.email')),
    newPasswordCopy: Yup.string().oneOf([Yup.ref('newPassword')], t('validation.passwordsMustMatch')),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={formikValues}
      validationSchema={validationSchema}
      initialErrors={{ some: 'error' }} //  deactivates the submit button
    >
      {({ resetForm, isSubmitting, errors, handleChange, values, setFieldTouched, touched }) => {
        return (
          <Form className={styles['profile-form']}>
            <Grid>
              <GridRow mobileGap='var(--grid-column-gap)'>
                <GridColumn width={6}>
                  <Input
                    label={t('common.email')}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email}
                    type={'text'}
                    error={errors.email}
                    readonly
                  />
                  <Input
                    label={t('profiles.threemaId')}
                    onChange={handleChange}
                    name={'threemaId'}
                    value={values.threemaId ?? ''}
                    type={'text'}
                    error={errors.threemaId}
                    disabled={!threemaLicense && !threemaBroadcastLicense}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    label={t('profiles.currentPassword')}
                    onChange={(ev) => {
                      setPasswordValidates(true);
                      handleChange(ev);
                      setFieldTouched('currentPassword');
                    }}
                    name={'currentPassword'}
                    value={values.currentPassword}
                    type={oldPwVisible ? 'text' : 'password'}
                    suffix={<EyeSymbol visible={oldPwVisible} toggle={() => setOldPwVisible((prev) => !prev)} />}
                  />
                  <Input
                    label={t('profiles.newPassword')}
                    onChange={(ev) => {
                      handleChange(ev);
                      setFieldTouched('newPassword');
                    }}
                    name={'newPassword'}
                    value={values.newPassword}
                    error={errors.newPassword}
                    type={newPwVisible ? 'text' : 'password'}
                    disabled={!touched.currentPassword}
                    suffix={<EyeSymbol visible={newPwVisible} toggle={() => setNewPwVisible((prev) => !prev)} />}
                  />
                  <Input
                    label={t('profiles.newPasswordRepeat')}
                    onChange={handleChange}
                    name={'newPasswordCopy'}
                    value={values.newPasswordCopy}
                    error={errors.newPasswordCopy}
                    type={newPwVisible ? 'text' : 'password'}
                    disabled={!touched.newPassword}
                    suffix={<EyeSymbol visible={newPwVisible} toggle={() => setNewPwVisible((prev) => !prev)} />}
                  />
                  <div className={styles['strength-bar-wrapper']}>
                    <PasswordStrengthBar
                      password={values.newPassword}
                      minLength={8}
                      scoreWords={[
                        t('profiles.weak'),
                        t('profiles.weak'),
                        t('profiles.okay'),
                        t('profiles.good'),
                        t('profiles.strong'),
                      ]}
                      shortScoreWord={t('validation.passwordsMinLength')}
                    />
                    {
                      // to make this special error more clearly visible I put it here
                      !passwordValidates && <div className={styles.error}>{t('validation.passwordWrong')}</div>
                    }
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              isLoading={isSubmitting}
              closeButton={{
                callback: () => {
                  resetForm();
                  if (onClose) onClose();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
