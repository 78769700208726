import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Grid,
  GridColumn,
  GridRow,
  InfoIcon,
  Input,
  Select,
  SelectOptionType,
  Switch,
  Tooltip,
} from '@bp/ui-components';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { MultiValue, SingleValue } from 'react-select';
import { CourseFormValues } from './CourseForm';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useBpProfileQuery, useSchoolYearsQuery, useSubjectsQuery } from '../../client/bp-graphql-client-defs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import styles from './CourseForm.module.scss';
import classNames from 'classnames';

const grades = Array(14)
  .fill(1)
  .map((element, index) => index.toString())
  .reverse();

type CourseFormBaseDataProps = {
  readonly: boolean;
  canCollaborate: boolean;
  onCollaborationChange: (isCollaboration: boolean) => void;
};

export const CourseFormBaseData = ({ readonly, canCollaborate, onCollaborationChange }: CourseFormBaseDataProps) => {
  const { t } = useTranslation();
  const { handleChange, setFieldValue, values, errors, setFieldTouched, handleBlur } =
    useFormikContext<CourseFormValues>();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const courseContext = useMemoizedCacheTag('COURSE');
  const profilesContext = useMemoizedCacheTag('PROFILE');

  const [{ data: schoolyearsData }] = useSchoolYearsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: courseContext,
  });

  const [{ data: subjectsData }] = useSubjectsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: courseContext,
  });

  const [{ data: profilesPoolData }] = useBpProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: profilesContext,
  });

  const subjects = subjectsData?.subjects;
  const schoolyears = schoolyearsData?.schoolYears;

  const selectSubjectsOpts = useCreateSelectOptions(subjects, 'uuid', 'name');
  const selectSchoolyearOpts = useCreateSelectOptions(schoolyears, 'uuid', 'name');
  const adminSingleOpts = useCreateSelectOptions([pimAuthClaims.getProfile()], 'uuid', 'displayName');
  const adminOpts = useCreateSelectOptions(profilesPoolData?.profiles, 'uuid', 'displayName');

  const selectGradeOpts = useCreateSelectOptions(
    grades.map((g) => {
      return { value: g, label: g };
    }),
    'value',
    'label',
  );

  const selectedSchoolyear = selectSchoolyearOpts.find((opt) => {
    return values.schoolyear ? opt.value === values.schoolyear : false;
  });

  const selectedGrades = selectGradeOpts.filter((opt) => {
    return values.grades?.includes(opt.value as string);
  });

  const switchStyles = classNames(styles.collaboration, {
    [styles.disabled]: !canCollaborate || !permissions?.canCreateCollaborationGroups(),
    [styles['read-only']]: readonly,
  });

  return (
    <Grid useFormGap>
      <GridRow spacingBottom='s'>
        <GridColumn width={6}>
          <Input
            autoFocus
            label={t('common.designation')}
            name='name'
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={errors.name}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('common.subject')}
            name='subject'
            value={values?.subject}
            error={errors.subject}
            options={selectSubjectsOpts}
            required
            isClearable={false}
            isSearchable
            onChange={(event) => setFieldValue('subject', event)}
            onBlur={handleBlur}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s' spacingBottom='s' breakpoint='phone'>
        <GridColumn width={6}>
          <Select
            label={t('common.schoolyear')}
            name='schoolyear'
            options={selectSchoolyearOpts}
            required
            isClearable={false}
            value={selectedSchoolyear}
            error={errors.schoolyear}
            isSearchable
            onChange={(event) => {
              const a: SelectOptionType | null = event as SingleValue<SelectOptionType>;
              setFieldTouched('schoolyear', true);
              setFieldValue('schoolyear', a?.value);
            }}
            onBlur={handleBlur}
            menuPosition='fixed'
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('courses.grade')}
            name='grades'
            isMulti
            value={selectedGrades}
            options={selectGradeOpts}
            isSearchable
            onChange={(e) => {
              setFieldValue(
                'grades',
                (e as MultiValue<SelectOptionType>).map((e) => e.value),
              );
            }}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s'>
        <GridColumn width={6}>
          <Select
            name={'admins'}
            label={t('collaborations.administration')}
            value={
              !canCollaborate
                ? adminOpts.find((v) => v.value === pimAuthClaims.getProfile().uuid)
                : [...adminOpts.filter((v) => values.admins.includes(v.value as string))]
            }
            options={!canCollaborate ? adminSingleOpts : adminOpts}
            onChange={(event) => {
              const adminOptions = event as MultiValue<SelectOptionType>;
              setFieldTouched('admins', true);
              setFieldValue(
                'admins',
                adminOptions.map((value) => value.value as string),
              );
            }}
            isClearable={canCollaborate}
            isMulti={canCollaborate}
            isSearchable={canCollaborate}
            readonly={!canCollaborate}
            menuPosition='fixed'
          />
        </GridColumn>
        <GridColumn width={6}>
          <div className={switchStyles}>
            <div>
              {t('courses.collaborationCourse')}
              {!canCollaborate && (
                <Tooltip triggerClass={styles.tooltip} content={t('collaborations.addHint')}>
                  <InfoIcon />
                </Tooltip>
              )}
            </div>
            <Switch
              name='collaborating'
              checked={values.collaborating}
              disabled={!canCollaborate || !permissions?.canCreateCollaborationGroups()}
              onChange={(event) => {
                setFieldValue('collaborating', event.target.checked);
                onCollaborationChange(event.target.checked);
              }}
            />
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
