import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileEntry,
  Material,
  SubmissionStatus,
  useBpAssignmentsQuery,
  useBpSubmissionsQuery,
  useBpUpdateSubmissionsMutation,
} from '../../../../client/bp-graphql-client-defs';
import {
  CalendarIcon,
  CheckIcon,
  EditIcon,
  EmptyState,
  Grid,
  GridColumn,
  GridRow,
  LazyLoader,
  SendIcon,
  UpdateIcon,
} from '@bp/ui-components';
import { ActivityProtocol } from '../../../../components/ActivityProtocol/ActivityProtocol';
import { AnswersCard } from '../../../../components/Cards/AnswersCard';
import { Form, Formik } from 'formik';
import { BpActionButton, BpCard } from '../../../../components/BpCard/BpCard';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { FileUpload } from '../../../../components/FileUpload/FileUpload';
import { useActivityProtocol } from '../../../../hooks/matrix/useActivityProtocol';
import { RequestForEditModal } from '../../../../components/Modals/ActivityProtocol/RequestForEditModal';
import { FileTable } from '../../../../components/FileTable/FileTable';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { showSuccessToast } from '../../../../utils/showSuccessToast';
import { BpSubpage } from '../../../../components/BpSubpage/BpSubpage';
import { BpHeader } from '../../../../components/BpHeader/BpHeader';
import { NotificationReasons, NotificationType } from '../../../../utils/matrixClient';
import { DesktopContext } from '../../../../context/IsDesktop';
import styles from './StudentContent.module.scss';
import { StatusChip } from '../../../../components/StatusChip/StatusChip';
import { BpTipTapText } from '../../../../components/BpTipTapText/BpTipTapText';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { BpTipTap } from 'components/BpTipTap/BpTipTap';
import { useDays } from '../../../../hooks/useDays';
import { type Submission } from '@bp/bp-graphql-types';
import { getDaysDifference, niceDate } from 'utils/dateCalculations';

export type MaterialWithStatus = Partial<Material> & { status: SubmissionStatus };

type CourseAssignmentStudentContentProps = {
  assignmentUuid: string;
  navigate: (submission: Submission) => void;
};

export const CourseAssignmentStudentContent: FC<CourseAssignmentStudentContentProps> = ({
  assignmentUuid,
  navigate,
}) => {
  const { t } = useTranslation();
  const { sendMessage, timelineListener } = useActivityProtocol();
  const { isDesktop } = useContext(DesktopContext);

  const pimAuthClaims = useAuthClaims().pimAuthClaims;

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const context = useMemoizedCacheTag('SUBMISSION');
  const [{ data: submissionsData }, refreshSubmission] = useBpSubmissionsQuery({
    variables: {
      where: {
        AND: [
          {
            assignmentConnection_SINGLE: {
              node: {
                uuid: assignmentUuid,
              },
            },
          },

          {
            ownerConnection: {
              node: {
                uuid: pimAuthClaims.getProfile().uuid,
              },
            },
          },
        ],
      },
    },
    context,
  });

  const submission = submissionsData?.submissions.find((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid);

  timelineListener(submission?.uuid ?? '', (event) => {
    const messageMeta: Partial<NotificationType> = JSON.parse(event.getContent().body);
    if (
      'type' in messageMeta &&
      [NotificationReasons.SubmissionNew, NotificationReasons.RequestToFeedback].includes(
        messageMeta.type as NotificationReasons,
      )
    ) {
      refreshSubmission({ requestPolicy: 'network-only' });
    }
  });

  const assigmentContext = useMemoizedCacheTag('ASSIGNMENT');
  const [{ data }] = useBpAssignmentsQuery({
    context: assigmentContext,
    variables: {
      where: {
        uuid: assignmentUuid,
      },
    },
  });
  const [, updateSubmissionMutation] = useBpUpdateSubmissionsMutation();

  const { daysLeft } = useDays();

  const connectFileEntries = (values: MaterialWithStatus) =>
    values.fileEntries?.map((entry) => {
      return {
        where: {
          node: {
            uuid: entry.uuid,
          },
        },
        edge: {
          order: 1,
        },
      };
    });

  const onSubmit = async (values: MaterialWithStatus) => {
    if (submission) {
      const resp = await updateSubmissionMutation(
        {
          where: {
            uuid: submission.uuid,
          },
          update: {
            status: values.status,
            material: {
              update: {
                node: {
                  text: values.text,
                  fileEntries: [
                    {
                      connect: connectFileEntries(values),
                    },
                  ],
                },
              },
            },
          },
        },
        context,
      );
      resp.error ? showErrorToast(resp.error) : showSuccessToast(t('common.saved'));

      if (!resp.error && values.status === SubmissionStatus.New) {
        // no message on Draft
        await sendMessage(submission.uuid, NotificationReasons.SubmissionNew);
      }
      setEditMode(false);
    }
  };

  const initialValues: MaterialWithStatus = {
    fileEntries: (submission?.material.fileEntries as FileEntry[]) ?? [],
    uuid: submission?.uuid ?? '',
    text: submission?.material.text ?? '',
    status: SubmissionStatus.Draft,
  };

  const status = submission?.status ?? SubmissionStatus.Todo;
  const isEditable = !(status === SubmissionStatus.New || status === SubmissionStatus.Done);

  const timeLeft = daysLeft(submission?.assignment[0].dueDate, true);
  const submissionIsEditable =
    isEditable &&
    (timeLeft > -1 || // Abgabe-Datum abgelaufen?
      (submission?.assignment[0].allowSubmissionAfterDueDate ?? false) || // oder Abgabe über das Datum hinaus erlauben
      submission?.status === SubmissionStatus.Feedback);

  const startDate = submission ? submission.assignment[0].visibleFrom?.date : data?.assignments[0].visibleFrom?.date;
  const endDate = submission ? submission.assignment[0].dueDate?.date : data?.assignments[0].visibleFrom?.date;

  return (
    <BpSubpage className={styles['course-assignment-student']}>
      {submission && (
        <RequestForEditModal isOpen={open} onClose={() => setOpen(false)} submissionUuid={submission.uuid} />
      )}

      {!data?.assignments[0] && <div>{t('errors.common')}</div>}
      {data?.assignments[0] && (
        <>
          <BpHeader headline={data?.assignments[0].title} />
          <Formik<MaterialWithStatus> onSubmit={onSubmit} initialValues={initialValues}>
            {({ isSubmitting, submitForm, setFieldValue, resetForm, values, dirty }) => {
              const handleClick = async (status: SubmissionStatus) => {
                await setFieldValue('status', status);
                await submitForm();
              };

              return (
                <Grid>
                  <GridRow mobileGap='var(--grid-column-gap)'>
                    <GridColumn width={8}>
                      <BpCard
                        noPadding
                        header={{
                          headline: t('assignments.titleSingular'),
                          subHeadline:
                            getDaysDifference(startDate, endDate) <= 1
                              ? niceDate(startDate, 'short')
                              : `${niceDate(startDate, 'short')} - ${niceDate(endDate, 'short')}`,
                          showSubHeadlineAsChip: true,
                        }}
                        hideContent={
                          !!data?.assignments[0]?.material.text && !data?.assignments[0]?.material.fileEntries
                        }
                        className='mb-6'
                      >
                        {data?.assignments[0]?.material.text ? (
                          <BpTipTapText
                            className='mb-6'
                            customPadding='0 var(--spacing-6)'
                            content={data.assignments[0].material.text}
                          />
                        ) : (
                          <EmptyState hideIcon size='small' title={t('assignments.noTask')} subtitle='' padding='l' />
                        )}
                        <FileTable
                          mode={'show'}
                          files={data?.assignments[0]?.material.fileEntries ?? []}
                          isGroupEditor={false}
                        />
                      </BpCard>
                      <Form className={styles['assignment-answer']}>
                        {isSubmitting ? (
                          <LazyLoader />
                        ) : (
                          <BpCard
                            noPadding={!isEditable}
                            header={{
                              headline: t('submissions.myAnswer'),
                              actions: [
                                ...(isEditable && editMode
                                  ? [
                                      {
                                        text: t('common.cancel'),
                                        callback: () => setEditMode((prev) => !prev),
                                        hierarchy: 'tertiary',
                                      } as BpActionButton,
                                    ]
                                  : []),
                                ...(isEditable && !editMode && submissionIsEditable
                                  ? [
                                      {
                                        text: t('common.edit'),
                                        callback: () => setEditMode((prev) => !prev),
                                        icon: <EditIcon />,
                                        hierarchy: 'secondary',
                                      } as BpActionButton,
                                    ]
                                  : []),
                                ...(isEditable &&
                                editMode &&
                                (submission?.status === SubmissionStatus.Todo ||
                                  submission?.status === SubmissionStatus.Draft ||
                                  submission?.status === SubmissionStatus.Feedback)
                                  ? [
                                      {
                                        text: t('submissions.saveAsDraft'),
                                        callback: () => handleClick(SubmissionStatus.Draft),
                                        hierarchy: 'secondary',
                                      } as BpActionButton,
                                      {
                                        text: t('submissions.submit'),
                                        callback: () => handleClick(SubmissionStatus.New),
                                        icon: <CheckIcon />,
                                        hierarchy: 'primary',
                                      } as BpActionButton,
                                    ]
                                  : []),
                                ...(isEditable && !editMode && submission?.status === SubmissionStatus.Draft
                                  ? [
                                      {
                                        text: t('submissions.submit'),
                                        callback: () => handleClick(SubmissionStatus.New),
                                        icon: <CheckIcon />,
                                        hierarchy: 'primary',
                                      } as BpActionButton,
                                    ]
                                  : []),
                                ...(submission?.status === SubmissionStatus.New ||
                                submission?.status === SubmissionStatus.Done
                                  ? [
                                      {
                                        text: t('submissions.requestToFeedback'),
                                        callback: () => setOpen(true),
                                        icon: <UpdateIcon />,
                                      } as BpActionButton,
                                    ]
                                  : []),
                              ],
                            }}
                          >
                            {!editMode ? (
                              <>
                                {values.text ? (
                                  <BpTipTapText
                                    className='mb-2'
                                    customPadding='0 var(--spacing-6)'
                                    maxHeight='6rem'
                                    content={values.text}
                                  />
                                ) : (
                                  <EmptyState
                                    hideIcon
                                    title={t('answers.noAnswers')}
                                    subtitle={t('answers.noAnswersHint')}
                                    padding='xl'
                                  />
                                )}
                                <FileTable mode={'show'} files={values.fileEntries ?? []} isGroupEditor={false} />
                              </>
                            ) : (
                              <>
                                <BpTipTap
                                  className='mb-6'
                                  name='text'
                                  onChange={(e) => setFieldValue('text', e)}
                                  defaultValue={values.text ?? ''}
                                  placeholder={t('answers.addAnswers')}
                                />
                                <FileTable
                                  className='mb-5'
                                  mode={'edit'}
                                  files={values.fileEntries ?? []}
                                  onRenamed={async (uuid, newName) => {
                                    const updated = values.fileEntries?.map((fileEntry) => {
                                      if (fileEntry.uuid === uuid) {
                                        return { ...fileEntry, filename: newName };
                                      }
                                      return fileEntry;
                                    });
                                    await setFieldValue('fileEntries', updated);
                                  }}
                                  onDeleted={async (uuid) => {
                                    await setFieldValue(
                                      'fileEntries',
                                      values.fileEntries?.filter((fileEntry) => {
                                        return fileEntry.uuid !== uuid;
                                      }),
                                    );
                                  }}
                                  isGroupEditor={false}
                                />
                                <FileUpload
                                  onFileUpload={(file) => {
                                    setFieldValue('fileEntries', [...(values.fileEntries as FileEntry[]), file]);
                                  }}
                                />
                              </>
                            )}
                          </BpCard>
                        )}
                      </Form>
                    </GridColumn>
                    <GridColumn width={4}>
                      <BpCard noPadding fixedHeight='200px' className='mb-6'>
                        <div className={styles['assignment-info']}>
                          <div className={styles.top}>
                            {isDesktop && <CalendarIcon className={styles.icon} />}
                            {timeLeft < 0 && (
                              <div>
                                {t('assignments.overdue')}
                                {submissionIsEditable ? ', ' + t('submissions.student.delayed') : ''}
                              </div>
                            )}
                            {timeLeft > -1 && (
                              <>
                                <div className={styles.days}>{timeLeft}</div>
                                <div className='ml-3'>{t('common.daysLeft')}</div>
                              </>
                            )}
                          </div>
                          <div className={styles.bottom}>
                            {isDesktop && <SendIcon className={styles.icon} />}
                            <StatusChip status={status} isEditorOfCourse={false} />
                          </div>
                        </div>
                      </BpCard>
                      {submission?.uuid && (
                        <BpCard
                          alwaysCollapsible
                          header={{
                            headline: t('activityProtocol.title'),
                          }}
                        >
                          <ActivityProtocol submissionUuid={submission.uuid} newSubmissionContent={() => {}} />
                        </BpCard>
                      )}
                      <AnswersCard onNavigate={navigate} assignmentUuid={assignmentUuid} />
                    </GridColumn>
                  </GridRow>
                </Grid>
              );
            }}
          </Formik>
        </>
      )}
    </BpSubpage>
  );
};
