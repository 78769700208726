import { Assignment, SubmissionStatus, TeachingUnit } from '@bp/bp-graphql-types';
import { EmptyState, Grid, GridRow, Modal, NoTasksIcon, SpinnerIcon } from '@bp/ui-components';
import { useBpGetAssignmentsByGroupQuery, useBpSubmissionsQuery } from '../../../../client/bp-graphql-client-defs';
import { AssignmentForm } from '../../../../components/AssignmentForm/AssignmentForm';
import { AssignmentsTableTeacher } from '../../../../components/AssignmentsTable/AssignmentsTableTeacher';
import { AssignmentPreviewTeacher } from '../../../../components/AssignmentPreview/AssignmentPreviewTeacher';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { FC, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { showErrorToast } from '../../../../utils/showErrorToast';
import styles from './TeacherContent.module.scss';
import { BpCard } from '../../../../components/BpCard/BpCard';
import { BpHeader } from '../../../../components/BpHeader/BpHeader';
import { useAutoUpdateQuery } from '../../../../hooks/useAutoUpdateQuery';
import dayjs from 'dayjs';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useWindowDimensions } from 'utils/dimensions';

interface AssignmentWithStatus extends Assignment {
  status?: SubmissionStatus;
}

export const CourseAssignmentsTeacherContent: FC = () => {
  const { t } = useTranslation();
  const { courseUuid } = useParams<{ courseUuid: string }>();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();

  const { isPhone, isTablet } = useWindowDimensions();

  const [modalOpen, setModalOpen] = useState<Assignment | null>(null);

  const openAssignmentModal = (assignment: Assignment) => {
    setModalOpen(assignment);
  };

  const context = useMemoizedCacheTag('ASSIGNMENT');

  const [{ data, error }] = useAutoUpdateQuery(
    useBpGetAssignmentsByGroupQuery({
      context: context,
      requestPolicy: 'cache-and-network',
      variables: {
        where: {
          uuid: courseUuid,
        },
        teachingUnitWhere: { active: true },
      },
    }),
  );

  error && showErrorToast(error);

  const [submissions] = useBpSubmissionsQuery({
    context: context,
    variables: {
      where: {
        owner: {
          uuid: pimAuthClaims.getProfile().uuid ?? '',
        },
        assignment: {
          uuid_IN: data?.groups[0]?.teachingUnits?.flatMap((t: TeachingUnit) => t.assignments.map((a) => a.uuid)),
        },
      },
    },
  });

  submissions.error && showErrorToast(submissions.error);

  const activeAssignments: AssignmentWithStatus[] = [];
  const pastAssignments: AssignmentWithStatus[] = [];
  const futureAssignments: AssignmentWithStatus[] = [];

  const assignments = data?.groups[0]?.teachingUnits?.map((t: TeachingUnit) => t.assignments).flat() ?? [];
  assignments.forEach((assignment: AssignmentWithStatus) => {
    const now = new Date();
    if (dayjs(assignment.dueDate?.date).isBefore(now, 'day')) {
      pastAssignments.push(assignment);
    }

    if (
      dayjs(assignment.dueDate?.date).isSameOrAfter(now, 'day') &&
      dayjs(assignment.visibleFrom?.date).isSameOrBefore(now, 'day')
    ) {
      activeAssignments.push(assignment);
    }

    if (dayjs(assignment.visibleFrom?.date).isAfter(now, 'day')) {
      futureAssignments.push(assignment);
    }
  });

  const handleAssignmentClick = (uuid: string) => {
    navigate(`/courses/${courseUuid}/assignments/${uuid}`);
  };

  return (
    <div className={styles['course-assignmnets-teacher']}>
      <Grid>
        <GridRow>
          <BpHeader noMargin headline={t('assignments.active')} subHeadline={activeAssignments.length} />
        </GridRow>
        <GridRow>
          {activeAssignments.length === 0 ? (
            <EmptyState
              fitParent
              size='small'
              backgroundColor='var(--color-white)'
              subtitle={t('assignments.noAssignments')}
              iconColor='var(--color-primary-light)'
              icon={<NoTasksIcon />}
              padding='none'
              forcedWidth='350px'
              forcedHeight='167px'
            />
          ) : (
            <div className={styles['assignment-previews']}>
              {activeAssignments.map((activeAssignment) => {
                return (
                  <AssignmentPreviewTeacher
                    className={styles.preview}
                    key={activeAssignment.uuid}
                    assignment={activeAssignment}
                    onEditClick={() => setModalOpen(activeAssignment)}
                    onClick={(uuid) => navigate(`${uuid}`)}
                  />
                );
              })}
            </div>
          )}
        </GridRow>
        <GridRow>
          <BpCard
            noPadding
            header={{
              headline: t('assignments.future'),
              subHeadline: !isPhone && !isTablet ? t('assignments.studentsCantSee') : undefined,
            }}
          >
            <div className={styles['mobile-hint']}>{t('assignments.studentsCantSee')}</div>
            <AssignmentsTableTeacher
              data={futureAssignments}
              onAssignmentClick={handleAssignmentClick}
              onAssignmentEditClick={openAssignmentModal}
            />
          </BpCard>
        </GridRow>
        <GridRow>
          <BpCard noPadding noMargin={false} header={{ headline: t('assignments.finishedAssignments') }}>
            <AssignmentsTableTeacher
              data={pastAssignments}
              onAssignmentClick={handleAssignmentClick}
              onAssignmentEditClick={openAssignmentModal}
            />
          </BpCard>
        </GridRow>
      </Grid>

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} hideFooter hideHeader isFormModal>
        <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
          <AssignmentForm
            courseUuid={courseUuid}
            assignmentUuid={modalOpen?.uuid ?? ''}
            onClose={() => setModalOpen(null)}
            currentTeachingUnitUuid={modalOpen?.holder.uuid ?? ''}
            isModal
            context={context}
            isGroupEditor={true}
          />
        </Suspense>
      </Modal>
    </div>
  );
};
