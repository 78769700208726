import { useTranslation } from 'react-i18next';
import styles from './AssignmentsGantt.module.scss';
import classNames from 'classnames';
import { ArrowDownIcon, ArrowUpIcon, Button } from '@bp/ui-components';
import { FC, useState } from 'react';
import { SubmissionStatus } from '@bp/bp-graphql-types';
import { GanttFlyout } from '../GanttFlyout/GanttFlyout';
import { GanttAssignment, GanttBar, GanttDay } from '../OpenAssignmentsOverview/OpenAssignmentsOverview';
import { BpLink } from 'components/BpLink/BpLink';

type AssignmentsGanttProps = {
  timespan: GanttDay[];
  assignments: GanttAssignment[];
  mode: 'desktop' | 'tablet' | 'mobile';
  onNavigate: (uuid: string) => void;
};

export const AssignmentsGantt: FC<AssignmentsGanttProps> = ({
  timespan,
  assignments,
  mode = 'desktop',
  onNavigate,
}) => {
  const { t } = useTranslation();

  const [showHidden, setShowHidden] = useState(false);

  const rowThreshold = mode === 'desktop' ? 4 : 6;

  function getBarClasses(bar: GanttBar, status: SubmissionStatus | undefined) {
    return classNames(
      styles.bar,
      {
        [styles.start]: bar === 1 || bar === 4,
        [styles.end]: bar === 3 || bar === 4,
      },
      styles['status-' + status?.toLowerCase()],
    );
  }

  function getKeyFromDay(day: GanttDay): string {
    const date = day.dayMonthYear;
    return `${date.day}${date.month}${date.year}`;
  }

  function getStatusText(status: SubmissionStatus | undefined) {
    let text = '';
    switch (status) {
      case SubmissionStatus.Done:
        text = t('submissions.student.done');
        break;
      case SubmissionStatus.Draft:
        text = t('submissions.student.draft');
        break;
      case SubmissionStatus.Feedback:
        text = t('submissions.student.feedback');
        break;
      case SubmissionStatus.New:
        text = t('submissions.student.new');
        break;
      case SubmissionStatus.Updated:
        text = t('submissions.student.updated');
        break;
      case SubmissionStatus.Todo:
        text = t('submissions.student.todo');
        break;
      default:
        break;
    }
    return text;
  }

  const filled: GanttAssignment[] = assignments;
  if (assignments.length < rowThreshold) {
    for (let i = assignments.length; i < rowThreshold; i++) {
      filled.push({
        key: i.toString(),
      });
    }
  }

  let isEmpty = true;
  filled.forEach((assignment) => {
    if (isNaN(Number(assignment.key.toString()))) {
      isEmpty = false;
      return;
    }
  });

  const classes = classNames(styles['assignments-gantt'], { [styles.tablet]: mode === 'tablet' });

  return (
    <div className={classes}>
      <div className={classNames(styles.header, 'has-scrollbar')}>
        <div className={styles.title}>{t('appointments.title')}</div>
        <div className={styles.days}>
          {timespan.map((day) => {
            return (
              <div
                key={getKeyFromDay(day)}
                className={classNames(styles.day, {
                  [styles.active]: day.isToday,
                })}
              >
                {day.events && day.events.length > 0 && (
                  <GanttFlyout
                    trigger={
                      <div
                        className={classNames(styles.event, {
                          [styles.multiple]: day.events.length > 1,
                        })}
                      >
                        <div className={styles.type}>{t('events.categories.event')}</div>
                        {day.events.length > 1 && <div className={styles.badge}>{`+${day.events.length - 1}`}</div>}
                      </div>
                    }
                    entries={day.events ?? []}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames(styles['assignments-wrapper'], 'has-scrollbar')}>
        {filled.map((assignment, assignIndex) => {
          return (
            <div
              key={assignment.key}
              className={classNames(styles.row, {
                [styles.hidden]: assignIndex > rowThreshold - 1 && !showHidden,
              })}
            >
              <div className={styles.title}>
                <BpLink
                  className={styles.link}
                  value={assignment.title ?? ''}
                  onNavigate={() => onNavigate(assignment.key)}
                  isEditorOfCourse={false}
                />
              </div>
              <div className={styles.days}>
                {timespan.map((day, dayIndex) => {
                  return (
                    <div
                      key={getKeyFromDay(day)}
                      className={classNames(styles.day, {
                        [styles.active]: day.isToday,
                      })}
                    >
                      {assignment.bars && assignment.bars[dayIndex] !== 0 && (
                        <div className={getBarClasses(assignment.bars[dayIndex], assignment.status)}>
                          {(assignment.bars[dayIndex] === 1 || assignment.bars[dayIndex] === 4 || dayIndex === 0) && (
                            <div className={styles['status-text']}>{getStatusText(assignment.status)}</div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {assignments && assignments.length > rowThreshold && (
        <div className={styles.button}>
          <Button
            hierarchy='primary'
            onClick={() => {
              setShowHidden(!showHidden);
            }}
          >
            <div className={styles.caption}>
              <div>{`${showHidden ? '-' : '+'} ${assignments.length - rowThreshold}`}</div>
              <div>
                {showHidden ? <ArrowUpIcon className='svg-icon white' /> : <ArrowDownIcon className='svg-icon white' />}
              </div>
            </div>
          </Button>
        </div>
      )}
      {(!assignments || isEmpty) && (
        <div className={styles.placeholder}>{t('assignments.noAssignmentsInTimespan')}</div>
      )}
    </div>
  );
};
