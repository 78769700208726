import { Skeleton } from '@bp/ui-components';

export const ClassbookClasses = () => {
  return (
    <>
      <br />
      <p>Hier wird es Auswertungen nach einzelnen Klassen/Schülern geben</p>
      <br />
      <Skeleton />
    </>
  );
};
