import React, { useEffect } from 'react';
import { getTokens } from '../../utils/authStore';
import styles from './FileUpload.module.scss';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { renderToString } from 'react-dom/server';
import { UploadIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { FileEntry } from '@bp/bp-graphql-types';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

type FileUploadProps = {
  onFileUpload?: (file: FileEntry) => void;
};

export const FileUpload = ({ onFileUpload }: FileUploadProps) => {
  const { t } = useTranslation();

  const [token, setToken] = React.useState<string | null>(null);

  useEffect(() => {
    getTokens().then((tokens) => {
      setToken(tokens?.access_token ?? null);
    });
  }, []);

  const label = (
    <div className={`filepond--label-action ${styles['upload-label']}`}>
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <UploadIcon />
        </div>
        <div className={styles.instruction}>{t('assignment.upload.instruction')}</div>
        <div className={styles.hint}>{t('assignment.upload.hint')}</div>
      </div>
    </div>
  );

  return (
    <FilePond
      className={styles.filepond}
      files={[]}
      allowMultiple={false}
      dropOnPage
      server={{
        process: {
          url: `${import.meta.env.VITE_APP_BACKEND_URI}${import.meta.env.VITE_APP_BACKEND_REST_PATH}/files`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onload: (response) => {
            const resp = JSON.parse(response);
            if (onFileUpload) onFileUpload(resp);
            return response;
          },
        },
      }}
      name='file' //<-- Muss unbedingt so heissen!
      allowRevert={false}
      labelIdle={renderToString(label)}
      credits={false}
      // acceptedFileTypes={props.fileTypes}
    />
  );
};
