import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalendarEventCreateInput,
  useBpCalendarEventsQuery,
  useBpCreateCalendarEventMutation,
  useBpUpdateCalendarEventsMutation,
} from '../../client/bp-graphql-client-defs';
import { CombinedError } from 'urql';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import styles from './AppointmentForm.module.scss';
import {
  Button,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  InplaceEdit,
  RadioButtonType,
  RadioGroup,
  showToast,
} from '@bp/ui-components';
import { AppointmentMeta } from '../Appointments/Forms/components/AppointmentMeta';
import dayjs, { Dayjs } from 'dayjs';
import { buildRecurrenceRule, connectByUuid } from '../../utils/connectLib';
import { AppointmentFormValues } from '../Appointments/types';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import classNames from 'classnames';
import { BpCard } from '../BpCard/BpCard';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { buildRecurrences, dateAndTime, eventColors } from '../../utils/dateCalculations';
import { BpText } from 'components/BpText/BpText';
import { useDays } from '../../hooks/useDays';
import { appointmentFormSchema } from './validation/appointmentFormSchema';
import { v4 } from 'uuid';
import { backendApi } from '../../utils/backendApi';
import { DEFAULT_MEETING_DURATION } from '../Modals/InstantMeetingModal/InstantMeetings';
import { useGroupMembersCount } from '../../hooks/useGroupMembersCount';

export type TErrors = AppointmentFormValues & { lessonType?: string };

type AppointmentFormPropsType = {
  isModal?: boolean;
  canAssignTeachingUnit?: boolean;
  disableEventTypeValues?: string[];
  organizationUuid: string; // we need the organization from the holder, not from the user for resource-handling
  holderUuid: string;
  context: 'GroupOrCourse' | 'Profile' | 'Organization';
  appointmentUuid?: string;
  teachingUnitUuid?: string;
  onClose: () => void;
};

const _beginDuration = (startTime: Dayjs, startDate: Dayjs, endTime: Dayjs, endDate: Dayjs) => {
  const sTime = startTime.format('HH:mm');
  const sDate = startDate.format('YYYY-MM-DD');

  const eTime = endTime.format('HH:mm');
  const eDate = endDate.format('YYYY-MM-DD');

  const _toISO = (date: string, time: string) => {
    return dayjs(`${date}T${time}:00.000`);
  };
  const begin = _toISO(sDate, sTime);
  const end = _toISO(eDate, eTime);

  // duration in seconds
  const duration = dayjs.duration(end.diff(begin)).asSeconds().toString();

  return { duration, begin };
};

export const AppointmentForm = ({
  isModal = false,
  canAssignTeachingUnit = true,
  disableEventTypeValues = [],
  organizationUuid,
  holderUuid,
  context,
  appointmentUuid,
  onClose,
  teachingUnitUuid,
}: AppointmentFormPropsType) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const now = dayjs().add(1, 'hour').startOf('hour');
  const eventContext = useMemoizedCacheTag('EVENT');

  const { ensureDay, createDateSeries } = useDays();
  const [loading, setLoading] = useState<boolean>(false);
  const [, bpCreateEvents] = useBpCreateCalendarEventMutation();
  const [, bpUpdateEvents] = useBpUpdateCalendarEventsMutation();

  const [{ data }] = useBpCalendarEventsQuery({
    context: eventContext,
    variables: { where: { uuid: appointmentUuid ?? '' } },
  });

  const calendarEvent = data?.calendarEvents.find((event) => event.uuid === appointmentUuid);

  const start = calendarEvent?.start ?? '';
  const end = dayjs(start).add(
    dayjs.duration(parseInt(calendarEvent?.duration ?? '', 10) ?? DEFAULT_MEETING_DURATION * 60, 'seconds'),
  );

  // TODO: what to do if holder is not a group but a orga or profile?
  const { groupMembers } = useGroupMembersCount(holderUuid);

  const initialValues: AppointmentFormValues = {
    title: calendarEvent?.title || '',
    categories: calendarEvent?.categories || ['onSite'],
    desc: calendarEvent?.description || '',
    startDate: calendarEvent?.start ? dayjs(calendarEvent?.start) : now,
    startTime: calendarEvent?.start ? dayjs(calendarEvent?.start) : now,
    endTime: calendarEvent?.duration ? end : now.add(DEFAULT_MEETING_DURATION * 60, 'seconds'),
    endDate: calendarEvent?.duration ? end : now,
    teachingUnit: canAssignTeachingUnit ? calendarEvent?.teachingUnit?.uuid || teachingUnitUuid : undefined,
    recurrence: calendarEvent?.recurrenceRule?.frequency || 'once',
    until: dayjs(calendarEvent?.until || now),
    record: calendarEvent?.recordVirtualEvent || false,
    duration: calendarEvent?.duration ? parseInt(calendarEvent.duration, 10) : DEFAULT_MEETING_DURATION * 60,
    service: { value: '', label: '' },
    participantsCount: calendarEvent?.maxAttendees ?? groupMembers + 10,
  };

  const handleError = (error: CombinedError | undefined, resetForm: () => void) => {
    if (error) {
      showErrorToast(error);
    } else {
      resetForm();
      showSuccessToast(t('common.saved'));
      onClose();
    }
  };

  const handleSubmit = async (values: AppointmentFormValues, formikHelpers: FormikHelpers<AppointmentFormValues>) => {
    if (loading) return;

    setLoading(true);

    const {
      categories,
      title,
      startTime,
      endTime,
      startDate,
      endDate,
      desc,
      teachingUnit,
      recurrence,
      until,
      service,
      participantsCount,
      record,
    } = values;

    let canSave = true;
    let attendees = participantsCount ?? 0;

    // check if we have sufficient bbb connections
    if (values.service.value === 'bbb') {
      if (appointmentUuid) {
        // it's an update, we need to substract the previous claimed slots
        attendees = participantsCount - (calendarEvent?.maxAttendees ?? 0);
      }
      const recurrences = [];
      if (recurrence === 'once') {
        recurrences.push({
          id: v4(),
          maxAttendees: attendees,
          start: dateAndTime(startTime, startTime).toISOString(),
          end: dateAndTime(endTime, endTime).toISOString(),
          organization: pimAuthClaims.getOrganizationUuid(),
        });
      }
      if (recurrence !== 'once') {
        recurrences.push(
          ...buildRecurrences(values).map((r) => ({
            id: v4(),
            maxAttendees: attendees,
            start: dateAndTime(r, startTime).toISOString(),
            end: dateAndTime(r, endTime).toISOString(),
            organization: pimAuthClaims.getOrganizationUuid(),
          })),
        );
      }

      const s = await Promise.all(recurrences.map(async (r) => await backendApi.testForSufficientBBBSlots(r)));
      const slots = s.flat();

      for (const slot of slots) {
        const count = Object.values(slot)[0];
        if (count <= 0) {
          canSave = false;
          const item = recurrences.find((r) => r.id === Object.keys(slot)[0]);

          showToast(
            `${t('meetings.tooManyParticipants')}: ${dayjs(item?.start).format('DD.MM.YYYY')} - max ${attendees + count}`,
            {
              type: 'error',
            },
          );
        }
      }
      setLoading(false);
    }

    //  we have sufficient bbb connections
    if (canSave) {
      // only add service if the categories are hybrid or virtual
      const cats =
        service?.value && (categories.includes('hybrid') || categories.includes('virtual'))
          ? [...categories, service.value]
          : categories;

      const dateSeries = createDateSeries(startDate, endDate);
      const dates = await Promise.all(dateSeries.map(async (d) => await ensureDay(d.toDate())));

      if (appointmentUuid) {
        // Edit Event
        const { error } = await bpUpdateEvents(
          {
            update: {
              start: _beginDuration(startTime, startDate, endTime, endDate).begin.toISOString(),
              duration: _beginDuration(startTime, startDate, endTime, endDate).duration.toString(),
              title,
              description: desc,
              color: eventColors(categories),
              categories: cats,
              days: [{ disconnect: [{}], connect: dates.map((day) => ({ where: { node: { uuid: day?.uuid } } })) }],
              maxAttendees: participantsCount,
              guestsAllowed: true, // per default guests are allowed (as it was in the old system too)
              recordVirtualEvent: record,
              until: until.toISOString(),
              ...(teachingUnit
                ? { teachingUnit: { disconnect: {}, ...connectByUuid(teachingUnit) } }
                : { teachingUnit: { disconnect: {} } }),
            },
            where: { uuid: appointmentUuid },
          },
          eventContext,
        );
        handleError(error, formikHelpers.resetForm);
      } else {
        // New Event
        const input: CalendarEventCreateInput = {
          organization: connectByUuid(organizationUuid),
          ...(teachingUnit && { teachingUnit: connectByUuid(teachingUnit) }),
          holder:
            context === 'GroupOrCourse'
              ? { Group: connectByUuid(holderUuid) }
              : context === 'Profile'
                ? { Profile: connectByUuid(holderUuid) }
                : { Organization: connectByUuid(holderUuid) },
          start: _beginDuration(startTime, startDate, endTime, endDate).begin.toISOString(),
          duration: _beginDuration(startTime, startDate, endTime, endDate).duration.toString(),
          startTime: startTime,
          endTime: endTime,
          days: { connect: dates.map((day) => ({ where: { node: { uuid: day?.uuid } } })) },
          title,
          description: desc,
          color: eventColors(categories),
          owner: connectByUuid(pimAuthClaims.getProfile().uuid ?? ''),
          maxAttendees: participantsCount,
          guestsAllowed: true, // per default guests are allowed (as it was in the old system too)
          recordVirtualEvent: record,
          categories: cats,
          ...buildRecurrenceRule(recurrence),
          until: until.toISOString(),
        };

        const { error } = await bpCreateEvents({ input }, eventContext);

        handleError(error, formikHelpers.resetForm);
      }
      setLoading(false);
    }
  };

  const recurrenceOptions: RadioButtonType[] = [
    {
      label: t('appointments.recurrences.once'),
      value: 'once',
    },
    {
      label: t('appointments.recurrences.daily'),
      value: 'daily',
    },
    {
      label: t('appointments.recurrences.weekly'),
      value: 'weekly',
    },
    {
      label: t('appointments.recurrences.workdays'),
      value: 'workdays',
    },
    {
      label: t('appointments.recurrences.yearly'),
      value: 'yearly',
    },
  ];

  return (
    <div className={styles['appointment-form']}>
      <Formik<AppointmentFormValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={appointmentFormSchema}
      >
        {({ values, errors, resetForm, isSubmitting, setFieldTouched, setFieldValue }) => {
          return (
            <Form className={classNames('bp__form', { 'is-modal': isModal })}>
              <div className={isModal ? 'bp__modal-header' : 'bp__form-header'}>
                <InplaceEdit
                  className={styles.title}
                  name='title'
                  fontSize={'xxl'}
                  onBlur={(value) => {
                    void setFieldValue('title', value);
                    void setFieldTouched('title', true);
                  }}
                  onChange={(value) => {
                    void setFieldValue('title', value);
                    void setFieldTouched('title', true);
                  }}
                  value={values.title}
                  placeholder={t('common.addTitle')}
                  error={errors.title}
                />
                <div className={'bp__form-buttons'}>
                  <Button
                    hierarchy='secondary'
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    {appointmentUuid ? t('common.discardChanges') : t('common.cancel')}
                  </Button>
                  <Button hierarchy='primary' type={'submit'} disabled={!!errors.title} isLoading={isSubmitting}>
                    {t('common.save')}
                  </Button>
                </div>
              </div>

              <Grid>
                <GridRow mobileGap='var(--grid-column-gap)'>
                  <GridColumn width={6}>
                    <AppointmentMeta
                      calendarEvent={calendarEvent}
                      organizationUuid={organizationUuid}
                      holderUuid={holderUuid}
                      disableEventTypeValues={disableEventTypeValues ?? []}
                      canAssignTeachingUnit={canAssignTeachingUnit}
                    />
                  </GridColumn>
                  <GridColumn width={6}>
                    <BpCard header={{ headline: t('appointments.recurrence') }}>
                      {appointmentUuid ? (
                        <BpText className='mb-3' embedded>
                          {t('appointments.recurrenceEditDescription')}
                        </BpText>
                      ) : (
                        <BpText className='mb-3' embedded>
                          {t('appointments.recurrenceDescription')}
                        </BpText>
                      )}
                      <RadioGroup
                        name='recurrence'
                        disabled={!!appointmentUuid}
                        value={values.recurrence ?? 'once'}
                        options={recurrenceOptions}
                        onChange={(value) => {
                          setFieldTouched('recurrence');
                          setFieldValue('recurrence', value);
                        }}
                      />
                      {values.recurrence !== 'once' && (
                        <DatePicker
                          disabled={!!appointmentUuid}
                          className='mt-5'
                          label={t('appointments.until')}
                          onChange={(e) => {
                            setFieldValue('until', dayjs(e));
                            setFieldTouched('until', true);
                          }}
                          name={'until'}
                          value={values.until.toDate()}
                          showMonthYearDropdown
                        />
                      )}
                    </BpCard>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
