import { DaysQuery, useCreateDayMutation, useDaysQuery } from '../client/bp-graphql-client-defs';
import dayjs from 'dayjs';
import { showErrorToast } from '../utils/showErrorToast';
import { useTranslation } from 'react-i18next';

type DayType = Pick<DaysQuery, 'days'>['days'][0] | null | undefined;

const MAX_YEARS_IN_THE_PAST = 1;

export const useDays = () => {
  const { t } = useTranslation();
  const actualYear = dayjs().get('year');

  const [daysQueryResult] = useDaysQuery({
    variables: {
      where: {
        year_GTE: actualYear - MAX_YEARS_IN_THE_PAST,
      },
      options: {
        limit: 5000, // somewhat arbitrary
      },
    },
  });

  const [, createDay] = useCreateDayMutation();

  const _findDayWithDate = (date: Date) => {
    return daysQueryResult.data?.days?.find((day) => dayjs(day.date).isSame(dayjs(date), 'day'));
  };

  const today = _findDayWithDate(new Date());

  const isFuture = (day: DayType) => dayjs(day?.date).isAfter(today?.date, 'day');
  const isPast = (day: DayType) => dayjs(day?.date).isBefore(today?.date, 'day');
  const daysLeft = (day: DayType, returnNegative = false): number => {
    const remaining = dayjs(day?.date).diff(dayjs(today?.date), 'd');

    if (!returnNegative) {
      // return 0 when negative
      return remaining < 0 ? 0 : remaining;
    }

    // return any value
    return remaining;
  };

  function createDateSeries(start: dayjs.Dayjs, end?: dayjs.Dayjs | null) {
    const dateArray: dayjs.Dayjs[] = [];
    let currentDate = start.clone();

    if (end) {
      while (currentDate <= end) {
        dateArray.push(currentDate);
        currentDate = currentDate.add(1, 'days');
      }
    }

    if (!end) {
      dateArray.push(currentDate);
    }

    return dateArray;
  }

  const ensureDay = async (date: Date) => {
    if (dayjs(date).get('year') >= actualYear - MAX_YEARS_IN_THE_PAST) {
      const day = _findDayWithDate(date);

      if (day)
        // day is in the query data
        return day;

      // otherwise day will be created
      const createResponse = await createDay({ start: date.toISOString() });
      return createResponse.data?.createBpDays[0];
    } else {
      showErrorToast({
        message: t('errors.dateTooOld', { count: MAX_YEARS_IN_THE_PAST }),
        graphQLErrors: [],
        name: '',
      });
      throw new Error(t('errors.dateTooOld', { count: MAX_YEARS_IN_THE_PAST }));
    }
  };

  return { today, daysLeft, isPast, isFuture, ensureDay, createDateSeries };
};
