import styles from './MatrixRoomListItem.module.scss';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { memo } from 'react';

type MessageDetailProps = {
  className?: string | undefined;
  lastUpdate: number;
  unread: number;
  roomName: string;
  badgeText?: string;
  preview: string;
  isSelected: boolean;
  onClick: (roomId: string) => void;
  roomId: string;
};

export const MatrixRoomListItem = memo(
  ({
    isSelected,
    lastUpdate,
    className,
    roomName,
    unread,
    preview,
    onClick,
    roomId,
    badgeText,
  }: MessageDetailProps) => {
    const fromNow = dayjs(dayjs(lastUpdate)).fromNow(false);

    const classes = classNames(
      styles.message,
      {
        [styles.unread]: unread > 0,
        [styles.selected]: isSelected,
      },
      className,
    );

    return (
      <div onClick={() => onClick(roomId)} className={classes}>
        <div className={styles.top}>
          <div className={styles.name}>{roomName}</div>
          <div className={styles.date}>{fromNow}</div>
        </div>
        <div className={styles.middle}>
          {preview !== '' && <div className={styles.preview}>{preview}</div>}
          {unread > 0 && <div className={styles.indicator}>{unread >= 10 ? '9+' : unread}</div>}
        </div>
        <div className={styles.bottom}>
          <div className={styles.room}>{badgeText}</div>
        </div>
      </div>
    );
  },
);
MatrixRoomListItem.displayName = 'Message';
