import { UserStatus } from '@bp/bp-graphql-types';
import { uniqueByUuid } from './uniquByUuid';
import { ProfileRoles } from '@bp/pim-auth-constants';

export type ProfileType = {
  organizationRoles: Array<string | null>;
  uuid: string;
  organization: { uuid: string };
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  userStatus: UserStatus;
};

export const extractProfilesByRoles = ({
  profileRoles,
  profiles,
}: {
  profiles?: ProfileType[];
  profileRoles: ProfileRoles[];
}) => {
  const _profiles = uniqueByUuid(profiles ?? []);

  const extractProfilesByRole = (role: ProfileRoles): ProfileType[] =>
    _profiles?.filter((p) => p.organizationRoles?.includes(role)) ?? [];

  const profileElements: Record<ProfileRoles, ProfileType[]> = {} as Record<ProfileRoles, ProfileType[]>;
  profileRoles.forEach((role) => {
    profileElements[role] = _profiles ? extractProfilesByRole(role) : [];
  });

  return profileElements;
};
