import { Skeleton } from '@bp/ui-components';
import { BpPage } from 'components/BpPage/BpPage';
import { useTranslation } from 'react-i18next';

export function AccountsPage() {
  const { t } = useTranslation();

  return (
    <BpPage title={t('accounts.title')} isForbidden={false}>
      <Skeleton />
    </BpPage>
  );
}
