import { Button, Chip } from '@bp/ui-components';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './BpHeader.module.scss';
import { BpActionButton } from '../../components/BpCard/BpCard';

type BpHeaderProps = {
  headline: string;
  subHeadline?: string | number;
  size?: 's' | 'm' | 'l';
  noMargin?: boolean;
  breakLine?: boolean;
  isModal?: boolean;
  isForm?: boolean;
  bigActionsGap?: boolean;
  showSubHeadlineAsChip?: boolean;
  actions?: BpActionButton[];
  className?: string | undefined;
};

export const BpHeader: FC<BpHeaderProps> = ({
  headline,
  subHeadline,
  size = 'm',
  noMargin = false,
  breakLine = false,
  isModal = false,
  isForm = false,
  bigActionsGap = false,
  showSubHeadlineAsChip = false,
  actions,
  className,
}) => {
  const classes = classNames(
    styles['bp-header'],
    styles[`size-${size}`],
    {
      [styles['no-margin']]: noMargin,
      [styles['break-line']]: breakLine,
      'bp__form-header': isForm,
      'bp__modal-header': isModal,
      [styles['big-gap']]: bigActionsGap,
      [styles['with-chip']]: showSubHeadlineAsChip,
    },
    className,
  );

  return (
    <div className={classes}>
      <div className={styles['headline-wrapper']}>
        <div className={styles.headline}>{headline}</div>
        {(subHeadline || subHeadline === 0) && (
          <>
            {showSubHeadlineAsChip ? (
              <Chip className={styles.subline} value={subHeadline.toString()} maxWidth='100%' />
            ) : (
              <div className={styles.subline}>{subHeadline.toString()}</div>
            )}
          </>
        )}
      </div>
      {actions && (
        <div className={styles.actions}>
          {actions &&
            actions.map((action, index) => {
              return (
                <Button
                  className={action.className}
                  key={index}
                  disabled={action.disabled}
                  icon={action.icon}
                  hierarchy={action.hierarchy ?? 'ghost'}
                  onClick={action.callback}
                  type={action.isSubmit ? 'submit' : 'button'}
                >
                  {action.text}
                </Button>
              );
            })}
        </div>
      )}
    </div>
  );
};
