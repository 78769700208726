import { BpText, BpTextProps } from '../BpText/BpText';
import { useMemo } from 'react';
import { generateHTML } from '@tiptap/html';
import { JSONContent } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

type BpTipTapTextProps = BpTextProps & {
  content: string;
  emptyText?: string;
};

export const BpTipTapText = ({
  fontSize,
  maxHeight,
  embedded,
  customPadding,
  className,
  canScroll,
  content,
  emptyText = '',
}: BpTipTapTextProps) => {
  const output = useMemo(() => {
    let json: JSONContent | null = null;
    try {
      json = JSON.parse(content);
    } catch {
      json = null;
    }

    return content && json
      ? generateHTML(json, [Bold, BulletList, Document, Heading, Italic, ListItem, OrderedList, Paragraph, Text])
      : emptyText;
  }, [content, emptyText]);

  return (
    <BpText
      className={className}
      canScroll={canScroll}
      maxHeight={maxHeight}
      fontSize={fontSize}
      embedded={embedded}
      customPadding={customPadding}
    >
      <div dangerouslySetInnerHTML={{ __html: output }} />
    </BpText>
  );
};
