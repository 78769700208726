import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Grid,
  GridColumn,
  GridRow,
  InfoIcon,
  Input,
  Select,
  SelectOptionType,
  Switch,
  Tooltip,
} from '@bp/ui-components';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { MultiValue, SingleValue } from 'react-select';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { GroupFormValues } from './GroupForm';
import { useBpProfileQuery, useSchoolYearsQuery } from '../../client/bp-graphql-client-defs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import classNames from 'classnames';
import styles from './GroupForm.module.scss';

type GroupFormBaseDataProps = {
  readonly: boolean;
  canCollaborate: boolean;
  onCollaborationChange: (isCollaboration: boolean) => void;
};

export const GroupFormBaseData = ({ readonly, canCollaborate, onCollaborationChange }: GroupFormBaseDataProps) => {
  const { t } = useTranslation();
  const { handleChange, handleBlur, setFieldValue, values, errors, touched, setFieldTouched } =
    useFormikContext<GroupFormValues>();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const groupContext = useMemoizedCacheTag('GROUP');
  const profilesContext = useMemoizedCacheTag('PROFILE');

  const [{ data: schoolyearsData }] = useSchoolYearsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: groupContext,
  });

  const [{ data: profilesPoolData }] = useBpProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: profilesContext,
  });

  const schoolyears = schoolyearsData?.schoolYears;

  const selectSchoolyearOpts = useCreateSelectOptions(schoolyears, 'uuid', 'name');
  const adminSingleOpts = useCreateSelectOptions([pimAuthClaims.getProfile()], 'uuid', 'displayName');
  const adminOpts = useCreateSelectOptions(profilesPoolData?.profiles, 'uuid', 'displayName');

  const selectedSchoolyear = selectSchoolyearOpts.find((opt) => {
    return values.schoolyear ? opt.value === values.schoolyear : false;
  }) ?? { label: t('group.schoolyearSpanning'), value: 'schoolyearSpanning' };

  const switchStyles = classNames(styles.collaboration, {
    [styles.disabled]: readonly || !canCollaborate || !permissions?.canCreateCollaborationGroups(),
    [styles['read-only']]: readonly,
  });

  return (
    <Grid useFormGap>
      <GridRow spacingBottom='s'>
        <GridColumn width={6}>
          <Input
            autoFocus
            label={t('common.designation')}
            name={'name'}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={errors.name}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            label={t('common.schoolyear')}
            name={'schoolyear'}
            options={selectSchoolyearOpts}
            value={selectedSchoolyear}
            isClearable={selectSchoolyearOpts.some((opt) =>
              values.schoolyear ? opt.value === values.schoolyear : false,
            )}
            onChange={(event) => {
              const schoolyear = event as SingleValue<SelectOptionType>;
              setFieldTouched('schoolyear', true);
              setFieldValue('schoolyear', schoolyear?.value ?? null);
            }}
            onBlur={handleBlur}
            menuPosition='fixed'
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='s' spacingBottom='s'>
        <Input
          label={t('common.description')}
          name={'description'}
          onChange={handleChange}
          value={values.description}
          {...(errors.description &&
            touched.description && {
              error: errors.description,
            })}
        />
      </GridRow>
      <GridRow spacingTop='s'>
        <GridColumn width={6}>
          <Select
            name={'admins'}
            label={t('collaborations.administration')}
            value={
              !canCollaborate
                ? adminOpts.find((v) => v.value === pimAuthClaims.getProfile().uuid)
                : [...adminOpts.filter((v) => values.admins.includes(v.value as string))]
            }
            options={!canCollaborate ? adminSingleOpts : adminOpts}
            onChange={(event) => {
              const adminOptions = event as MultiValue<SelectOptionType>;
              setFieldTouched('admins', true);
              setFieldValue(
                'admins',
                adminOptions.map((value) => value.value as string),
              );
            }}
            isClearable={canCollaborate}
            isMulti={canCollaborate}
            isSearchable={canCollaborate}
            readonly={!canCollaborate}
            menuPosition='fixed'
          />
        </GridColumn>
        <GridColumn width={6}>
          <div className={switchStyles}>
            <div>
              {t('groups.collaborationGroup')}
              {!canCollaborate && (
                <Tooltip triggerClass={styles.tooltip} content={t('collaborations.addHint')}>
                  <InfoIcon />
                </Tooltip>
              )}
            </div>
            <Switch
              name='collaborating'
              checked={values.collaborating}
              disabled={readonly || !canCollaborate || !permissions?.canCreateCollaborationGroups()}
              onChange={(event) => {
                setFieldValue('collaborating', event.target.checked);
                onCollaborationChange(event.target.checked);
              }}
            />
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
