import { use_GroupOrganizationQuery } from '../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';
import { useMemo } from 'react';

export const useGroupMembersCount = (groupUuid = '') => {
  const [{ data }] = use_GroupOrganizationQuery({
    variables: {
      where: {
        uuid: groupUuid,
      },
    },
    context: useMemoizedCacheTag('GROUP'),
    pause: groupUuid === '',
  });

  return useMemo(() => {
    let group = undefined;
    if (data?.groups && data?.groups.length > 0) {
      group = data?.groups[0];
    }

    const groupMembers = (group?.editorsAggregate?.count ?? 0) + (group?.viewersAggregate?.count ?? 0);

    const organisationUuid = group?.organization?.uuid ?? '';

    return {
      groupMembers,
      organisationUuid,
    };
  }, [data]);
};
