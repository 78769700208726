import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useFormikContext } from 'formik';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { Grid, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { MultiValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useBpProfileQuery } from '../../client/bp-graphql-client-defs';

export const CourseFormTeachers = <
  T extends {
    editors: string[];
  },
>() => {
  const { pimAuthClaims } = useAuthClaims();
  const profilesContext = useMemoizedCacheTag('PROFILE');
  const { t } = useTranslation();

  const [{ data: profilesPoolData }] = useBpProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: profilesContext,
  });

  const { setFieldValue, values, setFieldTouched } = useFormikContext<T>();

  const editorsSelectOpts = useCreateSelectOptions(profilesPoolData?.profiles, 'uuid', 'selectName');

  return (
    <Grid>
      <GridRow>
        <Select
          label={t('rolesInOrganization.teacher_other')}
          value={[...editorsSelectOpts.filter((v) => values.editors.includes(v.value as string))]}
          options={editorsSelectOpts}
          onChange={(event) => {
            const editorOptions = event as MultiValue<SelectOptionType>;
            setFieldTouched('editors', true);
            setFieldValue(
              'editors',
              editorOptions.map((value) => value.value as string),
            );
          }}
          name={'editors'}
          isSearchable
          isMulti
          isClearable
          menuPosition='fixed'
        />
      </GridRow>
    </Grid>
  );
};
