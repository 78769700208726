import { BubbleMenu, Content, EditorContent, useEditor } from '@tiptap/react';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { Button, ButtonGroup } from '@bp/ui-components';
import styles from './BpTipTap.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export type BpTipTapProps = {
  defaultValue: string;
  name?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: string | null;
  label?: string | null;
  placeholder?: string;
  isDense?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  className?: string | undefined;
};

export const BpTipTap = ({
  defaultValue,
  name,
  autoFocus,
  required,
  disabled,
  loading,
  error,
  placeholder,
  isDense,
  onChange,
  onBlur,
  className,
}: BpTipTapProps) => {
  const { t } = useTranslation();

  const [content, setContent] = useState<Content>(null);

  const tipTapClasses = classNames(
    styles['bp-tiptap'],
    { [styles.disabled]: disabled || loading },
    'has-scrollbar',
    className,
  );

  const editorClasses = classNames(styles.editor, { [styles.disabled]: disabled || loading });

  const editor = useEditor(
    {
      extensions: [
        Bold,
        BulletList,
        Document,
        Heading,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Text,
        Placeholder.configure({ placeholder: placeholder, emptyEditorClass: styles['is-editor-empty'] }),
      ],
      content,
      onUpdate: ({ editor }) => {
        if (onChange && !editor?.isDestroyed) {
          onChange(JSON.stringify(editor.getJSON()));
        }
      },
      onBlur: ({ editor }) => {
        if (onBlur && !editor?.isDestroyed) {
          onBlur(JSON.stringify(editor.getJSON()));
        }
      },
      editorProps: {
        attributes: {
          class: tipTapClasses,
        },
      },
    },
    [content],
  );

  useEffect(() => {
    if (defaultValue) {
      setContent(JSON.parse(defaultValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {editor && !editor.isDestroyed && (
        <BubbleMenu className='bubble-menu' tippyOptions={{ duration: 100 }} editor={editor}>
          <ButtonGroup className={styles['button-group']}>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              hierarchy={editor.isActive('heading', { level: 1 }) ? 'primary' : 'secondary'}
            >
              H1
            </Button>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
              hierarchy={editor.isActive('heading', { level: 2 }) ? 'primary' : 'secondary'}
            >
              H2
            </Button>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              hierarchy={editor.isActive('bulletList') ? 'primary' : 'secondary'}
            >
              {t('tiptap.bullet_list')}
            </Button>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              hierarchy={editor.isActive('orderedList') ? 'primary' : 'secondary'}
            >
              {t('tiptap.ordered_list')}
            </Button>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleBold().run()}
              hierarchy={editor.isActive('bold') ? 'primary' : 'secondary'}
            >
              {t('tiptap.bold')}
            </Button>
            <Button
              height={'s'}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              hierarchy={editor.isActive('italic') ? 'primary' : 'secondary'}
            >
              {t('tiptap.italic')}
            </Button>
          </ButtonGroup>
        </BubbleMenu>
      )}
      <EditorContent
        className={editorClasses}
        id={name}
        editor={editor}
        disabled={disabled || loading}
        name={name}
        autoFocus={autoFocus}
        required={required}
      />
      {!isDense && <div className={styles['error-message']}>{error}</div>}
    </>
  );
};
