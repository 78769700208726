import { Form, Formik } from 'formik';
import { Input, Select, SelectOptionType } from '@bp/ui-components';
import {
  useBpCreateTeachingUnitMutation,
  useBpGetTopicsByGroupQuery,
  useBpTeachingUnitsQuery,
  useBpUpdateTeachingUnitMutation,
} from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { FormikHelpers } from 'formik/dist/types';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { connectByUuid } from '../../utils/connectLib';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { ModalBottomButtons } from '../../components/ModalBottomButtons/ModalBottomButtons';

type TeachingUnitFormPropsTypes = {
  teachingUnitUuid?: string | null;
  courseUuid: string;
  onClose: () => void;
  topicUuid?: string;
};

type TeachingUnitValuesType = {
  title: string;
  topic: SelectOptionType;
  desc: string;
};

export const TeachingUnitForm = ({ teachingUnitUuid, courseUuid, onClose, topicUuid }: TeachingUnitFormPropsTypes) => {
  const { t } = useTranslation();
  const edit = !!teachingUnitUuid;
  const context = useMemoizedCacheTag('TEACHING_UNIT');
  const contextTopic = useMemoizedCacheTag('TOPICS');

  const [{ data: courseGroupsData }] = useBpGetTopicsByGroupQuery({
    variables: { where: { uuid: courseUuid } },
    context: contextTopic,
  });

  const [{ data: teachingUnitsData }] = useBpTeachingUnitsQuery({
    variables: { teachingUnitsWhere: { uuid: teachingUnitUuid ?? '' } },
    context,
  });

  const teachingUnit = teachingUnitsData?.teachingUnits[0];
  const usedTeachingTopics = courseGroupsData?.groups[0].teachingTopics ?? [];

  const { pimAuthClaims } = useAuthClaims();
  const [, updateTeachingUnit] = useBpUpdateTeachingUnitMutation();
  const [, createTeachingUnit] = useBpCreateTeachingUnitMutation();

  const validate = (values: TeachingUnitValuesType) => {
    const errors: Record<string, string> = {};
    if (typeof values !== 'undefined') {
      if (!values?.title) {
        errors.title = t('validation.textTooShort');
      }
    }
    return errors;
  };

  const topicParams = (values: TeachingUnitValuesType) => {
    // hack hack hack use isNew !
    if (values.topic.label === values.topic.value) {
      return {
        create: {
          node: {
            title: values.topic.label ?? '',
            owner: connectByUuid(pimAuthClaims.getProfile().uuid),
            organization: connectByUuid(pimAuthClaims.getOrganizationUuid()),
            group: connectByUuid(courseUuid),
          },
        },
      };
    } else {
      // user selected an existing Topic
      return {
        connect: {
          where: {
            node: {
              uuid: (values.topic.value as string) ?? '',
            },
          },
        },
      };
    }
  };

  const handleSubmit = async (values: TeachingUnitValuesType, formikHelpers: FormikHelpers<TeachingUnitValuesType>) => {
    const topic = values.topic;
    if (topic.label === '' && topic.value === '') {
      topic.label = 'root';
      topic.value = courseGroupsData?.groups[0].teachingTopics.at(-1)?.uuid;
    }

    if (edit) {
      const { error } = await updateTeachingUnit(
        {
          where: {
            uuid: teachingUnitUuid,
          },
          update: {
            title: values?.title,
            topic: { disconnect: {}, ...topicParams(values) },
            description: values.desc,
          },
        },
        context,
      );

      if (error) {
        showErrorToast(error);
      } else {
        formikHelpers.resetForm();

        showSuccessToast(t('common.saved'));
        onClose();
      }
    } else {
      const { error } = await createTeachingUnit(
        {
          input: [
            {
              active: true, // default
              note: {
                create: {
                  node: {
                    text: '',
                  },
                },
              },
              title: values?.title ?? '',
              topic: topicParams(values),
              owner: connectByUuid(pimAuthClaims.getProfile().uuid),
              organization: connectByUuid(pimAuthClaims.getOrganizationUuid()),
              group: connectByUuid(courseUuid),
              description: values.desc,
            },
          ],
        },
        context,
      );
      if (error) {
        showErrorToast(error);
      } else {
        formikHelpers.resetForm();

        showSuccessToast(t('common.saved'));
        onClose();
      }
    }
  };

  const selectOptions = useCreateSelectOptions(usedTeachingTopics, 'uuid', 'title').filter(
    (option) => option.label !== 'root',
  );

  const currentTopic = selectOptions.find((o) => {
    return o.value === teachingUnit?.topic.uuid;
  });
  const preselectedTopic = selectOptions.find((o) => {
    return o.value === topicUuid;
  });
  const selectedTopic = currentTopic || preselectedTopic || { value: '', label: '' };

  const initialValues: TeachingUnitValuesType = {
    title: teachingUnit?.title ?? '',
    desc: teachingUnit?.description ?? '',
    topic: selectedTopic,
  };

  return (
    <Formik<TeachingUnitValuesType> initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      {({ values, errors, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
        <Form className='bp__form'>
          <Input
            className='mb-2'
            required
            label={t('common.title')}
            name={'title'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.title}
            error={errors.title}
          />
          <Select
            className='mb-2'
            onBlur={handleBlur}
            required
            label={t('common.directory')}
            options={selectOptions}
            value={selectOptions.find((o) => o.value === values.topic.value)}
            onChange={(event) => {
              if (event === null) {
                setFieldValue('topic', {
                  label: '',
                  value: '',
                });
              } else {
                setFieldValue('topic', {
                  label: (event as SelectOptionType).label,
                  value: (event as SelectOptionType).value,
                });
              }
            }}
            name={'topic'}
            allowCreate
            isClearable
          />
          <BpTipTap
            defaultValue={values.desc}
            onChange={(value) => {
              setFieldValue('desc', value);
            }}
            name={'desc'}
            required={false}
            label={t('common.description')}
            placeholder={t('common.addType', { type: t('common.description') })}
          />
          <ModalBottomButtons
            closeButton={{ callback: onClose }}
            submitButton={{
              disabled: isSubmitting || (errors && Object.values(errors).length > 0),
            }}
            isLoading={isSubmitting}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
