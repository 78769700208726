import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './BpPage.module.scss';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { useDocumentTitle } from '@uidotdev/usehooks';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { BpHeader } from '../BpHeader/BpHeader';
import { ErrorState } from '@bp/ui-components';

type BpPageProps = {
  title: string;
  headline?: string | null;
  subheadline?: string | null;
  hideHeader?: boolean;
  breakHeader?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  noHeadlineMargin?: boolean;
  isForbidden: boolean;
  hideTitleIfForbidden?: boolean;
  children: React.ReactNode;
  className?: string | undefined;
};

export const BpPage: FC<BpPageProps> = ({
  title,
  headline = null,
  subheadline = null,
  hideHeader,
  breakHeader,
  fullWidth,
  fullHeight,
  noHeadlineMargin,
  isForbidden,
  hideTitleIfForbidden,
  children,
  className,
}) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const organization = pimAuthClaims.getOrganization();
  useDocumentTitle(`${!title ? t('app.title.full') : title}${organization ? ` - ${organization.name}` : ''}`);

  const classes = classNames(
    styles.page,
    { [styles['full-width']]: fullWidth, [styles['full-height']]: fullHeight },
    className,
  );

  const topClasses = classNames(styles.top, { [styles['no-header-margin']]: noHeadlineMargin });

  return isForbidden ? (
    <ErrorState type='forbidden' pageTitle={!hideTitleIfForbidden ? title : undefined} />
  ) : (
    <div className={classes}>
      <div className={topClasses}>
        <Breadcrumb className={styles.crumbs} />
        {(title || headline) && !hideHeader && (
          <BpHeader
            headline={!headline ? title : headline}
            subHeadline={subheadline ? subheadline : ''}
            size='l'
            noMargin
            breakLine={breakHeader}
            className={styles.header}
          />
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
