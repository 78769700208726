import { AddIcon, ArrowLeftIcon, ArrowRightIcon, Button } from '@bp/ui-components';
import i18next from 'i18next';
import { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import styles from './BpCalendar.module.scss';
import { BpEventType } from '../EventsList/EventsList';
import classNames from 'classnames';
import { useWindowDimensions } from 'utils/dimensions';

type HighlightDateType = { color: string; highlights: Date[] }[];

type BpCalendarProps = {
  events?: BpEventType[];
  showAddButton?: boolean;
  isOnWhite?: boolean;
  onChange?: (title: string) => void;
  onAdd?: (edit: boolean) => void;
  eventClick?: (uuid: string) => void;
};

export const BpCalendar = ({
  events = [],
  showAddButton = true,
  isOnWhite = false,
  onChange,
  eventClick,
  onAdd,
}: BpCalendarProps) => {
  const { isPhone, isTablet } = useWindowDimensions();

  const today = new Date();

  function getTitle(date: Date): string {
    const month = date.toLocaleString(i18next.language, { month: isPhone || isTablet ? 'short' : 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  const filterByColor = useCallback(
    (color: string): Date[] => {
      return events
        .filter((ev) => (ev?.color === color && ev.type === 'event') || (ev.type === 'event' && !ev.color))
        .map((day) => {
          return day && new Date(day.start.toDate());
        });
    },
    [events],
  );

  const highlightDates: HighlightDateType = useMemo(() => {
    return [
      {
        color: 'green',
        highlights: filterByColor('green') ?? [],
      },
      {
        color: 'orange',
        highlights: filterByColor('orange') ?? [],
      },
      {
        color: 'purple',
        highlights: filterByColor('purple') ?? [],
      },
      {
        color: 'red',
        highlights: filterByColor('red') ?? [],
      },
      {
        color: 'grey',
        highlights: filterByColor('grey') ?? [],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filterByColor]);

  const renderDayContents = useCallback(
    (day: number, date: Date) => {
      const eventsAtDate =
        events
          .filter((ev) => {
            return ev.type !== 'birthday';
          })
          .filter((ev) => {
            const e = ev && new Date(ev.start.toDate());
            return e?.toDateString() === date.toDateString();
          }) ?? [];

      return (
        <div key={day} onClick={eventClick && eventsAtDate[0] ? () => eventClick(eventsAtDate[0].uuid) : undefined}>
          {date.getDate()}
          {eventsAtDate.length > 0 && (
            <div className={styles.dots}>
              {[...eventsAtDate.slice(0, 3)].map((bpDate) => (
                <div key={bpDate.uuid} className={classNames(styles.dot, styles[bpDate.color ?? 'grey'])}></div>
              ))}
            </div>
          )}
        </div>
      );
    },
    [events, eventClick],
  );

  const calendarClasses = classNames('bp__calendar', { 'on-white': isOnWhite });

  return (
    <div className={styles.calendar}>
      <DatePicker
        inline
        selectsEnd={false}
        fixedHeight
        calendarStartDay={1}
        calendarClassName={calendarClasses}
        highlightDates={highlightDates.map((date) => {
          const highlights: { [className: string]: Date[] } = {};
          highlights['highlighted'] = date.highlights;
          return highlights;
        })}
        renderDayContents={renderDayContents}
        locale={i18next.language}
        onChange={(dateString) => {
          const title = getTitle(dateString ?? today);
          if (onChange) onChange(title);
        }}
        renderCustomHeader={({
          monthDate,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles['custom-header']}>
            <div className={styles.controls}>
              <Button
                name='ecrease'
                hierarchy='tertiary'
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                icon={<ArrowLeftIcon />}
              />
              <div className={styles.title}>{getTitle(monthDate)}</div>
              <Button
                name='increase'
                hierarchy='tertiary'
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                icon={<ArrowRightIcon />}
              />
            </div>
            {showAddButton && (
              <Button name='new' hierarchy='tertiary' onClick={() => onAdd?.(true)} icon={<AddIcon />} />
            )}
          </div>
        )}
      />
    </div>
  );
};
