import { useTranslation } from 'react-i18next';
import { ZoomForm } from '../../ZoomForm/ZoomForm';
import { BigBlueButtonForm } from '../../BigBlueButtonForm/BigBlueButtonForm';
import { ThreemaForm } from '../../ThreemaForm/ThreemaForm';
import { Modal } from '@bp/ui-components';
import { LicensedProduct } from '../../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { ThreemaBroadcastForm } from '../../ThreemaBroadcastForm/ThreemaBroadcastForm';
import { BildungsplattformForm } from '../../BildungsplattformForm/BildungsplattformForm';
import { NextcloudForm } from '../../NextcloudForm/NextcloudForm';

type Props = {
  isOpen: boolean;
  closeModal: (open: boolean) => void;
  data?: LicensedProduct;
};

export const BookingsModal = ({ isOpen, closeModal, data }: Props) => {
  const { t } = useTranslation();

  const selectForm = () => {
    if (data) {
      if (data.__typename === 'Nextcloud') return <NextcloudForm data={data} closeModal={() => closeModal(false)} />;
      if (data.__typename === 'Zoom') return <ZoomForm data={data} closeModal={() => closeModal(false)} />;
      if (data.__typename === 'BigBlueButton')
        return <BigBlueButtonForm data={data} closeModal={() => closeModal(false)} />;
      if (data.__typename === 'Threema') return <ThreemaForm data={data} closeModal={() => closeModal(false)} />;
      if (data.__typename === 'ThreemaBroadcast')
        return <ThreemaBroadcastForm data={data} closeModal={() => closeModal(false)} />;
      if (data.__typename === 'Bildungsplattform')
        return <BildungsplattformForm data={data} closeModal={() => closeModal(false)} />;

      // TODO: mailbox.org
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal(false)}
      title={`${t('settings.title')} ${data?.__typename}`}
    >
      <div>{selectForm()}</div>
    </Modal>
  );
};
