import { FC, useState } from 'react';
import { Button } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { PageLayoutPlaceholder } from '../../components/PageLayoutPlaceholder/PageLayoutPlaceholder';
import { BpSubpage } from '../../components/BpSubpage/BpSubpage';
import { AboutModal } from '../../components/Modals/AboutModal/AboutModal';
import { useParams } from 'react-router-dom';

export const SignupPage: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { invitationToken } = useParams();

  const [showAbout, setShowAbout] = useState<boolean>(false);

  return (
    <BpSubpage>
      <PageLayoutPlaceholder>
        <span>{t('signup.salutation')}</span>
        <span style={{ color: 'var(--color-grey-light)' }}>{t('signup.connectInfo')}</span>
        <span style={{ color: 'var(--color-grey-light)' }}>{t('signup.existingInfo')}</span>
        <Button
          disabled={true}
          hierarchy={auth.isAuthenticated ? 'primary' : 'secondary'}
          onClick={() => auth.signinRedirect({ prompt: 'login signup', login_hint: invitationToken })}
          fullWidth
          className='mt-2 mb-3'
        >
          {t('signup.connect')}
        </Button>
        <span>{t('signup.createInfo')}</span>
        <Button
          hierarchy={!auth.isAuthenticated ? 'primary' : 'secondary'}
          onClick={() =>
            auth.signinRedirect({
              prompt: 'signup login consent',
              login_hint: invitationToken,
            })
          }
          fullWidth
          className='mt-2'
        >
          {t('signup.create')}
        </Button>
        <Button fullWidth hierarchy='tertiary' onClick={() => setShowAbout(true)}>
          {t('common.about')}
        </Button>
      </PageLayoutPlaceholder>
      <AboutModal state={showAbout} setState={(value) => setShowAbout(value)} />
    </BpSubpage>
  );
};
