import { ArrowLeftIcon, ArrowRightIcon, SelectOptionType } from '@bp/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { showErrorToast } from '../../utils/showErrorToast';
import {
  GroupType,
  useBpCreateGroupsMutation,
  useBpUpdateGroupsMutation,
  useGroupAsCourseQuery,
} from '../../client/bp-graphql-client-defs';
import { CourseFormBaseData } from './CourseFormBaseData';
import styles from './CourseForm.module.scss';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { courseFormSchema } from './validation/courseFormSchema';
import { GroupFormProfiles } from '../GroupForm/GroupFormProfiles';
import { GroupFormAdmins } from '../GroupForm/GroupFormAdmins';
import { CollaborationAdminsValues } from '../GroupForm/GroupForm';
import { splitProfilesByOrganization } from '../../utils/splitProfilesByOrganization';
import { CourseFormTeachers } from './CourseFormTeachers';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import classNames from 'classnames';

export type CourseFormValues = {
  name: string;
  schoolyear: string;
  subject: SelectOptionType | null;
  groups?: string[];
  grades?: string[];
  classes?: string[];
  admins: string[];
  editors: string[];
  viewers: string[];
  collaborationSchools: string[];
  collaborationAdmins: CollaborationAdminsValues;
  collaborating: boolean;
};

export type CourseFormProps = {
  courseUuid: string;
  canCollaborate?: boolean;
  viaSettings?: boolean;
  onClose: () => void;
};

export const CourseForm = ({ courseUuid, canCollaborate, onClose, viaSettings = false }: CourseFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [step, setStep] = useState<number>(0);
  const [maxSteps, setMaxSteps] = useState<number>(!canCollaborate ? 1 : 2);
  const [loading, setLoading] = useState(false);

  const context = useMemoizedCacheTag('COURSE');
  const [{ data: courseData }] = useGroupAsCourseQuery({
    variables: { where: { uuid: courseUuid ?? '' } },
    context,
  });

  const currentCourse = courseData?.groups[0];
  const [, bpCreateGroup] = useBpCreateGroupsMutation();
  const [, bpUpdateGroup] = useBpUpdateGroupsMutation();

  const handleSubmit = async (values: CourseFormValues, formHelpers: FormikHelpers<CourseFormValues>) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const { name, subject, schoolyear, groups, grades, collaborationAdmins, admins, editors, viewers } = values;

    if ((canCollaborate || !viaSettings) && !editors.includes(pimAuthClaims.getProfile().uuid)) {
      editors.push(pimAuthClaims.getProfile().uuid);
    }

    if (!admins.includes(pimAuthClaims.getProfile().uuid)) {
      admins.push(pimAuthClaims.getProfile().uuid);
    }

    const collabAdmins = Object.values(collaborationAdmins)
      .flatMap((v) => v?.map((item) => item.uuid))
      .filter((item) => item);

    const updateCourse = async () => {
      const { error } = await bpUpdateGroup(
        {
          update: {
            name: name,
            viewers: viewers,
            admins: [...admins, ...collabAdmins],
            editors: editors,
          },
          uuid: courseUuid,
        },
        context,
      );

      if (!error) {
        formHelpers.resetForm();
        onClose();
      } else {
        showErrorToast(error);
      }
    };

    const createCourse = async () => {
      const { error } = await bpCreateGroup(
        {
          input: {
            name,
            organization: pimAuthClaims.getOrganizationUuid(),
            groupType: GroupType.Course,
            admins: [...admins, ...collabAdmins],
            editors,
            viewers,
            schoolYear: schoolyear ?? '',
            subject: (subject?.value as string) ?? '',
            groups: groups ?? [],
            grades: grades ?? [],
          },
        },
        context,
      );

      if (!error) {
        formHelpers.resetForm();
        onClose();
      } else {
        showErrorToast(error);
      }
    };

    courseUuid !== '' ? await updateCourse() : await createCourse();
    setLoading(false);
  };

  const otherOrganizations = currentCourse?.admins
    ? splitProfilesByOrganization(
        currentCourse.admins.map((a) => ({
          uuid: a.uuid,
          organization: a.organization,
          displayName: a.displayName ?? '',
        })),
        organizationUuid,
      )
    : null;

  const localAdmins =
    currentCourse?.admins.filter((admin) => admin.organization.uuid === organizationUuid).map((admin) => admin.uuid) ??
    [];

  const initialValues: CourseFormValues = {
    name: currentCourse?.name ?? '',
    schoolyear: currentCourse?.schoolYear?.uuid ?? '',
    subject: currentCourse?.subject
      ? {
          label: currentCourse?.subject.name,
          value: currentCourse?.subject.uuid,
        }
      : null,
    groups: courseUuid ? currentCourse?.groups.map((g) => g.uuid) : undefined,
    grades: courseUuid && currentCourse?.grades ? currentCourse?.grades : [],
    admins: localAdmins,
    editors: currentCourse
      ? currentCourse?.editors.map((p) => p.uuid)
      : canCollaborate || !viaSettings
        ? [pimAuthClaims.getProfile().uuid]
        : [],
    viewers: currentCourse ? currentCourse.viewers.map((value) => value.uuid) : [],
    collaborationAdmins: otherOrganizations ?? {},
    collaborationSchools: otherOrganizations ? Object.keys(otherOrganizations) : [],
    collaborating: !!otherOrganizations,
  };

  function handleCollaborationChange(isCollaboration: boolean) {
    setMaxSteps(isCollaboration && canCollaborate ? 3 : canCollaborate || isCollaboration ? 2 : 1);
  }

  return (
    <Formik<CourseFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnBlur={true}
      enableReinitialize={true}
      validationSchema={courseFormSchema}
    >
      {({ errors, values }) => (
        <Form className={styles['course-form']}>
          <div className={styles.slider}>
            <div
              tabIndex={0}
              className={styles.slide}
              style={{ left: step === 0 ? '0' : step > 0 ? `${step * -100}%` : `${step * 100}%` }}
            >
              <CourseFormBaseData
                readonly={!!currentCourse && !canCollaborate}
                canCollaborate={!!canCollaborate}
                onCollaborationChange={handleCollaborationChange}
              />
            </div>

            {canCollaborate && (
              <div
                tabIndex={0}
                className={styles.slide}
                style={{ left: step === 1 ? '0' : step > 1 ? `${(step + 1) * -100}%` : `${(step + 1) * 100}%` }}
              >
                <CourseFormTeachers<CourseFormValues> />
              </div>
            )}

            <div
              tabIndex={0}
              className={classNames(styles.slide, styles.profiles)}
              style={{
                left:
                  step === (canCollaborate ? 2 : 1)
                    ? '0'
                    : step > (canCollaborate ? 2 : 1)
                      ? `${(step + (canCollaborate ? 2 : 1)) * -100}%`
                      : `${(step + (canCollaborate ? 2 : 1)) * 100}%`,
              }}
            >
              <GroupFormProfiles<CourseFormValues> />
            </div>

            {values.collaborating && (
              <div
                tabIndex={0}
                className={styles.slide}
                style={{
                  left:
                    step === (canCollaborate ? 3 : 2)
                      ? '0'
                      : step > (canCollaborate ? 3 : 2)
                        ? `${(step + (canCollaborate ? 3 : 2)) * -100}%`
                        : `${(step + (canCollaborate ? 3 : 2)) * 100}%`,
                }}
              >
                <GroupFormAdmins<CourseFormValues> />
              </div>
            )}
          </div>

          <ModalBottomButtons
            closeButton={{
              callback: onClose,
            }}
            submitButton={{
              disabled: Object.keys(errors).length > 0 || step !== maxSteps,
            }}
            additionalButtons={[
              {
                text: t('common.back'),
                icon: <ArrowLeftIcon className={'small'} />,
                disabled: step === 0,
                callback: () => {
                  setStep((prev) => prev - 1);
                },
              },
              {
                text: t('common.next'),
                icon: <ArrowRightIcon className={'small'} />,
                iconPosition: 'right',
                disabled: step === maxSteps || Object.keys(errors).length > 0,
                callback: () => setStep((prev) => prev + 1),
              },
            ]}
            isLoading={loading}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
