import { EventRecurrence } from '@bp/bp-graphql-types';
import i18n from 'i18next';
import { showSuccessToast } from './showSuccessToast';
import { showErrorToast } from './showErrorToast';
import { backendApi } from './backendApi';
import { ProfileClaim } from '@bp/pim-auth-constants';

function getRecurrenceEventText(recurrence: EventRecurrence | null | undefined) {
  let text = '';
  if (recurrence === EventRecurrence.Daily) text = i18n.t('appointments.recurrences.daily');
  else if (recurrence === EventRecurrence.Monthly) text = i18n.t('appointments.recurrences.monthly');
  else if (recurrence === EventRecurrence.Weekly) text = i18n.t('appointments.recurrences.weekly');
  else if (recurrence === EventRecurrence.Workdays) text = i18n.t('appointments.recurrences.workdays');
  else if (recurrence === EventRecurrence.Yearly) text = i18n.t('appointments.recurrences.yearly');
  return text;
}

function getCategoryText(categories: string[]) {
  let text = '';

  categories.forEach((category) => {
    if (category === 'exam') {
      text = i18n.t('events.categories.exam');
    } else if (category === 'event') {
      text = i18n.t('events.categories.event');
    } else if (category === 'lesson') {
      text = i18n.t('events.categories.lesson');
    }
  });

  return text;
}

async function copyEventLinkToClipboard(uri: string) {
  try {
    await navigator.clipboard.writeText(uri);
    showSuccessToast(i18n.t('common.linkCopied'));
    return Promise.resolve();
  } catch {
    showErrorToast(i18n.t('meetings.noVirtualLocation'));
    return Promise.reject();
  }
}

async function startEvent(token: string) {
  const resp = await backendApi.startConference(token);
  if (resp.ok) {
    showSuccessToast(i18n.t('meetings.eventDidStart'));
    Promise.resolve();
  } else {
    showErrorToast(i18n.t('meetings.eventDidNotStart'));
    Promise.reject();
  }
}

async function startAndJoinEvent(token: string, profile: ProfileClaim, asModerator: boolean) {
  const joinUrl = await backendApi.joinConference(token, {
    displayName: profile.displayName ?? '',
    email: profile.email ?? '',
    profileUuid: profile.uuid ?? '',
    moderator: asModerator,
  });
  const url = await joinUrl.text();
  if (url.includes('https://')) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    Promise.resolve();
  } else {
    showErrorToast(i18n.t('meetings.noVirtualLocation'));
    Promise.reject();
  }
}

async function endEvent(token: string) {
  const resp = await backendApi.endConference(token);
  if (resp.ok) {
    showSuccessToast(i18n.t('meetings.eventDidEnd'));
    Promise.resolve();
  } else {
    showErrorToast(i18n.t('meetings.eventDidNotEnd'));
    Promise.reject();
  }
}

export { getCategoryText, getRecurrenceEventText, copyEventLinkToClipboard, startEvent, startAndJoinEvent, endEvent };
