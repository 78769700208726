import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './BpSubpage.module.scss';
import { ErrorState } from '@bp/ui-components';

type BpSubpageProps = {
  fullWidth?: boolean;
  fullHeight?: boolean;
  isForbidden?: boolean;
  children: React.ReactNode;
  className?: string | undefined;
};

export const BpSubpage: FC<BpSubpageProps> = ({ fullWidth, fullHeight, isForbidden, children, className }) => {
  const classes = classNames(
    styles.subpage,
    { [styles['full-width']]: fullWidth, [styles['full-height']]: fullHeight },
    className,
  );

  return isForbidden ? <ErrorState type='forbidden' /> : <div className={classes}>{children}</div>;
};
