import { Form, Formik } from 'formik';
import {
  useBildungsplattformsQuery,
  useCreateBildungsplattformsMutation,
  useUpdateBildungsplattformsMutation,
} from '../../client/bp-graphql-client-defs';
import { Checkbox, DatePicker, Select, SelectOptionType } from '@bp/ui-components';
import { LicensedProduct } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ensureDate } from '../../utils/dateCalculations';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { SingleValue } from 'react-select';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { validationSchema } from './bildungsplattformFormSchema';

type BildungsplattformValuesType = {
  allowStudentDirectMessages: boolean;
  notAfter: string;
  notBefore: string;
  version: string;
};

type BildungsplattformFormProps = { data: LicensedProduct; closeModal: () => void };

export const BildungsplattformForm = ({ data, closeModal }: BildungsplattformFormProps) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const [bildungsplattformsQueryResult] = useBildungsplattformsQuery({
    variables: { where: { uuid: data.uuid ?? '0' } },
  });
  const currentBooking = bildungsplattformsQueryResult.data?.bildungsplattforms.shift();

  const [, updateBildungsplattform] = useUpdateBildungsplattformsMutation();
  const [, createBildungsplattform] = useCreateBildungsplattformsMutation();

  const handleClose = () => {
    closeModal();
  };

  const initialValues: BildungsplattformValuesType = {
    version: currentBooking?.organizationConnection.edges[0].properties.version ?? '',
    allowStudentDirectMessages: currentBooking?.allowStudentDirectMessages ?? false,
    notAfter: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notAfter).toUTCString(),
    notBefore: ensureDate(currentBooking?.organizationConnection.edges[0].properties.notBefore).toUTCString(),
  };

  const onSubmit = async (values: BildungsplattformValuesType) => {
    if (perms?.canUpdateBildungsplattform({ uuid: organizationUuid })) {
      if (data.uuid === undefined) {
        const resp = await createBildungsplattform({
          input: [
            {
              allowStudentDirectMessages: values.allowStudentDirectMessages,
              organization: {
                connect: {
                  overwrite: true,
                  edge: {
                    notBefore: values.notBefore,
                    notAfter: values.notAfter,
                    version: values.version,
                  },
                  where: { node: { uuid: organizationUuid } },
                },
              },
            },
          ],
        });
        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      } else {
        const resp = await updateBildungsplattform({
          update: {
            allowStudentDirectMessages: values.allowStudentDirectMessages,
            organization: {
              update: {
                edge: {
                  notBefore: values.notBefore,
                  notAfter: values.notAfter,
                  version: values.version,
                },
              },
            },
          },
          where: { uuid: data.uuid },
        });
        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      }
    }
  };

  const selectOpts: SelectOptionType[] = [
    {
      label: t('app.pro'),
      value: 'pro',
    },
    {
      label: t('app.basic'),
      value: 'basic',
    },
    {
      label: t('app.community'),
      value: 'community',
    },
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        const onClose = () => {
          resetForm();
          handleClose();
        };

        return (
          <Form>
            <Select
              options={selectOpts}
              defaultValue={{ value: initialValues.version, label: initialValues.version }}
              onChange={(event) => {
                const a: SelectOptionType | null = event as SingleValue<SelectOptionType>;
                setFieldValue('version', a?.value);
              }}
              name={'version'}
              label={t('common.version')}
            />

            <DatePicker
              label={t('institution.bookedFromDate')}
              onChange={(e) => {
                setFieldValue('notBefore', e?.toUTCString());
              }}
              value={ensureDate(values.notBefore)}
              name={'notBefore'}
              showMonthYearDropdown
            />

            <DatePicker
              label={t('institution.bookedUntilDate')}
              onChange={(e) => {
                setFieldValue('notAfter', e?.toUTCString());
              }}
              value={ensureDate(values.notAfter)}
              name={'notAfter'}
              showMonthYearDropdown
            />

            <Checkbox
              className='mb-2'
              label={t('institution.allowStudentDirectMessages')}
              name={'allowStudentDirectMessages'}
              onChange={handleChange}
              checked={values.allowStudentDirectMessages ?? false}
              error={errors.allowStudentDirectMessages}
            />
            <ModalBottomButtons errors={errors} closeButton={{ callback: onClose }} isLoading={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};
