import styles from './TeachingUnitsTree.module.scss';
import { DndProvider } from 'react-dnd';
import {
  DragLayerMonitorProps,
  DropOptions,
  getBackendOptions,
  getDescendants,
  getParents,
  MultiBackend,
  NodeModel,
  Tree,
} from '@minoru/react-dnd-treeview';
import { TeachingUnitsTreeItem } from './TeachingUnitsTreeItem';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  useBpDeleteTeachingTopicMutation,
  useBpDeleteTeachingUnitsMutation,
  useBpGetTopicsByGroupQuery,
  useBpTeachingUnitsQuery,
  useBpUpdateTeachingTopicsConnectionsMutation,
  useBpUpdateTeachingUnitMutation,
} from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@bp/ui-components';
import { CustomData, TreeItemType } from './types';
import { TeachingUnitsTreeDragPreview } from './TeachingUnitsTreeDragPreview';
import { TeachingUnitsTreeDropPreview } from './TeachingUnitsTreeDropPreview';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';

type TeachingUnitsTreeProps = {
  courseUuid: string | undefined;
  activeTeachingUnitUuid?: string | null;
  isEditable?: boolean;
  onTeachingUnitSelect: (uuid: string) => void;
  onTeachingUnitAdd?: (parentUuid: string | null) => void;
  onTopicAdd?: (parentUuid: string) => void;
  onTopicEdit?: (topicUuid: string) => void;
};

export const TeachingUnitsTree: FC<TeachingUnitsTreeProps> = ({
  courseUuid,
  isEditable = false,
  activeTeachingUnitUuid,
  onTeachingUnitSelect,
  onTeachingUnitAdd,
  onTopicAdd,
  onTopicEdit,
}) => {
  const { t } = useTranslation();

  const [tree, setTree] = useState<NodeModel<CustomData>[] | null>(null);

  const context = useMemoizedCacheTag('TEACHING_UNIT');

  const [{ data: topicsData }] = useBpGetTopicsByGroupQuery({
    variables: {
      where: {
        uuid: courseUuid,
      },
    },
    context: context,
  });

  const [{ data: teachingUnitsData }] = useBpTeachingUnitsQuery({
    context: context,
    variables: {
      teachingUnitsWhere: {
        group: {
          uuid: courseUuid,
        },
      },
    },
  });

  const teachingUnits = useMemo(
    () => (teachingUnitsData?.teachingUnits ? teachingUnitsData.teachingUnits : []),
    [teachingUnitsData],
  );

  const teachingTopics = useMemo(() => {
    return topicsData?.groups[0]?.teachingTopics ? topicsData.groups[0].teachingTopics : [];
  }, [topicsData]);

  const rootTopic = teachingTopics.find((teachingTopic) => {
    return teachingTopic.topicParentConnection.edges.length === 0;
  });

  const [, connectTopic] = useBpUpdateTeachingTopicsConnectionsMutation();
  const [, updateTeachingUnit] = useBpUpdateTeachingUnitMutation();
  const [, deleteTeachingUnit] = useBpDeleteTeachingUnitsMutation();
  const [, deleteTopic] = useBpDeleteTeachingTopicMutation();

  useEffect(() => {
    const _tree: NodeModel<CustomData>[] =
      teachingTopics
        .map((topic, index) => {
          const units = teachingUnits.filter((unit) => {
            return unit.topic.uuid === topic.uuid;
          });
          // do  we have teachingunits as children?
          if (units && units.length > 0) {
            return [
              {
                id: topic.uuid,
                parent: topic.topicParentConnection.edges[0]?.node.uuid ?? 0,
                droppable: true,
                text: topic.title,
                data: {
                  type: TreeItemType.topic,
                },
              },
              ...units.map((tu) => {
                return {
                  id: tu.uuid,
                  parent: topic.uuid ?? 0,
                  droppable: false,
                  text: tu.title,
                  data: {
                    type: TreeItemType.teachingUnit,
                  },
                };
              }),
            ];
          }
          // no teachingUnit
          return {
            id: topic.uuid,
            parent: topic.topicParentConnection.edges[0]?.node.uuid ?? 0,
            droppable: true,
            text: topic.title,
            data: {
              type: TreeItemType.topic,
            },
          };
        })
        // flatten the array, we receive an array of arrays if we have teachingUnits as children
        .flat(2) ?? [];
    setTree(_tree);
  }, [teachingTopics, teachingUnits]);

  const handleTopicMove = async (newTree: NodeModel<CustomData>[], options: DropOptions<CustomData>) => {
    setTree(newTree);
    const topicUuid = options.dragSourceId;
    const targetUuid = options.dropTargetId;

    await connectTopic(
      {
        update: {
          topicParent: {
            disconnect: {},
            connect: {
              where: {
                node: {
                  uuid: targetUuid as string,
                },
              },
            },
          },
        },
        where: {
          uuid: topicUuid as string,
        },
      },
      context,
    );
  };

  const handleTeachingUnitMove = async (newTree: NodeModel<CustomData>[], options: DropOptions<CustomData>) => {
    setTree(newTree);

    const topicUuid = options.dragSourceId;
    const targetUuid = options.dropTargetId;

    updateTeachingUnit(
      {
        where: {
          uuid: topicUuid as string,
        },
        update: {
          topic: {
            connect: {
              where: {
                node: {
                  uuid: targetUuid as string,
                },
              },
            },
            disconnect: {},
          },
        },
      },
      context,
    );
  };

  const handleDeleteTeachingUnit = async (uuid: string) => {
    if (tree) {
      const deleteIds = [uuid, ...getDescendants(tree, uuid).map((node) => node.id)];
      const newTree = tree.filter((node) => !deleteIds.includes(node.id));
      setTree(newTree);
    }

    await deleteTeachingUnit(
      {
        where: {
          uuid: uuid,
        },
      },
      context,
    );
  };

  const handleDeleteTopic = async (uuid: string) => {
    if (tree) {
      const deleteIds = [uuid, ...getDescendants(tree, uuid).map((node) => node.id)];
      const newTree = tree.filter((node) => !deleteIds.includes(node.id));
      setTree(newTree);
    }
    await deleteTopic(
      {
        where: {
          uuid: uuid,
        },
      },
      { additionalTypenames: ['TeachingTopic'] },
    );
  };

  return (
    <div className={styles['teaching-units-tree']}>
      {teachingTopics.length === 0 ? (
        <EmptyState hideIcon subtitle={t('teachingUnits.emptyMessage')} padding='m' />
      ) : (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          {tree && (
            <Tree<CustomData>
              sort={true}
              canDrag={() => isEditable}
              tree={tree}
              rootId={rootTopic?.uuid || 0}
              render={(node, { isOpen, onToggle, handleRef: dragHandleRef }) => {
                return (
                  <TeachingUnitsTreeItem
                    node={node}
                    dragHandleRef={dragHandleRef}
                    childrenCount={tree ? tree.filter((item) => item.parent === node.id).length : 0}
                    isEditable={isEditable}
                    isOpen={isOpen}
                    isSelected={node.id === activeTeachingUnitUuid}
                    onToggle={onToggle}
                    onSelect={(node) => {
                      onTeachingUnitSelect(node.id as string);
                    }}
                    onTopicEdit={onTopicEdit ? (topicUuid) => onTopicEdit(topicUuid) : undefined}
                    onTopicAdd={onTopicAdd ? (parentUuid) => onTopicAdd(parentUuid) : undefined}
                    onTeachingUnitAdd={onTeachingUnitAdd ? (parentUuid) => onTeachingUnitAdd(parentUuid) : undefined}
                    onDelete={node.droppable ? handleDeleteTopic : handleDeleteTeachingUnit}
                  />
                );
              }}
              dragPreviewRender={(monitorProps: DragLayerMonitorProps<CustomData>) => (
                <TeachingUnitsTreeDragPreview text={monitorProps.item.text} type={monitorProps.item.data?.type} />
              )}
              placeholderRender={(node: NodeModel<CustomData>) => (
                <TeachingUnitsTreeDropPreview text={node.text} type={node.data?.type} />
              )}
              onDrop={(tree, options) => {
                if (options?.dragSource?.data?.type === 'topic') {
                  handleTopicMove(tree, options);
                }
                if (options?.dragSource?.data?.type === 'teachingUnit') {
                  handleTeachingUnitMove(tree, options);
                }
                (document.activeElement as HTMLDivElement).blur();
              }}
              enableAnimateExpand
              classes={{
                draggingSource: styles['dragging-source'],
                dropTarget: styles['drop-target'],
              }}
              initialOpen={
                activeTeachingUnitUuid ? getParents(tree, activeTeachingUnitUuid).map((parent) => parent.id) : false
              }
            />
          )}
        </DndProvider>
      )}
      {isEditable && <div className={styles['drag-hint']}>{t('teachingUnits.dragHint')}</div>}
    </div>
  );
};
