import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '@bp/ui-components';
import styles from './App.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { PageLayoutPlaceholder } from './components/PageLayoutPlaceholder/PageLayoutPlaceholder';
import { App as CapApp } from '@capacitor/app';
import { bpUserManager, capacitorStorage } from './BpAuthProvider';
import { Browser } from '@capacitor/browser';
import { updateClaims } from './utils/authStore';
import { STORAGE_KEY } from './utils/constants';

type RequireAuthProps = {
  children?: React.ReactNode;
};

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const [hasTriedSigning, setHasTriedSigning] = useState(false);

  const auth = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        const user = await bpUserManager.getUserManager().signinCallback(url);
        // UserManager.storeUser stores the user with wrong key :-(
        // await bpUserManager.getUserManager().storeUser(user ?? null);
        await capacitorStorage.setItem(STORAGE_KEY, JSON.stringify(user));
        Browser.close();
      }
    });
  }, [auth]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessDenied = params.get('error') === 'access_denied';

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSigning) {
      void auth.signinRedirect();
      setHasTriedSigning(true);
    }
  }, [auth, hasTriedSigning]);

  // TODO: find reasonable interval
  const autoUpdateInterval = 10_000;
  const autoUpdateClaims = useCallback(() => {
    if (auth.isAuthenticated) {
      void updateClaims();
    }
  }, [auth]);

  useEffect(() => {
    const id = setInterval(() => autoUpdateClaims(), autoUpdateInterval);

    return () => clearInterval(id);
  }, [autoUpdateClaims, autoUpdateInterval]);

  if (auth.isLoading) {
    return (
      <PageLayoutPlaceholder>
        <div>Loading...</div>
      </PageLayoutPlaceholder>
    );
  }

  if (auth.error) {
    return (
      <PageLayoutPlaceholder>
        <div>
          {auth.error.message} ({auth.error.name})
        </div>
        <div className={styles.vspace}>
          <Button hierarchy='primary' onClick={() => auth.signinRedirect()} fullWidth={true}>
            {t('auth.login')}
          </Button>
        </div>
      </PageLayoutPlaceholder>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <PageLayoutPlaceholder>
        {!accessDenied && (
          <div className={styles.vspace}>
            <Button hierarchy='primary' onClick={() => auth.signinRedirect()} fullWidth={true}>
              {t('auth.login')}
            </Button>
          </div>
        )}
        {accessDenied && (
          <>
            <div className={styles.loginseparator}>{t(params.get('error_description') ?? 'auth.error')}</div>
            <div className={styles.vspace}>
              Leider hat der Benutzer mit dem Sie aktuell in TK-Login angemeldet sind keinen Zugriff auf diese
              Anwendung.
              <br />
              <br />
              Bitte melden Sie sich ab und dann mit einem anderen Benutzer an.
            </div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => auth.signoutRedirect()} fullWidth={true}>
                {t('auth.logout')}
              </Button>
            </div>
          </>
        )}
      </PageLayoutPlaceholder>
    );
  }

  return <>{children}</>;
};
