import { FC, ReactNode } from 'react';
import styles from './BpText.module.scss';
import classNames from 'classnames';

export type BpFontSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';

export type BpTextProps = {
  fontSize?: BpFontSize;
  maxHeight?: string;
  embedded?: boolean;
  customPadding?: string;
  canScroll?: boolean;
  children?: string | ReactNode;
  className?: string | undefined;
};

export const BpText: FC<BpTextProps> = ({
  fontSize = 'm',
  maxHeight = '100%',
  embedded,
  customPadding,
  canScroll = false,
  children,
  className,
}) => {
  const classes = classNames(styles['bp-text'], { 'has-scrollbar': canScroll }, className);

  return (
    <div
      className={classes}
      style={{
        maxHeight: maxHeight,
        fontSize: `var(--font-size-${fontSize})`,
        padding: embedded ? '0 0 0 0' : `${customPadding}`,
      }}
    >
      {children}
    </div>
  );
};
