import classNames from 'classnames';
import { CSSProperties, FC, Ref } from 'react';
import styles from './BpLink.module.scss';
import { Chip, MeetingActiveIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { StatusChip } from 'components/StatusChip/StatusChip';

type BpLinkProps = {
  value: string;
  isEditorOfCourse: boolean;
  counter?: number;
  counterHint?: string;
  inProgress?: boolean;
  inProgressHint?: string;
  hasNews?: boolean;
  hasNewsHint?: string;
  allowAfterDue?: boolean;
  maxWidth?: string;
  onNavigate?: () => void;
  className?: string | undefined;
  style?: CSSProperties;
  ref?: Ref<HTMLDivElement>;
};

export const BpLink: FC<BpLinkProps> = ({
  value,
  isEditorOfCourse,
  counter,
  counterHint,
  inProgress,
  inProgressHint,
  hasNews,
  hasNewsHint,
  allowAfterDue,
  maxWidth,
  onNavigate,
  className,
  style,
  ref,
}) => {
  const { t } = useTranslation();

  const classes = classNames(styles['bp-link'], { [styles['no-link']]: !onNavigate }, className);

  return (
    <div className={classes} style={{ ...style, maxWidth: maxWidth ?? undefined }} ref={ref}>
      <div className={styles.value} onClick={onNavigate}>
        {value}
      </div>
      {!!counter && counter > 0 && isEditorOfCourse && <Chip title={counterHint} isSmall value={counter} />}
      {inProgress && (
        <div title={inProgressHint ?? t('groups.activeMeetingHint')}>
          <MeetingActiveIcon />
        </div>
      )}
      {hasNews && <div className='bp__dot' title={hasNewsHint ?? t('common.news')}></div>}
      {allowAfterDue && <StatusChip status={'delayed'} isEditorOfCourse={isEditorOfCourse} />}
    </div>
  );
};
