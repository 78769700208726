import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { Button, ButtonGroup, Card, Grid, GridColumn, GridRow, Table, TableColumns } from '@bp/ui-components';
import { getTokens } from '../../../utils/authStore';
import { useBpPimOrganizationsQuery } from '../../../client/bp-graphql-client-defs';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';

type OrganizationsListDataType = {
  uuid: string;
  name: string;
  bpEnabled: boolean;
};

export const OrganizationsSubpage: FC = () => {
  const { t } = useTranslation();

  const context = useMemoizedCacheTag('ORGANIZATION');

  const [returnLog, setReturnLog] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);

  const [{ data }, refetchOrganizations] = useBpPimOrganizationsQuery({
    variables: {
      where: {},
    },
    context,
  });

  const organizations: OrganizationsListDataType[] = useMemo(() => {
    return (
      data?.pimOrganizations.map((o) => {
        return {
          ...o,
          bpEnabled: o.bpEnabled ?? false,
        };
      }) ?? []
    );
  }, [data]);

  const createPersonColumns = (): TableColumns<OrganizationsListDataType>[] => {
    return [
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 250,
        canExpand: true,
      },
      {
        header: t('common.uuid'),
        accessorKey: 'uuid',
        id: 'uuid',
        size: 100,
      },
      {
        header: t('app.title.short'),
        accessorKey: 'bpEnabled',
        type: 'boolean',
        id: 'bpEnabled',
      },
    ];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableColumns = useMemo(createPersonColumns, []);

  const memoizedData = useMemo((): OrganizationsListDataType[] => {
    return data ? organizations : [];
  }, [data, organizations]);

  const handleSetup = async (row: OrganizationsListDataType) => {
    await callActionAndHandleResult(
      `${import.meta.env.VITE_APP_BACKEND_URI}${import.meta.env.VITE_APP_BACKEND_REST_PATH}/setup/${row.uuid}`,
      JSON.stringify({
        uuid: row.uuid,
        matrix: true,
      }),
    );
  };

  const callActionAndHandleResult = async (url: string, body?: BodyInit | null | undefined) => {
    setRunning(true);
    setReturnLog('');
    const tokens = await getTokens();
    const result = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${tokens?.access_token}`, 'content-type': 'application/json' },
      body: body,
    });

    setReturnLog(JSON.stringify(await result.json(), null, 2));
    refetchOrganizations({ requestPolicy: 'network-only' });
    setRunning(false);
  };

  return (
    <BpSubpage>
      <Grid>
        <GridRow>
          <GridColumn width={9}>
            <Table<OrganizationsListDataType>
              showBorderRadius
              showShadow
              canScroll
              minHeight={600}
              breakpoint={null}
              isOnWhite={false}
              columns={tableColumns}
              data={memoizedData}
              showActionBar
              actionBarSettings={{
                showExpertFilter: false,
                showAddButton: false,
                showBulkEdit: false,
                showPrintButton: false,
              }}
              lastColWidth='80px'
              lastCol={(row) => {
                return (
                  <ButtonGroup>
                    <Button
                      hierarchy='secondary'
                      type='button'
                      disabled={row.original.bpEnabled || running}
                      onClick={async () => {
                        await handleSetup(row.original);
                      }}
                    >
                      Setup
                    </Button>
                  </ButtonGroup>
                );
              }}
            />
          </GridColumn>
          <GridColumn width={3}>
            <Card>
              <pre>{returnLog}</pre>
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>
    </BpSubpage>
  );
};
