import { useBpCollaboratingOrganizationsQuery } from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useFormikContext } from 'formik';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { Grid, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { MultiValue } from 'react-select';
import { CollaborationStatus } from '@bp/bp-graphql-types';
import { useTranslation } from 'react-i18next';
import { CollaborationAdminsValues } from './GroupForm';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';

export const GroupFormAdmins = <
  T extends {
    admins: string[];
    collaborationSchools: string[];
    collaborationAdmins: CollaborationAdminsValues;
  },
>() => {
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const collaborationContext = useMemoizedCacheTag('COLLABORATING');
  const { t } = useTranslation();

  const [{ data }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
    context: collaborationContext,
  });

  const collaboratingData = data?.collaboratingOrganizations.filter(
    (co) =>
      co.collaborationStatus === CollaborationStatus.Collaboration && co.uuid !== pimAuthClaims.getOrganizationUuid(),
  );

  const { setFieldValue, values, setFieldTouched } = useFormikContext<T>();

  const collaboratingSelectOpts = useCreateSelectOptions(collaboratingData, 'uuid', 'name');

  return (
    <Grid>
      {permissions?.canCreateCollaborationGroups() && (
        <GridRow spacingTop='s' spacingBottom='s'>
          <Select
            isClearable
            label={t('collaborations.collaboratingSchool', { count: collaboratingSelectOpts.length })}
            options={collaboratingSelectOpts}
            onChange={async (event) => {
              const collaborateSchoolsOptions = event as MultiValue<SelectOptionType>;
              const collaborateSchools = collaborateSchoolsOptions.map((value) => value.value as string);

              const removedCollaborateSchools = values.collaborationSchools.filter((cs) => {
                return !collaborateSchools.includes(cs);
              });

              const addedCollaborateSchools = collaborateSchools.filter((cs) => {
                return !values.collaborationSchools.includes(cs);
              });

              removedCollaborateSchools.forEach((uuid) => {
                const collaborationAdminsIndex = collaboratingData?.find((school) => {
                  return school.uuid === uuid;
                })?.uuid;

                if (collaborationAdminsIndex && collaborationAdminsIndex) {
                  setFieldValue(`collaborationAdmins.${collaborationAdminsIndex}`, null);
                }
              });

              addedCollaborateSchools.forEach((uuid) => {
                const collaborationAdminsIndex = collaboratingData?.findIndex((school) => {
                  return school.uuid === uuid;
                });
                if (collaboratingData && collaborationAdminsIndex && Number.isInteger(collaborationAdminsIndex)) {
                  setFieldTouched(`collaborationAdmins.${uuid}`, true);
                  setFieldValue(
                    `collaborationAdmins.${uuid}`,
                    collaboratingData[collaborationAdminsIndex].groupAdmins?.map((s) => {
                      return { uuid: s.uuid, displayName: s.displayName ?? '' };
                    }) ?? [],
                  );
                }
              });

              await setFieldTouched('collaborationSchools', true);
              await setFieldValue('collaborationSchools', collaborateSchools);
            }}
            name={'collaborateSchools'}
            value={collaboratingSelectOpts.filter((opt) => {
              return values.collaborationSchools.some((cs) => {
                return cs === opt.value;
              });
            })}
            isMulti
            isSearchable
            menuPosition='fixed'
          />
        </GridRow>
      )}
      {values.collaborationSchools.map((uuid) => {
        const collaborationGroupAdmins = collaboratingData?.find((school) => {
          return school.uuid === uuid;
        })?.groupAdmins;

        const options: SelectOptionType[] =
          collaborationGroupAdmins?.map((a) => ({ value: a.uuid, label: a.displayName ?? '' })) ?? [];

        return (
          <GridRow key={uuid} spacingTop='s' spacingBottom='s'>
            <Select
              label={t('collaborations.schoolAdmins', {
                schoolName: collaboratingData?.find((ca) => ca.uuid === uuid)?.name ?? '',
              })}
              value={
                values.collaborationAdmins[uuid] && Array.isArray(values.collaborationAdmins[uuid])
                  ? values.collaborationAdmins[uuid].map((a) => {
                      return { value: a.uuid, label: a.displayName };
                    })
                  : []
              }
              options={options}
              onChange={(event) => {
                const adminOptions = event as MultiValue<SelectOptionType>;
                const selected =
                  collaborationGroupAdmins?.filter((collaborationGroupAdmin) => {
                    return adminOptions.some((option) => {
                      return option.value === collaborationGroupAdmin.uuid;
                    });
                  }) ?? [];
                setFieldTouched(`collaborationAdmins.${uuid}`, true);
                setFieldValue(
                  `collaborationAdmins.${uuid}`,
                  selected.map((s) => {
                    return { uuid: s.uuid, displayName: s.displayName ?? '' };
                  }),
                );
              }}
              name={`collaborationAdmins.${uuid}.admins`}
              isMulti
              menuPosition='fixed'
            />
          </GridRow>
        );
      })}
    </Grid>
  );
};
