import { LazyLoader } from '@bp/ui-components';
import { BpPage } from 'components/BpPage/BpPage';
import { NavigationTabs, NavigationTabsType } from 'components/NavigationTabs/NavigationTabs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { usePermissionChecker } from 'hooks/usePermissionChecker';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export function MeetingsPage() {
  const { t } = useTranslation();
  const permissions = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  const tabs: NavigationTabsType[] = [
    {
      title: t('meetings.runningBBB'),
      path: `/meetings`,
      pathMatchEnd: true,
    },
    {
      title: t('meetings.runningZoom'),
      path: `/meetings/zoom`,
      disabled: true,
    },
  ];

  return (
    <BpPage title={t('meetings.title')} isForbidden={!permissions?.canViewMeetings(organization)}>
      <NavigationTabs className='mb-6' tabs={tabs} />
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='45vh' />}>{<Outlet />}</Suspense>
    </BpPage>
  );
}
