import { useContext, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { GroupSettings } from '../../pages/Institution/subpages/InstitutionGroupsSubpage';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useGroupSettingsQuery, useUpdateGroupSettingsMutation } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';

interface Props {
  groupUuid: string | null;
  onClose: () => void;
}

type GroupSettingsFormType = {
  cloudDirectory: boolean;
  asThreemaGroup: boolean;
  allowViewersCanAddMaterial: boolean;
  allowViewersCanCreateEvents: boolean;
  allowViewersCanStartMeeting: boolean;
};

export const GroupSettingsForm = ({ groupUuid, onClose }: Props) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  if (groupUuid === undefined) {
    onClose();
  }

  const { threemaBroadcastLicense, cloudLicense } = useContext(OrganizationConfigContext);

  const context = useMemoizedCacheTag('GROUP');
  const [, updateGroupSettings] = useUpdateGroupSettingsMutation();
  const [{ data }] = useGroupSettingsQuery({
    variables: { where: { uuid: groupUuid } },
    context,
    requestPolicy: 'network-only',
  });

  const { currentCloud, currentThreema } = useMemo(() => {
    return {
      currentCloud: { enabled: data?.groups[0]?.nextcloudConnection.edges[0]?.properties.enabled ?? false },
      currentThreema: { enabled: data?.groups[0]?.threemaBroadcastConnection.edges[0]?.properties.enabled ?? false },
    };
  }, [data]);

  const [checked, setChecked] = useState({
    cloudDirectory: currentCloud.enabled,
    asThreemaGroup: currentThreema.enabled,
    allowViewersCanAddMaterial: false, // TODO
    allowViewersCanCreateEvents: false, // TODO
    allowViewersCanStartMeeting: false, // TODO
  });

  const handleSubmit = async (values: GroupSettingsFormType) => {
    if (
      groupUuid &&
      perms?.canUpdateGroupSettings({
        uuid: groupUuid,
        organization: { uuid: organization.uuid },
      })
    ) {
      const { error } = await updateGroupSettings(
        {
          where: { uuid: groupUuid },
          update: {
            nextcloudFolder: values.cloudDirectory,
            threemaGroup: values.asThreemaGroup,
            allowViewersCanAddMaterial: values.allowViewersCanAddMaterial,
            allowViewersCanCreateEvents: values.allowViewersCanCreateEvents,
            allowViewersCanStartMeeting: values.allowViewersCanStartMeeting,
          },
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('common.saved'));
    } else {
      showErrorToast({ message: 'Not Allowed', graphQLErrors: [], name: '' });
    }
    onClose();
  };

  const initialValue: GroupSettingsFormType = {
    cloudDirectory: checked.cloudDirectory,
    asThreemaGroup: checked.asThreemaGroup,
    allowViewersCanAddMaterial: checked.allowViewersCanAddMaterial,
    allowViewersCanCreateEvents: checked.allowViewersCanCreateEvents,
    allowViewersCanStartMeeting: checked.allowViewersCanStartMeeting,
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValue}>
      {({ values, resetForm, isSubmitting, errors, setFieldValue }) => {
        const handleClick = (name: keyof GroupSettings) => {
          const prev = checked;
          setChecked(() => {
            return {
              ...prev,
              [name]: !prev[name],
            };
          });
          setFieldValue(name, !prev[name]);
        };

        const onClose = () => {
          resetForm();
          onClose();
        };

        return (
          <Form>
            <Grid>
              <GridRow spacingBottom='s'>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.asCloudDirectory')}
                    checked={values.cloudDirectory}
                    name='cloudDirectory'
                    onChange={() => handleClick('cloudDirectory')}
                    disabled={!cloudLicense}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.asGroupInThreema')}
                    checked={values.asThreemaGroup}
                    name='asThreemaGroup'
                    onChange={() => handleClick('asThreemaGroup')}
                    disabled={!threemaBroadcastLicense}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s'>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.participantsCanAddMaterial')}
                    tooltipText=''
                    error=''
                    checked={values.allowViewersCanAddMaterial}
                    name='participantsCanAddMaterial'
                    onChange={() => handleClick('allowViewersCanAddMaterial')}
                    disabled={true} // TODO
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.participantsCanStartVirtualMeeting')}
                    tooltipText=''
                    error=''
                    checked={values.allowViewersCanStartMeeting}
                    name='studentsCanStartMeeting'
                    onChange={() => handleClick('allowViewersCanStartMeeting')}
                    disabled={true} // TODO
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
