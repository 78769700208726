import React, { useEffect, useState } from 'react';

const getContainerDimensions = (ref: React.RefObject<HTMLElement>) => {
  return {
    width: ref.current?.offsetWidth ?? 0,
    height: ref.current?.offsetHeight ?? 0,
  };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export function useContainerDimensions(ref: React.RefObject<HTMLElement>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isTiny, setIsTiny] = useState<boolean>(false);
  const [isSmall, setIsSmall] = useState<boolean>(false);
  const [isMedium, setIsMedium] = useState<boolean>(false);
  const [isLarge, setIsLarge] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      const { width, height } = getContainerDimensions(ref);
      setDimensions({ width, height });
      setIsTiny(width <= 400);
      setIsSmall(width > 400 && width <= 550);
      setIsMedium(width > 550 && width <= 750);
      setIsLarge(width > 750);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return { width: dimensions.width, height: dimensions.height, isTiny, isSmall, isMedium, isLarge };
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  const [isPhone, setIsPhone] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isLaptop, setIsLaptop] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      const { width, height } = getWindowDimensions();
      setDimensions({ width, height });
      setIsPhone(width <= 580);
      setIsTablet(width > 580 && width <= 1024);
      setIsLaptop(width > 1024 && width <= 1290);
      setIsDesktop(width > 1290 && width <= 1440);
      setIsFullscreen(width > 1440);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    width: dimensions.width,
    height: dimensions.height,
    isPhone,
    isTablet,
    isLaptop,
    isDesktop,
    isFullscreen,
  };
}
