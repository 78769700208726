import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBpTeachingUnitsQuery, useBpUpdateTeachingUnitMutation } from '../../client/bp-graphql-client-defs';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { FileUpload } from '../FileUpload/FileUpload';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { type FileEntryTableType, FileTable } from '../FileTable/FileTable';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';

type TeachingUnitNoteFormProps = {
  onClose: () => void;
  teachingUnitUuid?: string;
};

type TeachingUnitNoteFormType = {
  text: string;
  files: FileEntryTableType[];
};

export const TeachingUnitNoteForm = ({ onClose, teachingUnitUuid }: TeachingUnitNoteFormProps) => {
  const { t } = useTranslation();
  const queryContext = useMemoizedCacheTag('TEACHING_UNIT');

  const [loading, setLoading] = useState(false);
  const [{ data: teachingUnitsData }] = useBpTeachingUnitsQuery({
    variables: { teachingUnitsWhere: { uuid: teachingUnitUuid ?? '' } },
    context: queryContext,
  });
  const [, updateTeachingUnit] = useBpUpdateTeachingUnitMutation();

  const teachingUnit = teachingUnitsData?.teachingUnits[0];

  const initialValues: TeachingUnitNoteFormType = {
    text: teachingUnit?.note?.text ?? '',
    files: teachingUnit?.note?.fileEntries ?? [],
  };

  const handleSubmit = async (values: TeachingUnitNoteFormType) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (teachingUnit?.note?.uuid) {
      const { error } = await updateTeachingUnit(
        {
          update: {
            note: {
              update: {
                node: {
                  text: values.text,
                  fileEntries: [
                    {
                      disconnect: [],
                      connect: values.files?.map((file, index) => {
                        return {
                          where: { node: { uuid: file.uuid } },
                          edge: { order: index },
                        };
                      }),
                    },
                  ],
                },
              },
            },
          },
          where: { uuid: teachingUnitUuid },
        },
        queryContext,
      );
      error && showErrorToast(error);
      !error && showSuccessToast(t('common.saved'));
    } else {
      const { error } = await updateTeachingUnit(
        {
          update: {
            note: {
              create: {
                node: {
                  text: values.text,
                  fileEntries: {
                    connect: values.files?.map((file, index) => {
                      return {
                        where: { node: { uuid: file.uuid } },
                        edge: { order: index },
                      };
                    }),
                  },
                },
              },
            },
          },
          where: { uuid: teachingUnitUuid },
        },
        queryContext,
      );
      error && showErrorToast(error);
      !error && showSuccessToast(t('common.saved'));
    }
    onClose();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, handleChange, isSubmitting, errors, setFieldValue }) => (
          <Form className={'bp__form'}>
            <BpTipTap
              defaultValue={values.text}
              onChange={(value) => {
                setFieldValue('text', value);
              }}
              name={'text'}
              required={false}
              label={t('common.description')}
              placeholder={t('notes.addNotes')}
            />
            <FileTable
              className='mt-5 mb-6'
              mode={'edit'}
              files={values.files}
              onRenamed={async (uuid, newName) => {
                const updated = values.files.map((fileEntry) => {
                  if (fileEntry.uuid === uuid) {
                    return { ...fileEntry, filename: newName };
                  }
                  return fileEntry;
                });
                await setFieldValue('fileEntries', updated);
              }}
              onDeleted={async (uuid) => {
                await setFieldValue('fileEntries', [
                  ...values.files.filter((fileEntry) => {
                    return fileEntry.uuid !== uuid;
                  }),
                ]);
              }}
              isGroupEditor={true}
            />
            <FileUpload
              onFileUpload={(file) => {
                setFieldValue('files', [...values.files, file]);
              }}
            />
            <ModalBottomButtons closeButton={{ callback: onClose }} errors={errors} isLoading={loading} />
          </Form>
        )}
      </Formik>
    </>
  );
};
