import { FC } from 'react';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { CourseAssignmentsStudentContent } from './StudentContent/CourseAssignmentsStudentContent';
import { CourseAssignmentsTeacherContent } from './TeacherContent/CourseAssignmentsTeacherContent';
import { useParams } from 'react-router-dom';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export const CourseAssignmentsSubpage: FC = () => {
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const { courseUuid } = useParams<{ courseUuid: string }>();

  return (
    <BpSubpage>
      {perms?.canCreateAssignment({
        uuid: courseUuid ?? '',
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      }) ? (
        <CourseAssignmentsTeacherContent />
      ) : (
        <CourseAssignmentsStudentContent />
      )}
    </BpSubpage>
  );
};
