import { FC } from 'react';
import { TreeItemType } from './types';
import styles from './TeachingUnitsTree.module.scss';
import { FileIcon, TemplatesIcon } from '@bp/ui-components';

type TeachingUnitsTreeDropPreviewProps = { text: string; type?: TreeItemType };

export const TeachingUnitsTreeDropPreview: FC<TeachingUnitsTreeDropPreviewProps> = ({ text, type }) => {
  return (
    <div className={styles['drop-preview']}>
      <div className={styles.icon}>
        {type === TreeItemType.teachingUnit ? <FileIcon /> : <TemplatesIcon className='svg-icon mr-1' />}
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
