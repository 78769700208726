import { useTranslation } from 'react-i18next';
import styles from './ClassbookAttendeeListItem.module.scss';
import { Checkbox, Chip, Input, LazyLoader, Select, SelectOptionType, Switch } from '@bp/ui-components';
import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { SingleValue } from 'react-select';
import dayjs, { Dayjs } from 'dayjs';
import { BpTipTap } from '../../BpTipTap/BpTipTap';
import { useWindowDimensions } from 'utils/dimensions';

export type AbsenceType = {
  uuid: string | null;
  attendeeUuid: string;
  completeAbsence: boolean;
  absentFrom: Date;
  absentUntil: Date;
  absenceReason: string | null;
  excused: boolean;
  status: 'present' | 'absent' | 'excused';
};

export type Attendee = {
  uuid: string;
  name: string;
  comment?: string | null;
  absence?: AbsenceType | null;
};

type ClassbookAttendeeListItemProps = {
  attendee: Attendee;
  reasons: SelectOptionType[];
  start: Dayjs;
  end: Dayjs;
  onAbsenceUpdate: (updatedAbsence: Partial<AbsenceType> & { comment?: string }) => Promise<void>;
  className?: string | undefined;
};

export const ClassbookAttendeeListItem = ({
  attendee,
  start,
  end,
  reasons,
  onAbsenceUpdate,
  className,
}: ClassbookAttendeeListItemProps) => {
  const { t } = useTranslation();

  const { isPhone } = useWindowDimensions();

  const [values, setValues] = useState<Partial<AbsenceType> & { comment?: string }>({});
  const [updating, setUpdating] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [showComment, setShowComment] = useState<boolean>(false);

  function getStatus(): ReactNode {
    let text = '';
    let bgColor = '';
    switch (values.status) {
      case 'present':
        text = t('classbook.present');
        bgColor = 'var(--color-success)';
        break;
      case 'excused':
        text = t('classbook.excused');
        bgColor = 'var(--color-warning)';
        break;
      case 'absent':
        text = t('classbook.absent');
        bgColor = 'var(--color-error)';
        break;
    }
    if (values.comment) {
      text += `/${t('classbook.comment')}`;
    }
    return <Chip value={text} bgColor={bgColor} maxWidth={'200px'} color='var(--color-white)' />;
  }

  useEffect(() => {
    setValues({
      attendeeUuid: attendee.uuid,
      uuid: attendee.absence?.uuid,
      absentFrom: dayjs(attendee.absence?.absentFrom ?? start).toDate(),
      absentUntil: dayjs(attendee.absence?.absentUntil ?? end).toDate(),
      absenceReason: (reasons.find((r) => r.value === attendee.absence?.absenceReason)?.value as string) ?? null,
      completeAbsence: attendee.absence?.completeAbsence,
      comment: attendee.comment ?? '',
      status: attendee.absence?.status ?? 'present',
      excused: attendee.absence?.excused,
    });
    setShowComment(!!attendee.comment);
  }, [attendee, end, reasons, start]);

  const optionsClasses = classNames(styles.options, {
    [styles['is-expanded']]: isExpanded,
    [styles['is-absent']]: values.status !== 'present',
    [styles['has-comment']]: showComment,
  });

  async function handleUpdate(updatedValues: (Partial<AbsenceType> & { comment?: string }) | null = null) {
    setUpdating(true);
    await onAbsenceUpdate({
      attendeeUuid: attendee.uuid,
      uuid: updatedValues?.uuid,
      absentFrom: updatedValues?.absentFrom,
      absentUntil: updatedValues?.absentUntil,
      absenceReason: updatedValues?.absenceReason,
      completeAbsence: updatedValues?.completeAbsence,
      comment: updatedValues?.comment,
      status: updatedValues?.status,
      excused: updatedValues?.excused,
    });
    setUpdating(false);
  }

  return (
    <div className={classNames(styles['classbook-attendee-list-item'], className)}>
      <div className={styles['item-header']} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={styles.name}>{attendee.name}</div>
        <div className={styles.status}>{getStatus()}</div>
      </div>

      <div className={optionsClasses}>
        {updating && <LazyLoader embedded className={styles.loader} />}

        {/* ABSENCE STATUS */}
        <div className={styles.option}>
          <div className={styles['option-header']}>
            <div
              className={styles.text}
              onClick={() =>
                values.status === 'absent' || values.status === 'excused'
                  ? setValues({ ...values, status: 'present' })
                  : setValues({ ...values, status: 'absent' })
              }
            >
              {t('classbook.isAbsent')}
            </div>
            {values.status !== 'present' && !isPhone && (
              <Checkbox
                className={classNames(styles.excused, styles['hide-on-phone'])}
                name={`excused-${attendee.uuid}`}
                checked={attendee.absence?.excused ?? false}
                label={t('classbook.excused')}
                onChange={(e) => {
                  setValues({ ...values, status: e.target.checked ? 'excused' : 'absent' });
                  handleUpdate({ ...attendee.absence, excused: e.target.checked });
                }}
              />
            )}
            <Switch
              name={`isAbsent-${attendee.uuid}`}
              checked={values.status !== 'present'}
              onChange={(value) => {
                if (value.target.checked) {
                  setValues({ ...values, status: 'absent' });
                  handleUpdate({ uuid: attendee.absence?.uuid, status: 'absent' });
                } else {
                  setValues({ ...values, status: 'present' });
                  handleUpdate({ uuid: attendee.absence?.uuid, status: 'present' });
                }
              }}
            />
          </div>
          <div
            className={classNames(styles['option-area'], styles['absence-status-options'], {
              [styles['is-open']]: values.status !== 'present',
            })}
          >
            {isPhone && (
              <Checkbox
                className={classNames(styles.excused, styles['show-on-phone'])}
                name={`excused-${attendee.uuid}`}
                checked={attendee.absence?.excused ?? false}
                label={t('classbook.excused')}
                onChange={(e) => {
                  setValues({ ...values, status: e.target.checked ? 'excused' : 'absent' });
                  handleUpdate({ ...attendee.absence, excused: e.target.checked });
                }}
              />
            )}
            <Input
              className={styles['time-input']}
              name={`absentFrom-${attendee.uuid}`}
              label={t('classbook.absentFrom')}
              value={dayjs(values.absentFrom).format('HH:mm')}
              onChange={(e) => {
                const [hour, minute] = e.target.value.split(':');
                const date = dayjs().set('hours', parseInt(hour)).set('minutes', parseInt(minute)).toDate();
                setValues({ ...values, absentFrom: date });
              }}
              onBlur={(e) => {
                const [hour, minute] = e.target.value.split(':');
                handleUpdate({
                  ...attendee.absence,
                  absentFrom: dayjs(attendee.absence?.absentFrom ?? start)
                    .set('hour', Number(hour))
                    .set('minute', Number(minute))
                    .toDate(),
                });
              }}
              type='time'
            />
            <Input
              className={styles['time-input']}
              name={`absentTo-${attendee.uuid}`}
              label={t('classbook.absentUntil')}
              value={dayjs(values.absentUntil).format('HH:mm')}
              onChange={(e) => {
                const [hour, minute] = e.target.value.split(':');
                const date = dayjs().set('hours', parseInt(hour)).set('minutes', parseInt(minute)).toDate();
                setValues({ ...values, absentUntil: date });
              }}
              onBlur={(e) => {
                const [hour, minute] = e.target.value.split(':');
                handleUpdate({
                  ...attendee.absence,
                  absentUntil: dayjs(attendee.absence?.absentUntil ?? start)
                    .set('hour', Number(hour))
                    .set('minute', Number(minute))
                    .toDate(),
                });
              }}
              type='time'
            />
            <Select
              isSearchable
              options={reasons}
              onChange={(value) => {
                const option = value as SingleValue<SelectOptionType>;
                handleUpdate({ ...attendee.absence, absenceReason: option?.value as string | undefined });
              }}
              label={t('classbook.reason')}
              isClearable
              value={reasons.find((r) => r.value === values.absenceReason)}
              name={`reason-${attendee.uuid}`}
              menuPosition='fixed'
            />
          </div>
        </div>

        {/* COMMENT */}
        <div className={styles.option}>
          <div className={styles['option-header']}>
            <div
              className={styles.text}
              onClick={() => {
                const value = showComment;
                setShowComment(!value);
                if (!value) {
                  setValues({ ...values, comment: '' });
                  handleUpdate({ ...attendee.absence, comment: '' });
                }
              }}
            >
              {t('classbook.comment')}
            </div>
            <Switch
              name={`commnet-${attendee.uuid}`}
              checked={showComment}
              onChange={(e) => {
                const value = e.target.checked;
                setShowComment(value);
                if (!value) {
                  setValues({ ...values, comment: '' });
                  handleUpdate({ ...attendee.absence, comment: '' });
                }
              }}
            />
          </div>
          <div
            className={classNames(styles['option-area'], styles['comment-options'], {
              [styles['is-open']]: showComment,
            })}
          >
            <div className={styles['comment-wrapper']}>
              <BpTipTap
                key={attendee.uuid}
                defaultValue={attendee.comment ?? ''}
                onChange={(value) => setValues({ ...values, comment: value })}
                onBlur={() => handleUpdate({ ...attendee.absence, comment: values.comment })}
                name={`comment-${attendee.uuid}`}
                isDense
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
