import { RadioGroup, ModalBottomButtons } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

type ChangeChildrenFormProps = {
  options: { label: string; value: string }[];
  hint: string;
  onSetLoginHint: (value: string) => void;
  onChangeProfile: () => void;
  onClose: () => void;
};

export const ChangeChildrenForm = ({
  options,
  hint,
  onSetLoginHint,
  onChangeProfile,
  onClose,
}: ChangeChildrenFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <RadioGroup
        name={'children'}
        onChange={(value) => onSetLoginHint(value ?? '')}
        options={options}
        value={hint ?? ''}
      />
      <ModalBottomButtons
        closeButton={{
          callback: () => {
            onClose();
          },
        }}
        submitButton={{ text: t('common.switch'), callback: onChangeProfile, disabled: hint === '' }}
        isLoading={false}
        errors={''}
      />
    </>
  );
};
