import { Suspense, useMemo, useState } from 'react';
import { LazyLoader, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { GroupType, useGroupAsGroupsQuery } from '../../../client/bp-graphql-client-defs';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useNavigate } from 'react-router-dom';
import { GroupsTable, GroupsTableType } from '../../../components/GroupsTable/GroupsTable';
import dayjs from 'dayjs';
import { GroupFormOther } from '../../../components/GroupForm/GroupFormOther';
import { updateClaims } from '../../../utils/authStore';
import { usePermissionChecker } from 'hooks/usePermissionChecker';

export type GroupSettings = {
  cloudDirectory: boolean;
  asThreemaGroup: boolean;
  allowViewersCanAddMaterial: boolean;
  allowViewersCanCreateEvents: boolean;
  allowViewersCanStartMeeting: boolean;
};

export const InstitutionCollaborationGroupsSubpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useMemoizedCacheTag('GROUP');
  const [claimsTrigger, setClaimsTrigger] = useState<boolean>(false);
  const perms = usePermissionChecker();

  const { pimAuthClaims } = useAuthClaims();

  const [formProfilesOpen, setFormProfilesOpen] = useState<boolean>(false);
  const [currentGroupUuid, setCurrentGroupUuid] = useState<string>('');

  const [{ data }] = useGroupAsGroupsQuery({
    context,
    variables: {
      where: {
        type: GroupType.Group,
        organization: {
          uuid_IN: pimAuthClaims.getOrganization().collaboratesWith.map((o) => o.uuid),
        },
      },
    },
  });

  const handleProfilesEdit = (uuid: string) => {
    setFormProfilesOpen(true);
    setCurrentGroupUuid(uuid);
  };

  const tableData: GroupsTableType[] = useMemo(() => {
    return (
      data?.groups
        .map((g) => ({
          uuid: g.uuid,
          organizationUuid: g.organization.uuid,
          organizationName: g.organization.displayName ?? '',
          newMessage: false,
          name: g.name,
          managedBy:
            g.managedBy !== 'bp'
              ? g.managedBy
              : (g.admins.map((admin) => admin.displayNameShort).join(', ') ?? g.managedBy),
          isCommunity: g.isCommunity,
          schoolyearName: g.schoolYear?.name ?? undefined,
          meetingInProgress: false, // TODO: get from current Events!
          admins: g.admins,
          editors: g.editors,
          viewers: g.viewers,
          externalServices: {
            threema: {
              enabled: g.externalServices?.threema?.enabled ?? false,
              lastSync: g.externalServices?.threema?.lastSync ? dayjs(g.externalServices?.threema.lastSync) : undefined,
            },
            zoom: {
              enabled: g.externalServices?.zoom?.enabled ?? false,
              lastSync: g.externalServices?.zoom?.lastSync ? dayjs(g.externalServices?.zoom.lastSync) : undefined,
            },
            nextcloud: {
              enabled: g.externalServices?.nextcloud?.enabled ?? false,
              lastSync: g.externalServices?.nextcloud?.lastSync
                ? dayjs(g.externalServices?.nextcloud.lastSync)
                : undefined,
            },
          },
        }))
        .filter((c) => c.isCommunity) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, claimsTrigger]);

  return (
    <BpSubpage isForbidden={!perms?.canUpdateCollaborationGroups()}>
      {pimAuthClaims.getOrganization() && (
        <GroupsTable
          data={tableData}
          onNavigate={(uuid) => navigate('/groups/' + uuid)}
          onEditProfiles={handleProfilesEdit}
          communityDetails={true}
        />
      )}

      {formProfilesOpen && (
        <Modal
          isOpen={formProfilesOpen}
          onRequestClose={() => {
            setFormProfilesOpen(false);
            setCurrentGroupUuid('');
          }}
          title={t('groups.editGroup')}
          hideFooter
        >
          <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
            <GroupFormOther
              groupUuid={currentGroupUuid}
              onClose={async () => {
                await updateClaims(() => setClaimsTrigger(!claimsTrigger));
                setFormProfilesOpen(false);
                setCurrentGroupUuid('');
              }}
            />
          </Suspense>
        </Modal>
      )}
    </BpSubpage>
  );
};
