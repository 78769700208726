import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { FC } from 'react';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useParams } from 'react-router-dom';
import { Button } from '@bp/ui-components';
import { getTokens } from '../../../utils/authStore';

export const CourseAdminSubpage: FC = () => {
  const perms = usePermissionChecker();

  const { courseUuid } = useParams<{ courseUuid: string }>();

  async function handleFixMatrixRoomClick() {
    const tokens = await getTokens();
    await fetch(
      `${import.meta.env.VITE_APP_BACKEND_URI}${import.meta.env.VITE_APP_BACKEND_REST_PATH}/matrix/room/course/${courseUuid}`,
      {
        method: 'POST',
        headers: { Authorization: `Bearer ${tokens?.access_token}` },
      },
    );
  }

  if (!perms?.isOmniAdmin() || !courseUuid) {
    return <></>;
  }

  return (
    <BpSubpage>
      <Button onClick={() => handleFixMatrixRoomClick()}>Fix Matrix-Room</Button>
    </BpSubpage>
  );
};
