import { Link, useMatches } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';
import { FC } from 'react';
import classNames from 'classnames';

type CrumbItemType = {
  slug: string;
  title: string;
};

type CrumbType = {
  crumb: (data: unknown) => CrumbItemType | CrumbItemType[];
};

type BreadcrumbProps = {
  className?: string | undefined;
};

export const Breadcrumb: FC<BreadcrumbProps> = ({ className }) => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Boolean(match.handle?.crumb); // How to overwrite crumb ? see src/types/react-router.d.ts
    })
    .map((match) => {
      const handle = match.handle as CrumbType;
      return handle.crumb(match.data);
    });

  return (
    <div className={classNames(styles.breadcrumb, className)}>
      {crumbs.length > 1 &&
        crumbs.map((crumb, index) => (
          <div key={index} className={styles.link}>
            {Array.isArray(crumb) ? (
              crumb.map((c) => {
                const slug = !c.slug.startsWith('/') ? `/${c.slug}` : c.slug;
                return (
                  <Link className={styles.link} key={c.slug} to={`/${slug}`}>
                    {c.title}
                  </Link>
                );
              })
            ) : (
              <Link
                className={styles.link}
                key={crumb.slug}
                to={!crumb.slug.startsWith('/') ? `/${crumb.slug}` : crumb.slug}
              >
                {crumb.title}
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};
