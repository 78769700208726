import React, { createContext, ReactNode, useEffect, useState } from 'react';

export type DesktopContextType = {
  isDesktop: boolean;
};

export const DesktopContext = createContext<DesktopContextType>({
  isDesktop: false,
});

export const IsDesktopContextProvider = ({ children }: { children: ReactNode }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    function handleResize() {
      setDesktop(window.innerWidth > 1024);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DesktopContext.Provider
      value={{
        isDesktop,
      }}
    >
      {children}
    </DesktopContext.Provider>
  );
};
