import { Table, useDefaultSorting } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { niceDate } from '../../utils/dateCalculations';
import { handleFileEntryDownload } from '../../utils/download';
import { BpLink } from '../BpLink/BpLink';
import { type Row } from '@tanstack/react-table';
import { type FC } from 'react';
import { type FileEntryTableType } from '../FileTable/FileTable';

type FilePreviewTableProps = {
  data: FileEntryTableType[];
  hasNote?: boolean;
  className?: string | undefined;
  isGroupEditor: boolean;
};

export const FilePreviewTable: FC<FilePreviewTableProps> = ({ data, hasNote, className, isGroupEditor }) => {
  const { t } = useTranslation();
  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'name', desc: false }]);

  return (
    <Table<FileEntryTableType>
      maxHeight={hasNote ? 105 : 150}
      canScroll
      className={className}
      hideHeader
      showSort
      sorting={sorting}
      onSortingChange={onSortingChange}
      customPadding='var(--spacing-6)'
      customRowHeight='var(--list-row-height)'
      customLastColSpacing='var(--spacing-3)'
      lastColWidth='100px'
      breakpoint={null}
      data={data}
      columns={[
        {
          id: 'name',
          accessorKey: 'name',
          size: 300,
          cell: ({ row }) => {
            return (
              <BpLink
                value={row.original.filename}
                onNavigate={() => {
                  void handleFileEntryDownload(row.original);
                }}
                isEditorOfCourse={isGroupEditor}
              />
            );
          },
        },
      ]}
      emptyStateSettings={{
        hideIcon: true,
        size: 'small',
        title: '',
        subtitle: t('files.noFiles'),
        forcedHeight: hasNote ? '60px' : '150px',
        padding: hasNote ? 'none' : 'xl',
        fitParent: true,
      }}
      lastCol={(row: Row<FileEntryTableType>) => {
        return <>{niceDate(row.original.updated, 'short')}</>;
      }}
    />
  );
};
