import { SubmissionStatus } from '../../client/bp-graphql-client-defs';
import { Chip } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

type StatusChipProps = {
  status: SubmissionStatus | 'delayed';
  className?: string | undefined;
  isEditorOfCourse: boolean;
};

export const StatusChip: FC<StatusChipProps> = ({ status, className, isEditorOfCourse }) => {
  const { t } = useTranslation();

  let value = '';
  let color = 'done';

  if (isEditorOfCourse) {
    switch (status) {
      case SubmissionStatus.Todo:
        value = t('submissions.teacher.todo');
        color = 'var(--color-unprocessed)';
        break;

      case SubmissionStatus.Done:
        value = t('submissions.teacher.done');
        color = 'var(--color-finished)';
        break;

      case SubmissionStatus.New:
        value = t('submissions.teacher.new');
        color = 'var(--color-finished)';
        break;

      case SubmissionStatus.Draft:
        color = 'var(--color-workinprogress)';
        value = t('submissions.teacher.draft');
        break;

      case SubmissionStatus.Feedback:
        color = 'var(--color-tocorrect)';
        value = t('submissions.teacher.feedback');
        break;

      case SubmissionStatus.Updated:
        color = 'var(--color-error)';
        value = t('submissions.teacher.updated');
        break;

      case 'delayed':
        color = 'var(--color-grey-lightest)';
        value = t('submissions.teacher.delayed');
        break;
    }
  } else {
    switch (status) {
      case SubmissionStatus.Todo:
        value = t('submissions.student.todo');
        color = 'var(--color-unprocessed)';
        break;

      case SubmissionStatus.Done:
        value = t('submissions.student.done');
        color = 'var(--color-finished)';
        break;

      case SubmissionStatus.New:
        value = t('submissions.student.new');
        color = 'var(--color-finished)';
        break;

      case SubmissionStatus.Draft:
        color = 'var(--color-workinprogress)';
        value = t('submissions.student.draft');
        break;

      case SubmissionStatus.Feedback:
        color = 'var(--color-tocorrect)';
        value = t('submissions.student.feedback');
        break;

      case SubmissionStatus.Updated:
        color = 'var(--color-error)';
        value = t('submissions.student.updated');
        break;

      case 'delayed':
        color = 'var(--color-grey-lightest)';
        value = t('submissions.student.delayed');
        break;
    }
  }

  return <Chip value={value} bgColor={color} className={className} />;
};
