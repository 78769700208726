import { getFileUrl } from './getFileUrl';
import { type FileEntryTableType } from '../components/FileTable/FileTable';

export const handleFileEntryDownload = async (file: FileEntryTableType) => {
  const token = file.downloadToken;
  const link = document.createElement('a');
  link.download = file.filename ?? '';
  link.href = getFileUrl(file.uuid, token ?? '');
  link.target = '_blank';
  link.click();
};
