import { backendApi } from '../../../utils/backendApi';
import { GroupRoles, ProfileClaim, RolesClaim } from '@bp/pim-auth-constants';
import { BpCreateCalendarEventMutation } from '../../../client/bp-graphql-client-defs';

type CreatedEvent = Pick<
  BpCreateCalendarEventMutation,
  'createCalendarEvents'
>['createCalendarEvents']['calendarEvents'][0];

type PimAuthClaims = {
  getProfile: () => ProfileClaim;
  getRoles: () => RolesClaim;
};

export const startAndJoinMeeting = async (
  event: CreatedEvent,
  pimAuthClaims: PimAuthClaims,
  contextUuid: string = '',
): Promise<boolean> => {
  const onlineLocation = event.virtualLocations[0];
  let success = !!onlineLocation;

  if (onlineLocation) {
    const joinUrl = await backendApi.joinConference(onlineLocation.token, {
      displayName: pimAuthClaims.getProfile().displayName ?? '',
      email: pimAuthClaims.getProfile().email ?? '',
      profileUuid: pimAuthClaims.getProfile().uuid ?? '',
      moderator:
        pimAuthClaims
          .getRoles()
          .find(
            (r) =>
              r.target.uuid === contextUuid &&
              (r.name.includes(GroupRoles.Admin) || r.name.includes(GroupRoles.Editor)),
          ) !== undefined,
    });

    const url = await joinUrl.text();
    if (url.includes('https://')) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    } else {
      success = false;
    }
  }

  return success;
};
