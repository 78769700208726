export const splitProfilesByOrganization = <
  T extends {
    uuid: string;
    displayName: string;
    organization: { uuid: string };
  },
>(
  profiles: T[],
  excludeUuid?: string,
): {
  [key: string]: typeof profiles;
} | null => {
  const result: { [key: string]: typeof profiles } = {};

  profiles.forEach((item) => {
    const organizationUuid = item.organization.uuid;
    if (organizationUuid !== excludeUuid) {
      if (!result[organizationUuid]) {
        result[organizationUuid] = [item];
      } else {
        result[organizationUuid].push(item);
      }
    }
  });

  return Object.keys(result).length ? result : null;
};
