import { Form, Formik } from 'formik';
import { FC } from 'react';
import { CombinedError } from 'urql';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { useTranslation } from 'react-i18next';
import {
  AddIcon,
  Button,
  Checkbox,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  InplaceEdit,
  MinusIcon,
} from '@bp/ui-components';
import { TeachingUnitSelect } from '../TeachingUnitSelect/TeachingUnitSelect';
import { BpCard } from '../BpCard/BpCard';
import { niceDate } from '../../utils/dateCalculations';
import { FileUpload } from '../FileUpload/FileUpload';
import styles from './WorkMaterialsForm.module.scss';
import { type FileEntryTableType, FileTable } from '../FileTable/FileTable';
import { schema } from './validation/schema';
import {
  useBpCreateWorkMaterialsMutation,
  useBpUpdateWorkMaterialsMutation,
  useBpWorkMaterialsQuery,
} from '../../client/bp-graphql-client-defs';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useDays } from '../../hooks/useDays';

type WorkMaterialValuesType = {
  title: string;
  description: string;
  relevantFrom: Date;
  relevantTo: Date;
  visibleFrom: Date;
  teachingUnitUuid: string;
  visibleAfterVisibleTo: boolean;
  fileEntries: FileEntryTableType[];
  showPublicationDate: boolean;
};

export type WorkMaterialFormProps = {
  workMaterialUuid?: string;
  currentTeachingUnitUuid: string;
  isModal?: boolean;
  isDesktop?: boolean;
  onClose: (uuid?: string) => void;
  context: { additionalTypenames: string[] };
};

export const CourseWorkMaterialsForm: FC<WorkMaterialFormProps> = ({
  workMaterialUuid,
  currentTeachingUnitUuid,
  isModal = false,
  isDesktop = false,
  onClose,
  context,
}) => {
  const { t } = useTranslation();
  const { ensureDay, today } = useDays();

  const pimAuthClaims = useAuthClaims().pimAuthClaims;
  const [, bpCreateWorkMaterial] = useBpCreateWorkMaterialsMutation();
  const [, bpUpdateWorkMaterial] = useBpUpdateWorkMaterialsMutation();

  const [workmaterial] = useBpWorkMaterialsQuery({
    context,
    variables: {
      where: {
        uuid: workMaterialUuid,
      },
    },
  });

  const workMaterialToEdit = workmaterial.data?.workMaterials.find((mat) => mat.uuid === workMaterialUuid);

  const handleSubmit = async (values: WorkMaterialValuesType) => {
    let err: CombinedError | undefined;
    const { description, title, visibleAfterVisibleTo, teachingUnitUuid } = values;

    const relevantFrom = await ensureDay(values.relevantFrom);
    const relevantTo = await ensureDay(values.relevantTo);
    const publicFromDate = await ensureDay(values.visibleFrom);

    const fileEntriesConnect = () =>
      values.fileEntries && values.fileEntries.length > 0
        ? {
            connect: values.fileEntries?.map((file, index) => {
              return {
                where: { node: { uuid: file.uuid } },
                edge: { order: index },
              };
            }),
          }
        : {};
    if (workMaterialToEdit) {
      const { error } = await bpUpdateWorkMaterial(
        {
          where: { uuid: workMaterialUuid },
          update: {
            material: {
              delete: {},
              create: {
                node: {
                  text: description,
                  textMediaType: 'text/plain',
                  fileEntries: fileEntriesConnect(),
                },
              },
            },
            publicFrom: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: publicFromDate?.uuid,
                  },
                },
              },
            },
            title: title,
            visibleAfterVisibleTo: visibleAfterVisibleTo,
            visibleFrom: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: relevantFrom?.uuid,
                  },
                },
              },
            },
            visibleTo: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: relevantTo?.uuid,
                  },
                },
              },
            },
            holder: {
              TeachingUnit: {
                disconnect: {},
                connect: {
                  where: {
                    node: {
                      uuid: teachingUnitUuid,
                    },
                  },
                },
              },
            },
          },
        },
        context,
      );

      err = error;
    } else {
      const { error } = await bpCreateWorkMaterial(
        {
          input: {
            title: title,
            visibleFrom: {
              connect: {
                where: {
                  node: {
                    uuid: relevantFrom?.uuid,
                  },
                },
              },
            },
            visibleTo: {
              connect: {
                where: {
                  node: {
                    uuid: relevantTo?.uuid,
                  },
                },
              },
            },
            visibleAfterVisibleTo: visibleAfterVisibleTo,
            publicFrom: {
              connect: {
                where: {
                  node: {
                    uuid: publicFromDate?.uuid,
                  },
                },
              },
            },
            owner: { connect: { where: { node: { uuid: pimAuthClaims.getProfile().uuid } } } },
            material: {
              create: {
                node: {
                  text: description,
                  textMediaType: 'text/plain',
                  fileEntries: fileEntriesConnect(),
                },
              },
            },
            holder: {
              TeachingUnit: {
                connect: {
                  where: {
                    node: {
                      uuid: teachingUnitUuid,
                    },
                  },
                },
              },
            },
          },
        },
        context,
      );

      err = error;
    }

    if (err) showErrorToast(err);
    if (!err) showSuccessToast(t('common.saved'));
    onClose(workMaterialUuid);
  };

  const todayDay = dayjs(today?.date);
  const initialValues: WorkMaterialValuesType = {
    title: workMaterialToEdit?.title ?? '',
    description: workMaterialToEdit?.material.text ?? '',
    relevantFrom: workMaterialToEdit?.visibleFrom ? new Date(workMaterialToEdit?.visibleFrom.date) : todayDay.toDate(),
    relevantTo: workMaterialToEdit?.visibleTo
      ? new Date(workMaterialToEdit?.visibleTo.date)
      : todayDay.add(1, 'day').toDate(),
    visibleFrom: workMaterialToEdit?.publicFrom?.date
      ? new Date(workMaterialToEdit?.publicFrom?.date)
      : todayDay.toDate(),
    visibleAfterVisibleTo: workMaterialToEdit?.visibleAfterVisibleTo ?? false,
    teachingUnitUuid: workMaterialToEdit?.holder.uuid ?? currentTeachingUnitUuid,
    showPublicationDate: !!workMaterialToEdit?.publicFrom?.date,
    fileEntries: workMaterialToEdit?.material.fileEntries ?? [],
  };

  return (
    <div className={styles['course-workmaterials-form']}>
      <Formik<WorkMaterialValuesType> onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ resetForm, setFieldValue, setFieldTouched, handleChange, values, isSubmitting, errors }) => {
          return (
            <Form className={classNames('bp__form', { 'is-modal': isModal })}>
              <div className={isModal ? 'bp__modal-header' : 'bp__form-header'}>
                <InplaceEdit
                  className={styles.title}
                  name='title'
                  fontSize={'xxl'}
                  onBlur={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  onChange={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  value={values.title}
                  placeholder={t('common.addTitle') as string}
                  error={errors.title}
                />
                <div className={'bp__form-buttons'}>
                  <Button hierarchy='tertiary' type={'submit'} disabled>
                    {t('templates.useAsTemplate')}
                  </Button>
                  <Button
                    hierarchy='secondary'
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    {workMaterialUuid ? t('common.discardChanges') : t('common.cancel')}
                  </Button>
                  <Button hierarchy='primary' type={'submit'} disabled={!!errors.title} isLoading={isSubmitting}>
                    {t('common.save')}
                  </Button>
                </div>
              </div>

              <Grid className={!isDesktop ? 'mobile-margin' : undefined}>
                <GridRow mobileGap='var(--grid-column-gap)'>
                  <GridColumn width={8}>
                    <BpCard isEmbedded={isModal} header={{ headline: t('common.description') }}>
                      <BpTipTap
                        defaultValue={values.description}
                        onChange={async (value) => {
                          await setFieldValue('description', value);
                        }}
                        name={'description'}
                        required={false}
                        label={t('common.description')}
                        placeholder={t('common.description')}
                        error={errors.description}
                      />
                      <FileTable
                        className={styles['file-list']}
                        mode={'edit'}
                        files={values.fileEntries ?? []}
                        onRenamed={async (uuid, newName) => {
                          const updated = values.fileEntries.map((fileEntry) => {
                            if (fileEntry.uuid === uuid) {
                              return { ...fileEntry, filename: newName };
                            }
                            return fileEntry;
                          });
                          await setFieldValue('fileEntries', updated);
                        }}
                        onDeleted={async (uuid) => {
                          await setFieldValue(
                            'fileEntries',
                            values.fileEntries.filter((fileEntry) => {
                              return fileEntry.uuid !== uuid;
                            }),
                          );
                        }}
                        isGroupEditor={true}
                      />
                      <FileUpload
                        onFileUpload={async (file) => {
                          await setFieldValue('fileEntries', [...values.fileEntries, file]);
                        }}
                      />
                    </BpCard>
                  </GridColumn>
                  <GridColumn width={4}>
                    <TeachingUnitSelect
                      className={!isModal ? 'mb-7' : undefined}
                      teachingUnitUuid={values.teachingUnitUuid}
                      onChange={async (teachingUnitUuid) => {
                        await setFieldValue('teachingUnitUuid', teachingUnitUuid);
                      }}
                      error={errors.teachingUnitUuid}
                      isModal={isModal}
                    />
                    <BpCard isEmbedded={isModal} header={{ headline: t('common.data') }}>
                      <div className={styles['dates']}>
                        <div>
                          <DatePicker
                            className='mb-2'
                            name={'relevantFrom'}
                            dateFormat='dd.MM.yyyy'
                            label={t('workmaterials.relevantFrom') as string}
                            onChange={async (date) => {
                              await setFieldValue('relevantFrom', date);
                              await setFieldTouched('relevantFrom');
                              if (dayjs(values.relevantTo).isBefore(date)) {
                                await setFieldValue('relevantTo', date);
                                await setFieldTouched('relevantTo');
                              }
                              if (dayjs(values.visibleFrom).isAfter(date)) {
                                await setFieldValue('visibleFrom', date);
                                await setFieldTouched('visibleFrom');
                              }
                            }}
                            value={values.relevantFrom}
                            showMonthYearDropdown
                          />
                          <DatePicker
                            className='mb-5'
                            name={'relevantTo'}
                            dateFormat='dd.MM.yyyy'
                            label={t('workmaterials.visibleTo') as string}
                            onChange={async (date) => {
                              if (dayjs(date).isBefore(values.relevantFrom)) {
                                await setFieldValue('relevantFrom', date);
                                await setFieldTouched('relevantFrom');
                              }
                              await setFieldValue('relevantTo', date);
                              await setFieldTouched('relevantTo');
                            }}
                            value={values.relevantTo}
                            showMonthYearDropdown
                          />
                        </div>

                        <div className={styles['public-from-hint']}>{t('workmaterials.publicFromHint')}</div>

                        <div className={styles['public-from-date']}>
                          {t('workmaterials.publicFromDate', { date: niceDate(values.relevantFrom, 'short') })}
                        </div>

                        <Button
                          className={styles['full-width-button']}
                          hierarchy='secondary'
                          onClick={async () => {
                            await setFieldValue('showPublicationDate', !values.showPublicationDate);
                          }}
                          icon={
                            values.showPublicationDate ? (
                              <MinusIcon className={'svg-icon small'} />
                            ) : (
                              <AddIcon className={'svg-icon small'} />
                            )
                          }
                        >
                          {t('workmaterials.divergentDate')}
                        </Button>

                        {values.showPublicationDate && (
                          <DatePicker
                            name={'visibleFrom'}
                            dateFormat='dd.MM.yyyy'
                            label={t('common.visibleFrom')}
                            onChange={async (date) => {
                              if (dayjs(date).isAfter(values.relevantFrom)) {
                                await setFieldValue('visibleFrom', values.relevantFrom);
                                await setFieldTouched('visibleFrom', true);
                              } else {
                                await setFieldValue('visibleFrom', date);
                                await setFieldTouched('visibleFrom', true);
                              }
                            }}
                            value={values.visibleFrom}
                            showMonthYearDropdown
                          />
                        )}

                        <Checkbox
                          className='mt-5'
                          label={t('common.visibleAfter') as string}
                          name='visibleAfterVisibleTo'
                          checked={values.visibleAfterVisibleTo ?? false}
                          onChange={handleChange}
                        />
                      </div>
                    </BpCard>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
