import { FC } from 'react';
import styles from './PageNotFoundPage.module.scss';
import { BpCard } from 'components/BpCard/BpCard';
import { ErrorState, Logo } from '@bp/ui-components';
import { useNavigate } from 'react-router-dom';

export const PageNotFoundPage: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles['page-not-found-page']}>
      <BpCard className={styles.card}>
        <Logo type='bildungsplattform' collapsed={false} className={styles.logo} />
        <ErrorState type='notFound' useLightColor onNavigateBack={() => navigate(-1)} showBackButton />
      </BpCard>
    </div>
  );
};
