import { EmptyState } from '@bp/ui-components';

type LinkListProps = {
  className?: string | undefined;
};

export const LinkList = ({ className }: LinkListProps) => {
  return (
    <EmptyState
      className={className}
      subtitle='Hier erscheinen in Zukunft wichtige Links'
      hideIcon
      fitParent
      forcedHeight='140px'
    />
  );
};
