import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Select, SelectOptionType } from '@bp/ui-components';
import {
  useBpListParentsQuery,
  useBpUpdateParentsMutation,
  useProfilesQuery,
} from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { MultiValue } from 'react-select';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useMemo } from 'react';
import { ProfileRoles } from '@bp/pim-auth-constants';

type ParentProfilesFormProps = { childUuid: string; setModalClosed: (close: boolean) => void };

type ParentFormValues = {
  child: string;
  parents: string[];
};

export const ParentProfilesForm = ({ childUuid, setModalClosed }: ParentProfilesFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const parentContext = useMemoizedCacheTag('PARENTS');
  const profilesContext = useMemoizedCacheTag('PROFILE');

  const [, updateParents] = useBpUpdateParentsMutation();
  const { data } = useBpListParentsQuery({
    variables: { child: childUuid },
    requestPolicy: 'network-only',
    context: parentContext,
  })[0];

  const formikValues: ParentFormValues = useMemo(
    () => ({
      child: childUuid,
      parents: (data?.listParents ?? []).map((p) => p.uuid),
    }),
    [childUuid, data],
  );

  const handleSubmit = async (values: ParentFormValues) => {
    const resp = await updateParents(values, parentContext);
    resp.error ? showErrorToast(resp.error) : showSuccessToast(t('common.saved'));
    setModalClosed(false);
  };

  const [{ data: profilesPoolData }] = useProfilesQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: profilesContext,
  });

  const parentSelectOpts = useCreateSelectOptions(
    profilesPoolData?.profiles.filter((p) => !p.organizationRoles.includes(ProfileRoles.Student)),
    'uuid',
    'selectName',
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues}>
      {({ resetForm, isSubmitting, errors, setFieldValue, values }) => {
        const onClose = () => {
          resetForm();
          setModalClosed(false);
        };

        return (
          <Form>
            <Select
              label={t('parents.title')}
              value={[...parentSelectOpts.filter((v) => values.parents.includes(v.value as string))]}
              options={parentSelectOpts}
              onChange={(event) => {
                setFieldValue(
                  'parents',
                  (event as MultiValue<SelectOptionType>).map((e) => e.value),
                );
              }}
              name={'parents'}
              isMulti
              isSearchable
              menuPosition='fixed'
            />
            <ModalBottomButtons
              isLoading={isSubmitting}
              closeButton={{
                callback: () => {
                  resetForm();
                  onClose();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
