import {
  Button,
  CoursesIcon,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
  Tooltip,
} from '@bp/ui-components';
import { GroupType, useGroupAsCourseQuery } from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import styles from '../../Institution/subpages/InstitutionSubpages.module.scss';
import { getExternalService } from '../../../utils/getExternalServiceHelper';
import { CourseTableType } from '../../../components/CoursesTable/CoursesTableTeacher';
import dayjs from 'dayjs';
import { GroupFormOther } from '../../../components/GroupForm/GroupFormOther';
import { BpLink } from '../../../components/BpLink/BpLink';
import { updateClaims } from '../../../utils/authStore';

export const InstitutionCollaborationCoursesSubpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('GROUP');
  const [claimsTrigger, setClaimsTrigger] = useState<boolean>(false);

  const { pimAuthClaims } = useAuthClaims();

  const [formProfilesOpen, setFormProfilesOpen] = useState<boolean>(false);
  const [currentCourseUuid, setCurrentCourseUuid] = useState<string>('');

  const [{ data }] = useGroupAsCourseQuery({
    variables: {
      where: {
        organization: {
          uuid_IN: pimAuthClaims.getOrganization().collaboratesWith.map((o) => o.uuid),
        },
        type: GroupType.Course,
      },
    },
    context,
  });

  const tableData: CourseTableType[] = useMemo(() => {
    return (
      data?.groups
        .map((course) => {
          return {
            uuid: course.uuid,
            organizationUuid: course.organization.uuid,
            organizationName: course.organization.displayName ?? '',
            name: course.name,
            meetingInProgress: course.externalServices?.matrix ?? false,
            count: 0,
            newMessage: false,
            community: course.isCommunity,
            source:
              course.managedBy !== 'bp'
                ? course.managedBy
                : course.admins.map((admin) => admin.displayNameShort).toString(),
            nextcloud: {
              enabled: course.externalServices?.nextcloud?.enabled ?? false,
              lastSync: course.externalServices?.nextcloud?.lastSync
                ? dayjs(course.externalServices?.nextcloud.lastSync)
                : undefined,
            },
            threema: {
              enabled: course.externalServices?.threema?.enabled ?? false,
              lastSync: course.externalServices?.threema?.lastSync
                ? dayjs(course.externalServices?.threema.lastSync)
                : undefined,
            },
          };
        })
        .filter((g) => g.community) ?? []
    );
  }, [data]);

  const handleProfilesEdit = (uuid: string) => {
    setFormProfilesOpen(true);
    setCurrentCourseUuid(uuid);
  };

  // for Collaboration Groups only Members are editable
  const canEditProfiles = (row: Row<CourseTableType>) =>
    perms?.canUpdateCourse({ uuid: row.original.uuid, organization: { uuid: pimAuthClaims.getOrganizationUuid() } }) &&
    row.original.organizationUuid !== pimAuthClaims.getOrganizationUuid();

  const columns: TableColumns<CourseTableType>[] = useMemo(() => {
    return [
      {
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          return (
            <BpLink
              value={row.original.name}
              onNavigate={() => navigate('/courses/' + row.original.uuid)}
              isEditorOfCourse={
                perms?.isGroupEditor({
                  uuid: row.original.uuid,
                  organization: { uuid: row.original.organizationUuid },
                }) ?? false
              }
            />
          );
        },
        canExpand: true,
        size: 400,
      },
      {
        header: t('institution.title'),
        id: 'organizationName',
        accessorKey: 'organizationName',
        size: 400,
        cell: ({ row }: { row: Row<CourseTableType> }) => (
          <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.organizationName}>
            {row.original.organizationName}
          </Tooltip>
        ),
      },
      {
        header: t('schoolyears.titleSingular'),
        id: 'schoolyearName',
        accessorKey: 'schoolyearName',
        size: 180,
      },
      {
        header: t('common.managedBy'),
        id: 'source',
        accessorKey: 'source',
        size: 130,
      },
      {
        header: t('common.nextcloud'),
        id: 'nextcloud',
        accessorKey: 'nextcloud',
        cell: ({ row }) => {
          const service = getExternalService(
            row.original.nextcloud?.enabled ?? false,
            row.original.nextcloud?.lastSync,
          );
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
      {
        header: t('common.threema'),
        id: 'threema',
        accessorKey: 'threema',
        cell: ({ row }) => {
          const service = getExternalService(row.original.threema?.enabled ?? false, row.original.threema?.lastSync);
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropDownMenu = (row: Row<CourseTableType>): DropdownMenuItem[] => {
    const menu: DropdownMenuItem[] = [];
    if (canEditProfiles(row)) {
      menu.push({
        label: t('common.editProfiles'),
        color: 'default',
        onClick: () => handleProfilesEdit(row.original.uuid),
      });
    }
    return menu;
  };

  return (
    <BpSubpage className={styles.courses} isForbidden={!perms?.canUpdateCollaborationCourses()}>
      {perms?.canCreateCourse() && (
        <Table<CourseTableType>
          breakpoint='580px'
          data-cy='courseList'
          showActionBar
          isOnWhite={false}
          showBorderRadius
          showShadow
          showSort
          actionBarSettings={{
            showExpertFilter: true,
          }}
          emptyStateSettings={{
            icon: <CoursesIcon />,
          }}
          columns={columns}
          data={tableData}
          lastCol={(row) => {
            return dropDownMenu.length > 0 ? (
              <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                <DropdownMenu data={dropDownMenu(row)} />
              </Dropdown>
            ) : undefined;
          }}
        />
      )}

      <Modal
        isOpen={formProfilesOpen}
        onRequestClose={() => {
          setFormProfilesOpen(false);
          setCurrentCourseUuid('');
        }}
        title={t('groups.editGroup')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <GroupFormOther
            groupUuid={currentCourseUuid}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(!claimsTrigger));
              setFormProfilesOpen(false);
              setCurrentCourseUuid('');
            }}
          />
        </Suspense>
      </Modal>
    </BpSubpage>
  );
};
