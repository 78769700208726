import * as Yup from 'yup';
import i18next from 'i18next';

export const validationSchema = Yup.object().shape({
  adminUser: Yup.string()
    .required(i18next.t('validation.required.adminUser'))
    .min(2, i18next.t('validation.textTooShort')),
  adminPass: Yup.string()
    .required(i18next.t('validation.required.adminPass'))
    .min(8, i18next.t('validation.textTooShort')),
  serverUri: Yup.string()
    .url(i18next.t('validation.required.serverUri'))
    .required(i18next.t('validation.required.serverUri')),
});
