import { useBpUpdateFileEntryMutation } from '../../client/bp-graphql-client-defs';
import { showErrorToast } from '../../utils/showErrorToast';
import { Form, Formik } from 'formik';
import { Input } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { getFileExt } from '../../utils/getFileExt';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { FileEntryTableType } from '../FileTable/FileTable';

type FileName = {
  name: string;
  uuid?: string;
  ext: string;
};

type Args = {
  file: FileEntryTableType;
  onClose: () => void;
  onRenamed: (uuid: string, newName: string) => void;
};

export function FileRenameForm({ file, onClose, onRenamed }: Args) {
  const { t } = useTranslation();
  const [, updateFileEntry] = useBpUpdateFileEntryMutation();
  const context = useMemoizedCacheTag('FILE_ENTRY');

  const fileExt = getFileExt(file?.filename ?? '');

  const validate = (values: FileName) => {
    const errors: Partial<FileName> = {};

    if (!values.name) {
      errors.name = 'Filename too short';
    }
    return errors;
  };

  const handleSubmit = async (values: FileName) => {
    const { error } = await updateFileEntry(
      {
        where: {
          uuid: file?.uuid,
        },
        update: {
          filename: values.name + '.' + fileExt,
        },
      },
      context,
    );

    if (error) {
      showErrorToast(error);
    }

    if (!error) {
      showSuccessToast(t('common.renamed'));
    }
    onRenamed(file.uuid, values.name + '.' + fileExt);
    onClose();
  };

  const initalValues = {
    name: file?.filename.split('.').slice(0, -1).join('.') ?? '',
    ext: fileExt,
  };

  return (
    <Formik
      initialValues={initalValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validate={validate}
    >
      {({ values, handleBlur, handleChange, isSubmitting, errors }) => (
        <Form>
          <Input
            name={'name'}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name?.toString()}
            error={errors.name?.toString()}
          />
          <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
        </Form>
      )}
    </Formik>
  );
}
