import { Input, Select, Switch } from '@bp/ui-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { AppointmentFormValues } from '../../types';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { AvailableService } from '../../../../hooks/useAvailableServicesForOrganization';

export const VirtualMeetingSettings = ({ availableServices }: { availableServices: AvailableService[] }) => {
  const { t } = useTranslation();

  const { values, initialValues, errors, handleBlur, setFieldValue, handleChange } =
    useFormikContext<AppointmentFormValues>();

  const services = useMemo(() => {
    const services: { value: string; label: string }[] = [];
    if (availableServices.find((s) => s.service === 'Zoom')) {
      services.push({ value: 'zoom', label: 'Zoom' });
    }
    if (availableServices.find((s) => s.service === 'BigBlueButton')) {
      services.push({ value: 'bbb', label: 'BigBlueButton' });
    }

    return services;
  }, [availableServices]);

  const serviceOpts = useCreateSelectOptions(services, 'value', 'label');

  const defaultValue = useMemo(() => {
    let defaultService = serviceOpts.find((opt) =>
      initialValues.categories ? initialValues?.categories.includes(opt.value as string) : false,
    );
    if (!defaultService && services.length === 1) {
      defaultService = services[0];
      if (typeof defaultService.value === 'string' && !values.categories.includes(defaultService.value)) {
        values.categories.push(defaultService.value);
      }
    }

    return defaultService;
  }, [serviceOpts, initialValues.categories, services, values.categories]);

  return (
    // TODO style with inline classes
    <div>
      <Select
        name='service'
        options={serviceOpts}
        placeholder={t('meetings.chooseService.placeholder')}
        label={t('meetings.chooseService.label')}
        onChange={(value) => setFieldValue('service', value)}
        error={errors.service?.label}
        onBlur={handleBlur}
        defaultValue={defaultValue}
      />
      <div>
        <Input
          type={'number'}
          step={1}
          name={'participantsCount'}
          placeholder={t('meetings.participantsCount.placeholder')}
          label={t('meetings.participantsCount.label')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.participantsCount?.toString()}
          error={errors.participantsCount?.toString()}
        />
      </div>
      {availableServices.find((s) => s.service === values.service.value)?.allowRecording && (
        <div>
          <div>{t('meetings.record')}</div>
          <Switch size='large' name='record' onChange={handleChange} />
        </div>
      )}
    </div>
  );
};
