import { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, GridRow, Input, TextArea } from '@bp/ui-components';
import { useMatrixClient } from '../../hooks/matrix/useMatrixClient';
import { MatrixAnnouncementContent, MatrixNoticeTypes } from '../../hooks/matrix/useGetAnnouncements';
import { MsgType } from 'matrix-js-sdk';
import { ModalBottomButtons } from 'components/ModalBottomButtons/ModalBottomButtons';
import { RoomMessageEventContent } from 'matrix-js-sdk/lib/types';

type AnnouncementFormType = {
  title: string;
  message: string;
};

type AnnouncementFormProps = {
  matrixRoomId: string;
  onClose: () => void;
};

export const AnnouncementForm: FC<AnnouncementFormProps> = ({ onClose, matrixRoomId }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const matrix = useMatrixClient();
  const initialValues: AnnouncementFormType = {
    title: '',
    message: '',
  };

  const handleSubmit = async (values: AnnouncementFormType) => {
    if (!loading) {
      setLoading(true);
      const data: MatrixAnnouncementContent = {
        type: MatrixNoticeTypes.Announcement,
        message: values.message,
        title: values.title,
      };

      const content: RoomMessageEventContent = {
        body: JSON.stringify(data),
        formatted_body: values.message,
        msgtype: MsgType.Text,
      };

      await matrix?.sendMessage(matrixRoomId, content);

      setLoading(false);
      onClose();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, errors, resetForm, handleChange }) => {
        return (
          <Form className='bp__form'>
            <Grid>
              <GridRow spacingBottom='s'>
                <Input onChange={handleChange} name='title' value={values.title} label={t('common.title')} />
              </GridRow>
              <GridRow spacingTop='s'>
                <TextArea
                  onChange={handleChange}
                  name='message'
                  rows={3}
                  value={values.message}
                  label={t('common.description')}
                />
              </GridRow>
            </Grid>
            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  onClose();
                },
              }}
              submitButton={{
                text: t('common.publish'),
              }}
              isLoading={loading}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
