import { Form, Formik } from 'formik';
import { useCreateThreemasMutation, useUpdateThreemasMutation } from '../../client/bp-graphql-client-defs';
import { DatePicker, Input } from '@bp/ui-components';
import { LicensedProductThreema } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { ensureDate } from '../../utils/dateCalculations';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { validationSchema } from './threemaFormScheme';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';

type ThreemaValuesType = {
  apiToken: string | null;
  notBefore: string;
  notAfter: string;
};

type ThreemaFormProps = { data: LicensedProductThreema; closeModal: () => void };

export const ThreemaForm = ({ data, closeModal }: ThreemaFormProps) => {
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const { t } = useTranslation();
  const context = useMemoizedCacheTag('PRODUCTS');

  const [, updateThreemas] = useUpdateThreemasMutation();
  const [, createThreemas] = useCreateThreemasMutation();
  const handleClose = () => {
    closeModal();
  };

  const currentBookingEdge = data?.organizationConnection?.edges[0];

  const initialValues: ThreemaValuesType = {
    apiToken: data?.apiToken ?? '',
    notBefore: ensureDate(currentBookingEdge?.properties.notBefore ?? null).toUTCString(),
    notAfter: ensureDate(currentBookingEdge?.properties.notAfter ?? null).toUTCString(),
  };

  const onSubmit = async (values: ThreemaValuesType) => {
    if (perms?.canUpdateThreema({ uuid: organizationUuid })) {
      if (data.uuid === undefined) {
        const resp = await createThreemas(
          {
            input: [
              {
                apiToken: values.apiToken,
                organization: {
                  connect: {
                    edge: {
                      notAfter: values.notAfter,
                      notBefore: values.notBefore,
                    },
                    where: { node: { uuid: organizationUuid } },
                  },
                },
              },
            ],
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      } else {
        const resp = await updateThreemas(
          {
            update: {
              apiToken: values.apiToken,
              organization: {
                update: {
                  edge: {
                    notAfter: values.notAfter,
                    notBefore: values.notBefore,
                  },
                },
              },
            },
            where: { uuid: data.uuid },
          },
          context,
        );

        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        const onClose = () => {
          resetForm();
          handleClose();
        };

        return (
          <>
            <Form>
              <DatePicker
                label={t('institution.bookedFromDate')}
                onChange={(e) => setFieldValue('notBefore', e?.toUTCString())}
                value={ensureDate(values.notBefore)}
                name={'notBefore'}
                showMonthYearDropdown
              />

              <DatePicker
                label={t('institution.bookedUntilDate')}
                onChange={(e) => setFieldValue('notAfter', e?.toUTCString())}
                value={ensureDate(values.notAfter)}
                name={'notAfter'}
                showMonthYearDropdown
              />

              <Input
                className='mb-2'
                label={t('settings.threemaWorkApiToken')}
                name={'apiToken'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apiToken?.toString()}
                error={errors.apiToken}
              />

              <ModalBottomButtons errors={errors} closeButton={{ callback: onClose }} isLoading={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
