import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styles from './SubjectForm.module.scss';
import { Grid, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import {
  useCreateBpSubjectMutation,
  useGlobalSubjectsQuery,
  useUpdateBpSubjectMutation,
} from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import * as Yup from 'yup';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { SubjectType } from '../../pages/Institution/subpages/InstitutionSubjectsSubpage';

type SubjectFormProps = { closeModal: () => void; subject?: SubjectType | undefined };

export const SubjectForm = ({ closeModal, subject }: SubjectFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('SUBJECT');
  const [, updateSubject] = useUpdateBpSubjectMutation();
  const [, createSubject] = useCreateBpSubjectMutation();
  const [globalSubjects] = useGlobalSubjectsQuery({ variables: { where: { country: 'de' } } });

  const formikValues = {
    name: subject?.name ?? '',
    globalSubject: subject?.globalSubject,
  };

  const handleSubmit = async (values: typeof formikValues) => {
    if (subject && perms?.canUpdateSubject({ uuid: organizationUuid })) {
      const { error } = await updateSubject(
        {
          update: {
            globalSubject: values.globalSubject,
            name: values.name,
          },
          uuid: subject?.uuid as string,
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('subjects.saved'));
    }
    if (!subject && perms?.canCreateSubject({ uuid: organizationUuid })) {
      const { error } = await createSubject(
        {
          input: {
            globalSubject: values.globalSubject,
            name: values.name,
            organization: organizationUuid,
          },
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('subjects.saved'));
    }

    closeModal();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required.designation')).min(2, t('validation.textTooShort')),
  });

  const selectOpts = useCreateSelectOptions(globalSubjects.data?.globalSubjects, 'subject', 'subject');

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues} validationSchema={validationSchema}>
      {({ resetForm, isSubmitting, errors, handleChange, values, setFieldValue }) => {
        return (
          <Form className={styles.form}>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <Input
                  label={t('common.designation')}
                  onChange={handleChange}
                  name={'name'}
                  value={values.name}
                  type={'text'}
                  error={errors.name}
                />
              </GridRow>
              <GridRow spacingTop='s'>
                <Select
                  label={t('subjects.globalSubject')}
                  onChange={(ev) => setFieldValue('globalSubject', (ev as unknown as SelectOptionType).value)}
                  name={'globalSubject'}
                  isSearchable={true}
                  defaultValue={{ value: values.globalSubject ?? '', label: values.globalSubject ?? '' }}
                  options={selectOpts}
                  menuPosition='fixed'
                />
              </GridRow>
            </Grid>
            <ModalBottomButtons
              errors={errors}
              closeButton={{
                callback: () => {
                  resetForm();
                  closeModal();
                },
              }}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
