import { FC, useState } from 'react';
import styles from './Header.module.scss';
import { HeaderMeetingsFlyout } from './components/Meetings/HeaderMeetingsFlyout';
import { NotificationsFlyout } from './components/Notifications/NotificationsFlyout';
import {
  ArrowDownIcon,
  Button,
  MessagesActiveIcon,
  MessagesDefaultIcon,
  ProfileIcon,
  QuestionmarkCircleIcon,
} from '@bp/ui-components';
import { useAuth } from 'react-oidc-context';
import { AboutModal } from '../Modals/AboutModal/AboutModal';
import classNames from 'classnames';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useNavigate } from 'react-router-dom';
import { useMatrixDmRooms } from '../../hooks/matrix/useMatrixDmRooms';
import { NotificationCountType } from 'matrix-js-sdk';
import { usePermissionChecker } from 'hooks/usePermissionChecker';

type HeaderProps = {
  className?: string | undefined;
};

export const Header: FC<HeaderProps> = ({ className }) => {
  const { pimAuthClaims } = useAuthClaims();
  const organization = pimAuthClaims.getOrganization();
  const auth = useAuth();
  const perms = usePermissionChecker();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const rooms = useMatrixDmRooms();

  const unread =
    rooms?.reduce((prev, room) => {
      return prev + room.getUnreadNotificationCount(NotificationCountType.Total);
    }, 0) ?? 0;

  const messagesIcon = unread > 0 ? <MessagesActiveIcon /> : <MessagesDefaultIcon />;
  return (
    <>
      <div className={classNames(styles.header, className)}>
        <div className={styles['organisation-switch']} data-cy={'organizationSelect'}>
          {organization?.name && <div className={styles.name}>{organization.name}</div>}
          <Button
            hierarchy='tertiary'
            onClick={() => {
              auth.signinRedirect({
                prompt: ['select_account', 'consent'].join(' '),
              });
            }}
            icon={<ArrowDownIcon />}
          />
        </div>
        <div className={styles['fly-outs']}>
          <HeaderMeetingsFlyout />
          <Button onClick={() => navigate('/messages')} hierarchy='tertiary' icon={messagesIcon} />
          <NotificationsFlyout navigate={navigate} />
          <Button
            className={styles.about}
            hierarchy='tertiary'
            icon={<QuestionmarkCircleIcon onClick={() => setShowModal(true)} />}
          />
          <Button hierarchy='secondary' icon={<ProfileIcon />} onClick={() => navigate('/profile')}>
            {pimAuthClaims.getProfile().displayName}
          </Button>
        </div>
      </div>

      <AboutModal
        isAdmin={!!perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())}
        state={showModal}
        setState={(value) => setShowModal(value)}
      />
    </>
  );
};
