import { useEffect } from 'react';
import { UseQueryResponse } from 'urql';

export function useAutoUpdateQuery(queryResponse: UseQueryResponse, interval: number = 60) {
  const [{ fetching }, reexecuteQuery] = queryResponse;
  useEffect(() => {
    if (fetching) return;

    // Set up to refetch in interval seconds, if the query is idle
    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' });
    }, interval * 1000);

    return () => clearTimeout(timerId);
  }, [fetching, reexecuteQuery, interval]);

  return queryResponse;
}
