import { Dispatch, SetStateAction } from 'react';
import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { AboutBP } from 'components/AboutBP/AboutBP';

export type AboutModalProps = {
  isAdmin?: boolean;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
};

export const AboutModal = ({ isAdmin, state, setState }: AboutModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('app.title.full')} isOpen={state} onRequestClose={() => setState(false)}>
      <AboutBP isAdmin={!!isAdmin} />
    </Modal>
  );
};
