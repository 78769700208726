import { Button, DotsVerticalIcon, Dropdown, DropdownMenu, Table } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { niceDate } from '../../utils/dateCalculations';
import { BpLink } from 'components/BpLink/BpLink';
import { useConfirm } from 'hooks/useConfirm';

export type AppointmentsPreviewTableType = {
  uuid: string;
  name: string;
  date: string;
};

type AppointmentsPreviewTableProps = {
  data: AppointmentsPreviewTableType[];
  onEdit: (uuid: string) => void;
  onNavigate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  isGroupEditor: boolean;
};

export const AppointmentsPreviewTable: FC<AppointmentsPreviewTableProps> = ({
  data,
  onNavigate,
  onEdit,
  onDelete,
  isGroupEditor,
}) => {
  const { t } = useTranslation();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.title'),
    defaultConfirmText: t('delete.title'),
  });

  return (
    <>
      <Table<AppointmentsPreviewTableType>
        maxHeight={220}
        className='hide-gutter'
        canScroll
        hideHeader
        showSort
        customPadding='var(--spacing-6)'
        customRowHeight='var(--list-row-height)'
        customLastColSpacing='var(--spacing-3)'
        lastColWidth='150px'
        breakpoint={null}
        data={data}
        columns={[
          {
            id: 'name',
            accessorKey: 'name',
            cell: ({ row }: { row: Row<AppointmentsPreviewTableType> }) => (
              <BpLink
                value={row.original.name}
                onNavigate={() => onNavigate(row.original.uuid)}
                isEditorOfCourse={isGroupEditor}
              />
            ),
            canExpand: true,
          },
        ]}
        emptyStateSettings={{
          hideIcon: true,
          size: 'small',
          title: '',
          subtitle: t('appointments.noAppointments'),
          forcedHeight: '150px',
          fitParent: true,
        }}
        lastCol={(row: Row<AppointmentsPreviewTableType>) => {
          return (
            <div className='bp__last-col'>
              <div>{`${t('common.on')} ${niceDate(row.original.date, 'short', 'short')}`}</div>
              <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
                <DropdownMenu
                  data={[
                    {
                      label: t('common.edit'),
                      onClick: () => {
                        onEdit(row.original.uuid);
                      },
                    },
                    { type: 'ruler' },
                    {
                      label: t('delete.title'),
                      color: 'error',
                      onClick: async () => {
                        const res = await confirmDelete();
                        if (res) {
                          onDelete(row.original.uuid);
                        }
                      },
                    },
                  ]}
                />
              </Dropdown>
            </div>
          );
        }}
      />

      <ConfirmationDialog />
    </>
  );
};
