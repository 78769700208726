import { useTranslation } from 'react-i18next';
import styles from './SubmissionsTab.module.scss';
import { CheckIcon, Grid, GridColumn, GridRow, NotificationDefaultIcon, Table } from '@bp/ui-components';
import { isAfter, niceDate } from '../../utils/dateCalculations';
import { SubmissionStatus } from '../../client/bp-graphql-client-defs';
import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BpCard } from '../BpCard/BpCard';
import { useGetMatrixRooms } from '../../hooks/matrix/useGetMatrixRooms';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';
import { useMarkAsRead } from '../../hooks/matrix/useMarkAsRead';
import { Room } from 'matrix-js-sdk';
import { Submission } from '@bp/bp-graphql-types';

type SubmissionType = Submission & {
  room?: Room;
  newMessage: boolean;
};

type SubmissionsTabProps = {
  profiles: { uuid: string; firstName?: string | null; lastName?: string | null }[] | undefined;
  openSubmission: (submission: Submission) => void;
  submissions: Submission[] | undefined;
};

export const SubmissionsTab: FC<SubmissionsTabProps> = ({ profiles, openSubmission, submissions }) => {
  const { t } = useTranslation();
  useRefreshOnEvent();

  const { markAsRead, refresh } = useMarkAsRead();
  const [, setRefresh] = useState<Date | null>();

  const submissionsNew = submissions?.filter((s) => {
    return s.status === SubmissionStatus.New || s.status === SubmissionStatus.Updated;
  });

  useEffect(() => {
    setRefresh(refresh);
  }, [refresh]);

  const submissionsDone = submissions?.filter((s) => s.status === SubmissionStatus.Done);
  const submissionsTodo = submissions?.filter((s) => {
    return (
      s.status === SubmissionStatus.Todo ||
      s.status === SubmissionStatus.Draft ||
      s.status === SubmissionStatus.Feedback
    );
  });

  const rooms = useGetMatrixRooms();

  const doneSubmissionsWithMessageInfo: SubmissionType[] =
    submissionsDone?.map((submission) => {
      const submissionRoom = rooms?.find((room) => {
        return room.name.includes(submission.uuid);
      });
      const newMessages = submissionRoom ? submissionRoom.getRoomUnreadNotificationCount() > 0 : false;
      return {
        newMessage: newMessages,
        room: submissionRoom,
        ...submission,
      };
    }) ?? [];

  const newSubmissionsWithMessageInfo: SubmissionType[] =
    submissionsNew?.map((submission) => {
      const submissionRoom = rooms?.find((room) => {
        return room.name.includes(submission.uuid);
      });
      const newMessages = submissionRoom ? submissionRoom.getRoomUnreadNotificationCount() > 0 : false;
      return {
        newMessage: newMessages,
        room: submissionRoom,
        ...submission,
      };
    }) ?? [];

  const todoSubmissionsWithMessageInfo: SubmissionType[] =
    submissionsTodo?.map((submission) => {
      const submissionRoom = rooms?.find((room) => {
        return room.name.includes(submission.uuid);
      });
      const newMessages = submissionRoom ? submissionRoom.getRoomUnreadNotificationCount() > 0 : false;
      return {
        newMessage: newMessages,
        room: submissionRoom,
        ...submission,
      };
    }) ?? [];

  return (
    <div className={styles['submissions-tab']}>
      <Grid>
        <GridRow mobileGap='var(--spacing-4)'>
          <GridColumn width={4}>
            <BpCard
              header={{
                headline: t('submissions.teacher.todo'),
                subHeadline: submissionsTodo?.length,
                actions: [
                  {
                    icon: <NotificationDefaultIcon className='svg-icon small' />,
                    text: t('notifications.notifyAll'),
                    callback: () => {
                      alert('notifications clicked');
                    },
                    disabled: submissionsTodo?.length === 0,
                  },
                ],
              }}
              noPadding
            >
              <Table<SubmissionType>
                hideHeader
                canScroll
                maxHeight={450}
                breakpoint={null}
                customRowHeight='var(--spacing-8)'
                customPadding='var(--spacing-6)'
                lastColWidth='0'
                showRowHover
                onRowClick={(event, row) => {
                  if (row.original.newMessage) {
                    markAsRead(row.original.room);
                  }
                  openSubmission(row.original);
                }}
                columns={[
                  {
                    id: 'name',
                    size: 350,
                    cell: ({ row }) => {
                      const profile = profiles?.find((p) => p.uuid === row.original.owner.uuid);

                      return (
                        <div className={styles['info-wrapper']}>
                          <div className={styles.info}>
                            <div className={styles.name}>{`${profile?.firstName} ${profile?.lastName}`}</div>
                          </div>
                          {row.original.newMessage && <div className='bp__dot'></div>}
                        </div>
                      );
                    },
                  },
                ]}
                data={todoSubmissionsWithMessageInfo ?? []}
                emptyStateSettings={{
                  size: 'small',
                  hideIcon: true,
                  title: '',
                  subtitle: t('submissions.noPending'),
                  forcedHeight: '100px',
                }}
              />
            </BpCard>
          </GridColumn>
          <GridColumn width={4}>
            <BpCard
              header={{
                headline: t('submissions.teacher.new'),
                subHeadline: submissionsNew?.length,
              }}
              noPadding
            >
              <Table<SubmissionType>
                hideHeader
                canScroll
                maxHeight={450}
                breakpoint={null}
                customRowHeight='var(--spacing-10)'
                customPadding='var(--spacing-6)'
                lastColWidth='0'
                showRowHover
                onRowClick={(event, row) => {
                  if (row.original.newMessage) {
                    markAsRead(row.original.room);
                  }
                  openSubmission(row.original);
                }}
                columns={[
                  {
                    id: 'name',
                    size: 350,
                    cell: ({ row }) => {
                      const profile = profiles?.find((p) => p.uuid === row.original.owner.uuid);
                      const name = `${profile?.firstName} ${profile?.lastName}`;
                      return (
                        <div className={styles['info-wrapper']}>
                          <div className={styles.info}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.date}>{niceDate(row.original.updated, 'short', 'short')}</div>
                          </div>
                          {row.original.newMessage && <div className='bp__dot'></div>}
                        </div>
                      );
                    },
                  },
                ]}
                data={newSubmissionsWithMessageInfo ?? []}
                emptyStateSettings={{
                  size: 'small',
                  hideIcon: true,
                  title: '',
                  subtitle: t('submissions.noSubmissions'),
                  forcedHeight: '100px',
                }}
              />
            </BpCard>
          </GridColumn>
          <GridColumn width={4}>
            <BpCard
              header={{
                headline: t('submissions.teacher.done'),
                subHeadline: submissionsDone?.length,
              }}
              noPadding
            >
              <Table<SubmissionType>
                hideHeader
                canScroll
                maxHeight={450}
                breakpoint={null}
                customRowHeight='var(--spacing-10)'
                customPadding='var(--spacing-6)'
                lastColWidth='0'
                showRowHover
                onRowClick={(event, row) => {
                  if (row.original.newMessage) {
                    markAsRead(row.original.room);
                  }
                  openSubmission(row.original);
                }}
                columns={[
                  {
                    id: 'name',
                    size: 350,
                    cell: ({ row }) => {
                      const profile = profiles?.find((p) => p.uuid === row.original.owner.uuid);
                      const name = `${profile?.firstName} ${profile?.lastName}`;
                      return (
                        <div className={styles['info-wrapper']}>
                          <div className={styles.info}>
                            <div className={styles.name}>
                              <CheckIcon className={classNames('svg-icon small white', styles.check)} />
                              {name}
                            </div>
                            <div className={styles.date}>
                              {niceDate(row.original.updated, 'short', 'short')}
                              {isAfter(row.original.updated, row.original.assignment[0].dueDate?.date ?? '') && (
                                <div className={styles.late}>{t('submissions.late')}</div>
                              )}
                            </div>
                          </div>
                          {row.original.newMessage && <div className='bp__dot'></div>}
                        </div>
                      );
                    },
                  },
                ]}
                data={doneSubmissionsWithMessageInfo ?? []}
                emptyStateSettings={{
                  size: 'small',
                  hideIcon: true,
                  title: '',
                  subtitle: t('submissions.noTicked'),
                  forcedHeight: '100px',
                }}
              />
            </BpCard>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
