import styles from './PageLayoutPlaceholder.module.scss';
import { Logo } from '@bp/ui-components';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export const PageLayoutPlaceholder: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['page-layout-placeholder']} data-cy='layoutWrapper'>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo className={styles.logo} type={'bildungsplattform'} collapsed={false} />
          <div className={styles.about}>{t('app.about')}</div>
        </div>
        <div className={styles.children}>{children}</div>
      </div>
      <div className={styles.footer}>
        <a href='https://bildungsplattform.org/impressum/' target={'_blank'} rel='noreferrer'>
          {t('about.imprint')}
        </a>
        <div className={styles.border}></div>
        <a href='https://www.tk-schulsoftware.de/datenschutz/' target={'_blank'} rel='noreferrer'>
          {t('about.privacy')}
        </a>
        <div className={styles.border}></div>
        <a href='https://bildungsplattform.org/nutzungsbedingungen/' target={'_blank'} rel='noreferrer'>
          {t('about.tos')}
        </a>
        <div className={styles.border}></div>
        <a href='https://www.tk-schulsoftware.de/netzwerk/' target={'_blank'} rel='noreferrer'>
          {t('about.partner')}
        </a>
      </div>
    </div>
  );
};
