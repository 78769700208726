import './init';
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import './core/i18n/config';
import { Router } from './Router';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import dayjsRecur from 'dayjs-recur';

// styles
import 'sanitize.css';
import './core/styles/styles.scss';
import './core/styles/global.scss';
import './core/styles/typography.scss';
import './core/styles/modals.scss';
import './core/styles/calendar.scss';
import './core/styles/mixins/paddingMarginMixin.scss';

import i18n, { defaultNS } from './core/i18n/config';

// dayjs plugins
dayjs.locale('de');
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(dayjsRecur);
dayjs.extend(utc);

// Sentry instrumentation
if (import.meta.env.VITE_APP_SENTRY_DSN !== 'false') {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    release: 'bp-frontend@' + import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: import.meta.env.VITE_APP_SENTRY_TRACING_SAMPLE_RATE,
    tracePropagationTargets: [import.meta.env.VITE_APP_BACKEND_URI, 'localhost'],
    profilesSampleRate: import.meta.env.VITE_APP_SENTRY_TRACING_SAMPLE_RATE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
        <Router />
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
