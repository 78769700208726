import { ProfileTableData } from '../pages/Institution/subpages/InstitutionPersonsSubpage';
import { getTokens } from './authStore';

export const inviteUser = async (profile: ProfileTableData) => {
  const tokens = await getTokens();

  await fetch(`${import.meta.env.VITE_APP_AUTH_SERVER_URI}/invitation/${profile.uuid}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokens?.access_token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      profileUuid: profile.uuid,
      product: 'bp',
      baseUrl: import.meta.env.VITE_APP_REDIRECT_URI,
      email: profile.email,
    }),
  });
};
