import { BpCard } from '../BpCard/BpCard';
import { Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBpTeachingUnitsQuery } from '../../client/bp-graphql-client-defs';
import { useParams } from 'react-router-dom';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';

type TeachingUnitSelectProps = {
  teachingUnitUuid: string;
  error?: string;
  isModal?: boolean;
  onChange: (teachingUnitUuid: string) => void;
  className?: string | undefined;
};

export const TeachingUnitSelect: FC<TeachingUnitSelectProps> = ({
  teachingUnitUuid,
  error,
  isModal,
  onChange,
  className,
}) => {
  const { t } = useTranslation();
  const { courseUuid } = useParams();

  const context = useMemo(
    () => ({
      additionalTypenames: ['TeachingUnit'],
    }),
    [],
  );

  const [{ data }] = useBpTeachingUnitsQuery({
    context: context,
    variables: {
      teachingUnitsWhere: {
        group: {
          uuid: courseUuid,
        },
      },
    },
  });

  const opts = useCreateSelectOptions(data?.teachingUnits, 'uuid', 'title');

  return (
    <BpCard className={className} isEmbedded={isModal} header={{ headline: t('teachingUnits.titleSingular') }}>
      <Select
        className='mb-2'
        dense
        required={true}
        placeholder={t('common.chooseType', {
          type: t('teachingUnits.titleSingular'),
        })}
        name='teachingUnitSelect'
        disabled={false}
        defaultValue={opts.find((v) => v.value === teachingUnitUuid)}
        options={opts}
        onChange={(value) => {
          const singleValue = value as SingleValue<SelectOptionType>;
          onChange(singleValue?.value as string);
        }}
        error={error}
        menuPosition='fixed'
      />
    </BpCard>
  );
};
