import dayjs from 'dayjs';
import { BpEventType } from '../components/EventsList/EventsList';

export const hasBlockingEvents = (events: BpEventType[]) =>
  !!events.filter((event) => {
    const MIN_TIME_TO_NEXT_EVENT = 1800; // 30 mins
    const isVirtualEvent = event.virtualLocations.length > 0;
    const diff = event.start.unix() - dayjs().unix();
    return isVirtualEvent && diff > 0 && diff < MIN_TIME_TO_NEXT_EVENT;
  }).length;
