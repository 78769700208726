import { NoTasksIcon, Table, TableColumns, useDefaultSorting } from '@bp/ui-components';
import { BpLink } from '../BpLink/BpLink';
import { t } from 'i18next';
import { FC, useMemo } from 'react';

export type ActiveAssignmentsTableType = {
  uuid: string;
  title: string;
  openSubmissionsCount: number;
};

type ActiveAssignmentsTableProps = {
  data: ActiveAssignmentsTableType[];
  onClick: (uuid: string) => void;
};

export const ActiveAssignmentsTable: FC<ActiveAssignmentsTableProps> = ({ data, onClick }) => {
  const tableData = useMemo(() => data, [data]);
  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'title', desc: false }]);

  const taskColumns: TableColumns<ActiveAssignmentsTableType>[] = useMemo(
    () => [
      {
        header: t('common.name'),
        accessorKey: 'title',
        id: 'title',
        size: 350,
        canExpand: true,
        cell: ({ row }) => (
          <BpLink value={row.original.title} onNavigate={() => onClick(row.original.uuid)} isEditorOfCourse={true} />
        ),
      },
      {
        header: t('common.open'),
        id: 'openSubmissionsCount',
        accessorKey: 'openSubmissionsCount',
        size: 75,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Table<ActiveAssignmentsTableType>
      showSort
      canScroll
      breakpoint='580px'
      sorting={sorting}
      onSortingChange={onSortingChange}
      data={tableData}
      columns={taskColumns}
      customPadding='var(--spacing-6)'
      hideHeader={tableData.length === 0}
      emptyStateSettings={{
        fitParent: true,
        forcedHeight: '286px',
        size: 'small',
        subtitle: t('assignments.noAssignments'),
        title: '',
        iconColor: 'var(--color-primary-light)',
        icon: <NoTasksIcon />,
      }}
    />
  );
};
