import { FC, useState } from 'react';
import styles from './AnnouncementsList.module.scss';
import classNames from 'classnames';
import { Button, DeleteIcon, EmptyState, EmptyStateSettings, SendIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { backendApi } from '../../utils/backendApi';
import { MatrixEvent } from 'matrix-js-sdk';
import { niceDate } from 'utils/dateCalculations';

export type AnnouncementsType = {
  id: string;
  title: string;
  date: Date;
  message: string;
  isUnread: boolean;
  matrixEvent: MatrixEvent;
};

type AnnouncementsListProps = {
  emptyStateSettings?: EmptyStateSettings;
  className?: string | undefined;
  announcements: AnnouncementsType[];
  hasPermissionToDelete: boolean;
};

export const AnnouncementsList: FC<AnnouncementsListProps> = ({
  emptyStateSettings,
  className,
  announcements,
  hasPermissionToDelete,
}) => {
  const { t } = useTranslation();
  const matrixAvailable = useMatrixAvailable();

  const messages = sortMessages(announcements ?? []);

  const [loading, setLoading] = useState(false);

  function sortMessages(messages: AnnouncementsType[]): AnnouncementsType[] {
    return messages
      .sort(function (a, b) {
        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
      })
      .sort((a, b) => {
        return Number(b.isUnread) - Number(a.isUnread);
      });
  }

  async function deleteAnnouncement(matrixEvent: MatrixEvent) {
    if (loading) {
      return;
    }
    setLoading(true);
    const roomId = matrixEvent.getRoomId();
    const eventId = matrixEvent.getId();
    if (roomId && eventId) {
      await backendApi.redactMatrixEvent(roomId, eventId);
    }
    setLoading(false);
  }

  const classes = classNames(styles['announcements-list'], className);

  return (
    <div className={classes}>
      {messages.length === 0 || !matrixAvailable ? (
        <EmptyState
          {...emptyStateSettings}
          subtitle={emptyStateSettings?.subtitle ?? t('announcements.noAnnouncements')}
          icon={emptyStateSettings?.icon ?? <SendIcon />}
          iconColor={emptyStateSettings?.iconColor ?? 'var(--color-primary-light)'}
          fitParent={emptyStateSettings?.fitParent ?? true}
          padding={emptyStateSettings?.padding ?? 'xl'}
          size={emptyStateSettings?.size ?? 'small'}
          forcedHeight={emptyStateSettings?.forcedHeight ?? '220px'}
        />
      ) : (
        messages.map(({ title, date, message, isUnread, id, matrixEvent }) => {
          return (
            <div key={id} className={classNames(styles['message-row'], { [styles.unread]: isUnread })}>
              <div className={styles['top-section']}>
                <div className={styles.header}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.info}>{niceDate(date, 'medium')}</div>
                </div>
                <div className={styles.actions}>
                  {hasPermissionToDelete && (
                    <Button
                      isLoading={loading}
                      onClick={() => deleteAnnouncement(matrixEvent)}
                      icon={<DeleteIcon className='small' />}
                      hierarchy='tertiary'
                    />
                  )}
                </div>
              </div>
              <div className={styles.message}>{message}</div>
            </div>
          );
        })
      )}
    </div>
  );
};
