import { useContext } from 'react';
import { Form, Formik } from 'formik';
import { Checkbox, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useGroupSettingsQuery, useUpdateGroupSettingsMutation } from '../../client/bp-graphql-client-defs';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';

interface CourseSettingsProps {
  courseUuid: string | null;
  onClose: () => void;
}

type CourseSettingsFormType = {
  nextcloudFolder: boolean;
  threemaGroup: boolean;
};

export const CourseSettingsForm = ({ courseUuid, onClose }: CourseSettingsProps) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  if (courseUuid === null) {
    onClose();
  }

  const { threemaBroadcastLicense, cloudLicense } = useContext(OrganizationConfigContext);

  const [, updateGroupSettings] = useUpdateGroupSettingsMutation();
  const context = useMemoizedCacheTag('COURSE');

  const [{ data }] = useGroupSettingsQuery({
    variables: { where: { uuid: courseUuid } },
    context,
    requestPolicy: 'network-only',
  });

  const handleSubmit = async (values: CourseSettingsFormType) => {
    if (
      courseUuid &&
      perms?.canUpdateGroupSettings({
        uuid: courseUuid,
        organization: { uuid: organization.uuid },
      })
    ) {
      const { error } = await updateGroupSettings({
        where: { uuid: courseUuid },
        update: { nextcloudFolder: values.nextcloudFolder, threemaGroup: values.threemaGroup },
      });

      error ? showErrorToast(error) : showSuccessToast(t('common.saved'));
    }
    onClose();
  };

  const initialValues: CourseSettingsFormType = {
    nextcloudFolder: data?.groups[0].nextcloudConnection.edges[0]?.properties.enabled ?? false,
    threemaGroup: data?.groups[0].threemaBroadcastConnection.edges[0]?.properties.enabled ?? false,
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting, errors, setFieldValue, values }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.asCloudDirectory')}
                    checked={values.nextcloudFolder}
                    name='nextcloudFolder'
                    onChange={() => setFieldValue('nextcloudFolder', !values['nextcloudFolder'])}
                    disabled={!cloudLicense}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Checkbox
                    label={t('groups.asGroupInThreema')}
                    checked={values.threemaGroup}
                    name='threemaGroup'
                    onChange={() => setFieldValue('threemaGroup', !values['threemaGroup'])}
                    disabled={!threemaBroadcastLicense}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
