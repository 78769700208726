import React, { createContext, ReactNode, useState } from 'react';
import { useAuthClaims } from '../hooks/useAuthClaims';
import * as Sentry from '@sentry/react';

export type AppContextType = {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const AppContext = createContext<AppContextType>({
  sidebarIsOpen: false,
  setSidebarIsOpen: () => {
    return;
  },
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const { pimAuthClaims } = useAuthClaims();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.setUser({
      id: pimAuthClaims.getUser()?.uuid ?? '00000000-0000-0000-0000-000000000000',
      email: pimAuthClaims.getProfile()?.email ?? '',
      segment: pimAuthClaims.getOrganization()?.name ?? '',
    });
  }

  return (
    <AppContext.Provider
      value={{
        sidebarIsOpen,
        setSidebarIsOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
