import { useContext } from 'react';
import styles from './ListsBar.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, DotsVerticalIcon, Dropdown, DropdownMenu } from '@bp/ui-components';
import { DesktopContext } from '../../context/IsDesktop';

export const ListsBar = () => {
  const { t } = useTranslation();
  const { isDesktop } = useContext(DesktopContext);

  const classes = classNames(styles['lists-bar'], {
    [styles.mobile]: !isDesktop,
  });

  return (
    <div className={classes} data-cy='listsBar'>
      <div className={styles.title}>{t('dashboard.lists.title')}</div>
      {isDesktop && (
        <div className={styles['link-wrapper']}>
          <div className={styles.link}>{t('dashboard.lists.classes')}</div>
          <div className={styles.link}>{t('dashboard.lists.courses')}</div>
          <div className={styles.link}>{t('dashboard.lists.groups')}</div>
        </div>
      )}
      {!isDesktop && (
        <Dropdown
          trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon small' />} />}
          noPadding
        >
          <DropdownMenu
            data={[
              {
                label: t('dashboard.lists.classes'),
              },
              {
                label: t('dashboard.lists.courses'),
              },
              {
                label: t('dashboard.lists.groups'),
              },
            ]}
          />
        </Dropdown>
      )}
    </div>
  );
};
