import { Button, EmptyState, NoTasksIcon } from '@bp/ui-components';
import styles from '../Header/components/_Flyouts.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GroupRoles } from '@bp/pim-auth-constants';
import { useState } from 'react';
import { useRunningPlannedMeetings } from '../../hooks/useCalendarEvents';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpEventType } from '../EventsList/EventsList';
import { EventItem } from '../EventItem/EventItem';
import { copyEventLinkToClipboard, endEvent, startAndJoinEvent, startEvent } from 'utils/eventHelper';

const MeetingsFlyout = () => {
  const { t } = useTranslation();
  useRefreshOnEvent();

  const { pimAuthClaims } = useAuthClaims();

  const [isLoading, setLoading] = useState<boolean>(false);

  const { plannedMeetings, runningMeetings, refetchEvents } = useRunningPlannedMeetings(
    pimAuthClaims.getProfile().uuid,
  );

  const startMeeting = async (event: BpEventType) => {
    setLoading(true);
    if (event.virtualLocations[0] && event.virtualLocations[0].token) {
      await startEvent(event.virtualLocations[0].token);
    }
    refetchEvents();
    setLoading(false);
  };

  const copyToClipBoard = async (event: BpEventType) => {
    setLoading(true);
    if (event.virtualLocations[0] && event.virtualLocations[0].uri) {
      await copyEventLinkToClipboard(event.virtualLocations[0].uri);
    }
    setLoading(false);
  };

  const startAndJoinMeeting = async (event: BpEventType) => {
    setLoading(true);
    if (event.virtualLocations[0] && event.virtualLocations[0].token) {
      await startAndJoinEvent(
        event.virtualLocations[0].token,
        pimAuthClaims.getProfile(),
        pimAuthClaims
          .getRoles()
          .find(
            (r) =>
              r.target.uuid === event.originUuid &&
              (r.name.includes(GroupRoles.Admin) || r.name.includes(GroupRoles.Editor)),
          ) !== undefined,
      );
    }
    refetchEvents();
    setLoading(false);
  };

  const endMeeting = async (event: BpEventType) => {
    setLoading(true);
    if (event.virtualLocations[0] && event.virtualLocations[0].token) {
      await endEvent(event.virtualLocations[0].token);
    }
    refetchEvents();
    setLoading(false);
  };

  const canStartMeeting = (event: BpEventType): boolean => {
    return event.owner;
  };

  const meetingStartable = (m: BpEventType): boolean => {
    return dayjs(m.start).isBefore(dayjs().add(1, 'hour'));
  };

  return (
    <div className={classNames(styles['flyout-body'], styles.meetings, 'has-scrollbar')}>
      {runningMeetings.length === 0 && plannedMeetings.length === 0 ? (
        <EmptyState forcedHeight='500px' maxWidth='200px' title={t('meetings.noMeeting')} icon={<NoTasksIcon />} />
      ) : (
        <>
          <div className={styles.running}>
            <div className={styles.header}>{t('meetings.title')}</div>
            {runningMeetings?.length === 0 ? (
              <EmptyState size='small' title={t('meetings.noRunningMeetings')} hideIcon padding='l' />
            ) : (
              runningMeetings.map((m) => (
                <div className={styles['flyout-item']} key={m.uuid}>
                  <EventItem event={m} onClick={() => {}} isEmbedded isCompact={true} />
                  <div className={styles.actions}>
                    {canStartMeeting(m) && (
                      <Button isLoading={isLoading} hierarchy='secondary' onClick={() => endMeeting(m)}>
                        {t('meetings.end')}
                      </Button>
                    )}
                    <Button isLoading={isLoading} hierarchy='primary' onClick={() => startAndJoinMeeting(m)}>
                      {t('meetings.join')}
                    </Button>
                    {m.guestsAllowed && (
                      <Button isLoading={isLoading} hierarchy='tertiary' onClick={() => copyToClipBoard(m)}>
                        {t('meetings.copyLink')}
                      </Button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>

          <div className={styles.planned}>
            <div className={styles.header}>{t('meetings.plannedMeetings')}</div>
            {plannedMeetings?.length === 0 ? (
              <EmptyState size='small' title={t('meetings.noPlannedMeetings')} hideIcon padding='l' />
            ) : (
              <>
                {plannedMeetings?.map((m) => {
                  return (
                    <div className={styles['flyout-item']} key={m.uuid}>
                      <EventItem className={styles.event} event={m} isCompact={true} onClick={() => {}} isEmbedded />
                      {meetingStartable(m) && (
                        <div className={styles.actions}>
                          <Button
                            isLoading={isLoading}
                            type='submit'
                            hierarchy='secondary'
                            className='mb-2'
                            onClick={async () => await startAndJoinMeeting(m)}
                          >
                            {t('meetings.startAndJoin')}
                          </Button>
                          <Button isLoading={isLoading} hierarchy='primary' onClick={() => startMeeting(m)}>
                            {t('meetings.start')}
                          </Button>
                          {m.guestsAllowed && (
                            <Button isLoading={isLoading} hierarchy='tertiary' onClick={() => copyToClipBoard(m)}>
                              {t('meetings.copyLink')}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { MeetingsFlyout };
