import React, { ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { EventType, MatrixEvent } from 'matrix-js-sdk';
import { uuidRegexHelper } from '../../utils/uuidRegexHelper';
import {
  Button,
  CheckIcon,
  CloseIcon,
  EditIcon,
  GenerateIcon,
  InfoIcon,
  LogOutIcon,
  MessagesDefaultIcon,
  QuestionmarkIcon,
  UndoIcon,
  UpdateIcon,
  UploadIcon,
} from '@bp/ui-components';
import styles from './Activity.module.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { Submission, useBpProfileQuery } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { NotificationReasons, NotificationType } from '../../utils/matrixClient';
import { useRefreshOnEvent } from 'hooks/matrix/useRefreshOnEvent';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { useAuthClaims } from 'hooks/useAuthClaims';

type FnType = (eventId: string) => void;
type Props = {
  message: MatrixEvent;
  submission: Submission | undefined;
  grantFeedback: FnType;
  rejectFeedback: FnType;
  publishSubmission: (isPublic: boolean, eventId: string) => void;
  newSubmissionContent: () => void;
};

export const Activity = ({
  message,
  submission,
  grantFeedback,
  rejectFeedback,
  publishSubmission,
  newSubmissionContent,
}: Props) => {
  const { t } = useTranslation();
  const fromNow = dayjs(dayjs(message?.getDate())).fromNow(false);
  const result = uuidRegexHelper('user', message?.getSender() ?? '');
  const profileUuid = result ? result[1] : null;
  const permissionChecker = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();

  const [buttonsVisible, setButtonsVisible] = useState(true);

  const [, setOnline] = useState(false);
  const refresh = useRefreshOnEvent();
  const matrixAvailable = useMatrixAvailable();

  useEffect(() => {
    setOnline(matrixAvailable);
  }, [matrixAvailable, refresh]);

  const context = useMemoizedCacheTag('PROFILE');
  const [queryResult] = useBpProfileQuery({
    context,
    variables: { where: { uuid: profileUuid } },
  });

  const name = queryResult.data?.profiles[0]?.firstName + ' ' + queryResult.data?.profiles[0]?.lastName;

  const currentAssignment = submission?.assignment[0];
  const groupSubject = {
    uuid: currentAssignment?.holder.group?.uuid ?? '0',
    organization: { uuid: currentAssignment?.holder.organization?.uuid ?? '0' },
  };

  let messageMeta: Partial<NotificationType>;
  try {
    messageMeta = JSON.parse(message.getContent().body);
    // can be removed when typing ist consistent
    const type = messageMeta.type?.charAt(0).toUpperCase() + (messageMeta.type?.slice(1) ?? '');
    type && (messageMeta.type = type as NotificationReasons);
  } catch {
    messageMeta = message.getType() === EventType.RoomMessage ? { type: NotificationReasons.Comment } : {};
  }

  if (messageMeta.type === NotificationReasons.Comment && pimAuthClaims.getProfile().uuid !== profileUuid) {
    messageMeta = { type: NotificationReasons.Answer };
  }

  let msg: string;
  try {
    msg = message?.getContent().formatted_body;
  } catch (e) {
    msg = message?.getContent().body;
    console.error(e);
  }

  const grant = () => {
    const eventId = message.getId();
    if (eventId) {
      setButtonsVisible(false);
      grantFeedback(eventId);
    }
  };

  const reject = () => {
    const eventId = message.getId();
    if (eventId) {
      setButtonsVisible(false);
      rejectFeedback(eventId);
    }
  };

  const publish = (isPublic: boolean) => {
    setButtonsVisible(false);
    const eventId = message.getId();
    eventId && publishSubmission(isPublic, eventId);
  };

  const isTeacher = permissionChecker?.canViewSubmissions({
    uuid: currentAssignment?.uuid ?? '',
    ownerUuid: submission?.uuid ?? '',
    groupUuid: groupSubject.uuid,
    organization: groupSubject.organization,
  });

  let content: ReactElement;
  const type = messageMeta.type ? messageMeta.type?.charAt(0).toLowerCase() + messageMeta.type?.slice(1) : '';
  switch (type) {
    case NotificationReasons.Comment:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.blue)}>
            <MessagesDefaultIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div className={styles.blue}>
            <div>{t('activityProtocol.comment')}</div>
            <div className={styles.time}>{fromNow}</div>
            <div className={classnames(styles.textBox)}>{msg}</div>
          </div>
        </div>
      );
      break;
    case NotificationReasons.Answer:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.grey)}>
            <MessagesDefaultIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div className={styles.grey}>
            <div>
              <>{name} </>
              {t('activityProtocol.answer')}
            </div>
            <div className={styles.time}>{fromNow}</div>
            <div className={classnames(styles.textBox)}>{msg}</div>
          </div>
        </div>
      );
      break;
    case NotificationReasons.AssignmentOpened:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.grey)}>
            <GenerateIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.assignmentOpened')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );
      break;
    case NotificationReasons.SubmissionUpdated:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.grey)}>
            <EditIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.submissionWasEdited')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );

      newSubmissionContent && newSubmissionContent();
      break;
    case NotificationReasons.SubmissionNew:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.green)}>
            <CheckIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.submissionNew')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );

      newSubmissionContent && newSubmissionContent();
      break;
    case NotificationReasons.SubmissionFeedback:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.yellow)}>
            <UpdateIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.submissionFeedback')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );

      newSubmissionContent && newSubmissionContent();
      break;
    case NotificationReasons.RequestToFeedback:
      if (isTeacher) {
        content = (
          <div className={styles.grid}>
            <div className={classnames(styles.iconWrap, styles.grey)}>
              <QuestionmarkIcon className={classnames(styles.icon, 'svg-icon large')} />
            </div>
            <div>
              <div>
                {name} {t('activityProtocol.requestToFeedbackTeacher')}
              </div>
              <div className={styles.time}>{fromNow}</div>
              <div>{msg}</div>

              {buttonsVisible && (
                <div className={styles.buttons}>
                  <Button onClick={grant} icon={<CheckIcon className={'svg-icon white'} />}>
                    {t('common.agree')}
                  </Button>
                  <Button onClick={reject} hierarchy={'secondary'} icon={<CloseIcon />}>
                    {t('common.reject')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      } else {
        content = (
          <div className={styles.grid}>
            <div className={classnames(styles.iconWrap, styles.blue)}>
              <UpdateIcon className={classnames(styles.icon, 'svg-icon large')} />
            </div>
            <div>
              <div> {t('activityProtocol.requestToFeedbackStudent')}</div>
              <div className={styles.time}>{fromNow}</div>
            </div>
          </div>
        );
      }
      break;
    case NotificationReasons.FeedbackRejected:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.blue)}>
            <InfoIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.feedbackRejected')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );
      break;

    case NotificationReasons.AssignmentDeleted:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.grey)}>
            <LogOutIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.assignmentClosed')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );
      break;
    case NotificationReasons.RequestToPublish:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.grey)}>
            <QuestionmarkIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>
              {isTeacher
                ? t('activityProtocol.requestToPublishTeacher')
                : t('activityProtocol.requestToPublishStudent')}
            </div>
            <div className={styles.time}>{fromNow}</div>
            <div>{msg}</div>
            {!isTeacher && !messageMeta.clicked && (
              <div className={styles.buttons}>
                <Button icon={<CheckIcon className={'svg-icon white'} />} onClick={() => publish(true)}>
                  {t('common.agree')}
                </Button>
                <Button hierarchy={'secondary'} icon={<CloseIcon />} onClick={() => publish(false)}>
                  {t('common.reject')}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
      break;
    case NotificationReasons.SubmissionPublished:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.green)}>
            <UploadIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.submissionPublished')}</div>
            <div className={styles.time}>{fromNow}</div>
            {!isTeacher && (
              <div className={styles.buttons}>
                <Button icon={<UndoIcon className={'svg-icon white'} />} onClick={() => publish(false)}>
                  {t('common.undo')}
                </Button>
              </div>
            )}
          </div>
        </div>
      );
      break;

    case NotificationReasons.SubmissionDePublished:
      content = (
        <div className={styles.grid}>
          <div className={classnames(styles.iconWrap, styles.blue)}>
            <UndoIcon className={classnames(styles.icon, 'svg-icon large')} />
          </div>
          <div>
            <div>{t('activityProtocol.submissionDePublished')}</div>
            <div className={styles.time}>{fromNow}</div>
          </div>
        </div>
      );
      break;

    default:
      content = <></>; // no tag, so it does not claim space in the list
  }

  return content;
};
