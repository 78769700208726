import { useTranslation } from 'react-i18next';
import { BpPage } from 'components/BpPage/BpPage';
import { BpCard } from 'components/BpCard/BpCard';
import { AboutBP } from 'components/AboutBP/AboutBP';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { usePermissionChecker } from 'hooks/usePermissionChecker';

export const AboutPage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const perms = usePermissionChecker();

  return (
    <BpPage title={t('app.title.full')} isForbidden={false} noHeadlineMargin>
      <BpCard>
        <AboutBP isAdmin={!!perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())} />
      </BpCard>
    </BpPage>
  );
};
