import { Form, Formik } from 'formik';
import { Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import {
  CollaborationStatus,
  useCollaboratingOrganizationsQuery,
  useCreateCollaborationMutation,
} from '../../client/bp-graphql-client-defs';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';

type CollaborationsFormType = {
  uuid: string;
  name: string;
};

export const CollaborationsForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('COLLABORATING');
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const [, createCollaboration] = useCreateCollaborationMutation();
  const [{ data }] = useCollaboratingOrganizationsQuery({ variables: { uuid: organizationUuid }, context });
  const handleClose = () => {
    onClose();
  };

  const initialValues: CollaborationsFormType = {
    uuid: '',
    name: '',
  };

  const selectOpts = useCreateSelectOptions(
    data?.collaboratingOrganizations.filter((collab) => {
      const filteredStates = [
        CollaborationStatus.NoCollaborationPossible,
        CollaborationStatus.Pending,
        CollaborationStatus.Collaboration,
      ];
      return !filteredStates.includes(collab.collaborationStatus as CollaborationStatus);
    }),
    'uuid',
    'name',
  );

  const onSubmit = async (values: CollaborationsFormType) => {
    if (values.uuid !== '' && perms?.canCreateCollaboration({ uuid: organizationUuid })) {
      const resp = await createCollaboration({ uuid: values.uuid });

      if (resp.error) {
        showErrorToast(resp.error);
      } else {
        showSuccessToast(t('common.saved'));
        handleClose();
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ resetForm, setFieldValue, handleBlur, values, isSubmitting, errors }) => {
        return (
          <Form>
            <Select
              label={t('collaborations.cooperationSchool')}
              name={'uuid'}
              onBlur={handleBlur}
              isSearchable
              onChange={(ev) => setFieldValue('uuid', (ev as unknown as SelectOptionType).value)}
              defaultValue={{ value: values.uuid ?? '', label: values.name ?? '' }}
              options={selectOpts}
            />

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  handleClose();
                },
              }}
              submitButton={{ text: t('common.sendRequest') }}
              errors={errors}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
