import { ConversationType } from '../MatrixConversation';
import classNames from 'classnames';
import styles from '../MatrixConversation.module.scss';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useMatrixClient } from '../../../../hooks/matrix/useMatrixClient';

export type MatrixConversationItemProps = {
  chunk: ConversationType[0];
};
export const MatrixConversationItem = ({ chunk }: MatrixConversationItemProps) => {
  const matrixClient = useMatrixClient();

  useEffect(() => {
    chunk.messages.forEach((message) => {
      if (message.matrixEvent && !message.matrixEvent.isSending()) {
        matrixClient?.sendReadReceipt(message.matrixEvent);
      }
    });
  }, [matrixClient, chunk]);

  return (
    <div className={classNames(styles.chunk, chunk.isIncoming ? styles.incoming : styles.outgoing)}>
      <div className={styles.initials} style={{ color: chunk.color }}>
        {chunk.initials}
      </div>
      <div className={styles.messages}>
        {chunk.messages.map((message, index) => {
          return (
            <div key={index} className={styles.message}>
              <div className={styles.content}>{message.content}</div>
              <div className={styles.date}>{dayjs(message.date).fromNow()}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
