import { useNetworkState } from '@uidotdev/usehooks';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Modal } from '@bp/ui-components';
import styles from './App.module.scss';
import { useMatrixClient } from './hooks/matrix/useMatrixClient';
import { useTranslation } from 'react-i18next';

export const RequireBackend: FC<PropsWithChildren> = ({ children }) => {
  const network = useNetworkState();
  const { t } = useTranslation();
  const matrix = useMatrixClient();

  const slowDelay = 5000;
  const fastDelay = 2000;

  const [backendOnline, setBackendOnline] = useState(true); // be optimist
  const [modal, setModal] = useState(false);
  const [delay, setDelay] = useState(slowDelay);

  const fetchHealth = useCallback(() => {
    fetch(import.meta.env.VITE_APP_BACKEND_URI + '/_health')
      .then((resp) => {
        if (!resp.ok || !network.online) {
          setBackendOnline(false);
          setModal(true);
          setDelay(fastDelay);
          if (matrix && matrix.clientRunning) {
            matrix.stopClient();
          }
        } else {
          setBackendOnline(true);
          setDelay(slowDelay);
          setModal(false);
          if (matrix && !matrix.clientRunning) {
            matrix.startClient();
          }
        }
      })
      .catch(() => {
        if (matrix && matrix.clientRunning) {
          matrix.stopClient();
        }
        setBackendOnline(false);
        setModal(true);
        setDelay(fastDelay);
      });
  }, [network.online, matrix]);

  useEffect(() => {
    const id = setInterval(() => fetchHealth(), delay);

    return () => clearInterval(id);
  }, [fetchHealth, delay]);

  return (
    <>
      {children}
      {modal && (
        <Modal
          isOpen={modal}
          onRequestClose={() => {
            setModal(false);
          }}
          title={t('offline.connectionIssues')}
        >
          <div className={styles['offline-container']}>
            <div className={styles.message}>
              {!network.online && <div>{t('offline.clientOffline')}</div>}
              {!backendOnline && <div>{t('offline.serverOffline')}</div>}
              <div>{t('offline.autoClose')}</div>
              <div>{t('offline.unsavedData')}</div>
            </div>
            <div className={styles.status}>
              <div>
                <div>Server</div>
                <span className={backendOnline ? styles.online : styles.offline}>
                  {backendOnline ? 'Online' : 'Offline'}
                </span>
              </div>
              <div>
                <div>Client</div>
                <span className={network.online ? styles.online : styles.offline}>
                  {network.online ? 'Online' : 'Offline'}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
