export const uuidRegexHelper = (type: 'room' | 'user', searchString: string) => {
  /*
     in Matrix an id with a prefix of
    '!' is a room identifier
    '#' is a room alias - we use group uuids a such
    '@' is a  user

    a matrix room identifier:
    !gMiTHvjRiqPWNckvHP:testmatrix.dev.tks.eu

    a room alias :
    #12350000-0000-0000-00000012:testmatrix.dev.tks.eu

    a user ID ( we use the users PIM UUID ) :
    @12350000-0000-0000-00000012:testmatrix.dev.tks.eu

    we use  aliases and users here

   */

  // default room prefix
  let prefix = '#';

  switch (type) {
    case 'room':
      prefix = '#'; // room alias
      break;
    case 'user':
      prefix = '@'; // user ID
      break;
  }

  const regex = new RegExp(
    prefix + '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}):',
    'g',
  );

  return regex.exec(searchString);
};
