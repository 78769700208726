import { Suspense, useMemo, useState } from 'react';
import { LazyLoader, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { GroupSettingsForm } from '../../../components/GroupSettingsForm/GroupSettingsForm';
import { GroupType, useBpDeleteGroupMutation, useGroupAsGroupsQuery } from '../../../client/bp-graphql-client-defs';
import { GroupForm } from '../../../components/GroupForm/GroupForm';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { useNavigate } from 'react-router-dom';
import { GroupsTable, GroupsTableType } from '../../../components/GroupsTable/GroupsTable';
import dayjs from 'dayjs';
import { GroupFormOther } from '../../../components/GroupForm/GroupFormOther';
import { updateClaims } from '../../../utils/authStore';
import { usePermissionChecker } from 'hooks/usePermissionChecker';

export type GroupSettings = {
  cloudDirectory: boolean;
  asThreemaGroup: boolean;
  allowViewersCanAddMaterial: boolean;
  allowViewersCanCreateEvents: boolean;
  allowViewersCanStartMeeting: boolean;
};

export const InstitutionGroupsSubpage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useMemoizedCacheTag('GROUP');
  const [claimsTrigger, setClaimsTrigger] = useState<boolean>(false);
  const perms = usePermissionChecker();

  const organization = useAuthClaims().pimAuthClaims.getOrganization();

  const [, deleteGroup] = useBpDeleteGroupMutation();

  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [formModalOpen, setFormModalOpen] = useState<boolean>(false);
  const [formProfilesOpen, setFormProfilesOpen] = useState<boolean>(false);
  const [currentGroupUuid, setCurrentGroupUuid] = useState<string>('');

  const [{ data }] = useGroupAsGroupsQuery({
    context,
    variables: {
      where: {
        organization: {
          uuid: organization.uuid,
        },
        type: GroupType.Group,
      },
    },
  });

  const handleAdd = () => {
    setFormModalOpen(true);
    setCurrentGroupUuid('');
  };

  const handleEdit = (uuid: string) => {
    setFormModalOpen(true);
    setCurrentGroupUuid(uuid);
  };

  const handleProfilesEdit = (uuid: string) => {
    setFormProfilesOpen(true);
    setCurrentGroupUuid(uuid);
  };

  const handleDelete = async (uuid: string) => {
    await deleteGroup(
      {
        id: uuid,
      },
      context,
    );
  };

  const handleSettings = (groupUuid: string) => {
    setSettingsModalOpen(true);
    setCurrentGroupUuid(groupUuid);
  };

  const tableData: GroupsTableType[] = useMemo(() => {
    return (
      data?.groups.map((g) => ({
        uuid: g.uuid,
        organizationUuid: g.organization.uuid,
        organizationName: g.organization.displayName ?? '',
        newMessage: false,
        name: g.name,
        managedBy:
          g.managedBy !== 'bp'
            ? g.managedBy
            : (g.admins.map((admin) => admin.displayNameShort).join(', ') ?? g.managedBy),
        isCommunity: g.isCommunity,
        schoolyearName: g.schoolYear?.name ?? undefined,
        meetingInProgress: false, // TODO: get from current Events!
        admins: g.admins,
        editors: g.editors,
        viewers: g.viewers,
        externalServices: {
          threema: {
            enabled: g.externalServices?.threema?.enabled ?? false,
            lastSync: g.externalServices?.threema?.lastSync ? dayjs(g.externalServices?.threema.lastSync) : undefined,
          },
          zoom: {
            enabled: g.externalServices?.zoom?.enabled ?? false,
            lastSync: g.externalServices?.zoom?.lastSync ? dayjs(g.externalServices?.zoom.lastSync) : undefined,
          },
          nextcloud: {
            enabled: g.externalServices?.nextcloud?.enabled ?? false,
            lastSync: g.externalServices?.nextcloud?.lastSync
              ? dayjs(g.externalServices?.nextcloud.lastSync)
              : undefined,
          },
        },
      })) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, claimsTrigger]);

  const handleSettingsClose = () => {
    setSettingsModalOpen(false);
    setCurrentGroupUuid('');
  };

  return (
    <BpSubpage isForbidden={!perms?.canListInstitutionGroups(organization)}>
      {organization && (
        <GroupsTable
          data={tableData}
          onNavigate={(uuid) => navigate('/groups/' + uuid)}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onEditProfiles={handleProfilesEdit}
          onDelete={async (uuid) => handleDelete(uuid)}
          onSettings={(uuid) => handleSettings(uuid)}
        />
      )}

      <Modal
        isOpen={formModalOpen}
        onRequestClose={() => {
          setFormModalOpen(false);
          setCurrentGroupUuid('');
        }}
        title={currentGroupUuid !== '' ? t('groups.editGroup') : t('groups.addGroup')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <GroupForm
            viaSettings={true}
            canCollaborate
            groupUuid={currentGroupUuid}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(!claimsTrigger));
              setFormModalOpen(false);
              setCurrentGroupUuid('');
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        isOpen={formProfilesOpen}
        onRequestClose={() => {
          setFormProfilesOpen(false);
          setCurrentGroupUuid('');
        }}
        title={t('groups.editGroup')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
          <GroupFormOther
            groupUuid={currentGroupUuid}
            onClose={async () => {
              await updateClaims(() => setClaimsTrigger(!claimsTrigger));
              setFormProfilesOpen(false);
              setCurrentGroupUuid('');
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        title={t('groups.groupsSettings')}
        isOpen={settingsModalOpen}
        onRequestClose={handleSettingsClose}
        width='s'
      >
        <GroupSettingsForm onClose={handleSettingsClose} groupUuid={currentGroupUuid} />
      </Modal>
    </BpSubpage>
  );
};
