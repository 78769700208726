import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { useMatrixClient } from '../../hooks/matrix/useMatrixClient';
import { TextArea } from '@bp/ui-components';
import { t } from 'i18next';
import { backendApi } from '../../utils/backendApi';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { MsgType } from 'matrix-js-sdk';
import { validationSchema } from './validation/schema';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { RoomMessageEventContent } from 'matrix-js-sdk/lib/types';

export type SendMatrixMessageFormProps = {
  onClose: () => void;
  receiverProfiles: { uuid: string; displayName?: string | null }[];
};

type SendMatrixMessageType = {
  text: string;
};

export const SendMatrixMessageForm: FC<SendMatrixMessageFormProps> = ({ receiverProfiles, onClose }) => {
  const matrix = useMatrixClient();
  const [loading, setLoading] = useState(false);
  const initialValues: SendMatrixMessageType = {
    text: '',
  };

  const handleSubmit = async (values: SendMatrixMessageType) => {
    if (loading || !values.text) {
      return;
    }
    setLoading(true);

    const content: RoomMessageEventContent = {
      body: values.text,
      formatted_body: values.text,
      msgtype: MsgType.Text,
    };

    const promises = receiverProfiles.map((profile) => {
      return backendApi.createMatrixDm(profile.uuid);
    });
    const responses = await Promise.all(promises);
    const successful = responses.every((response) => response.ok);

    if (successful) {
      const sendPromises = responses.map(async (r) => {
        const json = await r.json();
        if (json.success && json.matrixRoomId) {
          const matrixRoomId = json.matrixRoomId;
          if (matrixRoomId) {
            return matrix?.sendMessage(matrixRoomId, content);
          }
        }
      });
      const sendResponses = await Promise.all(sendPromises);
      const successfulSent = sendResponses.every((response) => response && response.event_id);

      if (successfulSent) {
        showSuccessToast(t('common.success'));
      } else {
        showErrorToast(t('common.error'));
      }
    } else {
      showErrorToast(t('common.error'));
    }

    setLoading(false);
    onClose();
  };

  return (
    <Formik<SendMatrixMessageType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, errors }) => (
        <Form className={'bp__form'}>
          <TextArea
            dense
            rows={6}
            isScrollable
            className='mt-5 mb-6'
            name='text'
            onChange={handleChange}
            value={values.text}
            error={errors.text}
          />
          <ModalBottomButtons
            closeButton={{ callback: onClose }}
            isLoading={loading}
            errors={errors}
            submitButton={{ text: t('common.send') }}
          />
        </Form>
      )}
    </Formik>
  );
};
