import React, { createContext, ReactNode, useState } from 'react';

export type TeachingUnitContextType = {
  teachingUnitUuid?: string | null;
  setTeachingUnitUuid: (teachingUnitUuid: string) => void;
};

export const TeachingUnitContext = createContext<TeachingUnitContextType>({
  teachingUnitUuid: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTeachingUnitUuid: (teachingUnitUuid: string) => {},
});

export const TeachingUnitContextProvider = ({ children }: { children: ReactNode }) => {
  const [teachingUnitUuid, _setTeachingUnitUuid] = useState<string | null>(null);

  const setTeachingUnitUuid = (value: string | null) => {
    _setTeachingUnitUuid(value);
  };

  return (
    <TeachingUnitContext.Provider
      value={{
        teachingUnitUuid,
        setTeachingUnitUuid,
      }}
    >
      {children}
    </TeachingUnitContext.Provider>
  );
};
