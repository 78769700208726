import styles from './MatrixRoomList.module.scss';
import classNames from 'classnames';
import { MatrixRoomListItem } from './MatrixRoomListItem/MatrixRoomListItem';
import { ConversationType } from '../MatrixConversation/MatrixConversation';
import { useMatrixClient } from '../../../hooks/matrix/useMatrixClient';
import { EventType, MsgType, NotificationCountType } from 'matrix-js-sdk';
import { useMatrixDmRooms } from '../../../hooks/matrix/useMatrixDmRooms';

type MessagesProps = {
  onSelect: (roomId: string) => void;
  selected: string | null;
  searchValue?: string;
};

export function MatrixRoomList({ onSelect, selected, searchValue }: MessagesProps) {
  const matrixClient = useMatrixClient();
  const rooms = useMatrixDmRooms(searchValue);

  return (
    <div className={classNames(styles.messages, 'has-scrollbar')}>
      {rooms?.map((room) => {
        const roomId = room.roomId;

        const timestamp = room.getLastActiveTimestamp();
        const unread = room.getUnreadNotificationCount(NotificationCountType.Total);
        const events = room?.getLiveTimeline().getEvents();
        const converstation: ConversationType = [];
        events?.forEach((matrixEvent) => {
          if (matrixEvent.getType() === EventType.RoomMessage) {
            if (matrixEvent.getRoomId() === roomId && matrixEvent.getContent().msgtype === MsgType.Text) {
              converstation.push({
                messages: [
                  { type: '', content: matrixEvent.getContent().body, date: matrixEvent.getDate() ?? new Date() },
                ],
                isIncoming: matrixEvent.getSender() !== matrixClient?.getUserId(),
                initials: '',
                color: matrixEvent.getSender() === matrixClient?.getUserId() ? 'red' : 'blue',
                id: matrixEvent.getId() ?? '',
              });
            }
          }
        });
        const last = converstation[converstation.length - 1];

        return (
          <MatrixRoomListItem
            onClick={async (roomId) => {
              if (last) {
                await matrixClient?.setRoomReadMarkers(roomId, last.id);
                if (last.messages[last.messages.length - 1]) {
                  matrixClient?.sendReadReceipt(last.messages[last.messages.length - 1].matrixEvent ?? null);
                }
              }
              onSelect(roomId);
            }}
            isSelected={selected === roomId}
            roomId={roomId}
            lastUpdate={timestamp}
            preview={last && last.messages ? last.messages[0].content : ''}
            roomName={room.roomInformation?.displayName ?? ''}
            unread={unread}
            key={roomId}
            className={styles.message}
            badgeText={room.roomInformation?.profile?.roleName ?? ''}
          />
        );
      })}
    </div>
  );
}
