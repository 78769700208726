import { EventRecurrence } from '../client/bp-graphql-client-defs';

export const connectByUuid = (uuid: string | null | undefined) => {
  return {
    connect: {
      where: {
        node: {
          uuid: uuid,
        },
      },
    },
  };
};

export const bpProperty = (name: string, value: string) => {
  return {
    edge: {
      scope: 'bp',
    },
    node: {
      name: name,
      value: value,
    },
  };
};

export const globalProperty = (name: string, value: string) => {
  return {
    edge: {
      scope: 'global',
    },
    node: {
      name: name,
      value: value,
    },
  };
};

export const bpManaged = () => {
  return {
    edge: {
      scope: 'source',
    },
    node: {
      name: 'master',
      value: 'bp',
    },
  };
};

export const connectOrCreateByUuid = (uuid: string) => {
  return {
    connectOrCreate: {
      where: {
        node: {
          uuid,
        },
      },
      onCreate: {
        node: {
          uuid,
        },
      },
    },
  };
};

export const buildRecurrenceRule = (recurrence: string) => {
  let frequency: EventRecurrence = EventRecurrence.Daily;
  switch (recurrence) {
    case 'daily':
      frequency = EventRecurrence.Daily;
      break;
    case 'weekly':
      frequency = EventRecurrence.Weekly;
      break;
    case 'workdays':
      frequency = EventRecurrence.Workdays;
      break;
    case 'yearly':
      frequency = EventRecurrence.Yearly;
      break;
    default:
      return;
  }

  return {
    recurrenceRule: {
      create: {
        node: {
          frequency: frequency,
        },
      },
    },
  };
};
