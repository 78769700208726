import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectOptionType } from '@bp/ui-components';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { MultiValue } from 'react-select';
import styles from './GroupForm.module.scss';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useFormikContext } from 'formik';
import { ProfileSelection } from '../ProfileSelection/ProfileSelection';
import { GroupType, useBpProfileQuery, useGroupAsGroupsQuery } from '../../client/bp-graphql-client-defs';

export const GroupFormProfiles = <T extends { viewers: string[] }>() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const groupContext = useMemoizedCacheTag('GROUP');
  const profilesContext = useMemoizedCacheTag('PROFILE');
  const { setFieldValue, values, setFieldTouched } = useFormikContext<T>();
  const [filterGroups, setFilterGroups] = useState<string[] | null>(null);

  const [{ data: profilesPoolData }] = useBpProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: profilesContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    context: groupContext,
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
        type: GroupType.Group,
      },
    },
  });

  const handleSelect = (event: MultiValue<SelectOptionType>) => {
    const uuids = event.map((e) => e.value as string);
    setFilterGroups(uuids.length ? uuids : null);
  };

  const selectOpts = useCreateSelectOptions(groupsData?.groups, 'uuid', 'name');

  const courseProfiles =
    profilesPoolData?.profiles.filter((profile) => {
      return values.viewers.includes(profile.uuid);
    }) ?? [];

  const profilesFilterGroups = filterGroups
    ? (groupsData?.groups
        .filter((group) => {
          return filterGroups.includes(group.uuid);
        })
        .map((group) => {
          return group.viewers;
        })
        .flat() ?? [])
    : profilesPoolData?.profiles;

  const profilesToSelect = profilesFilterGroups?.filter((profile) => {
    return !courseProfiles.some((cp) => {
      return cp.uuid === profile.uuid;
    });
  });

  return (
    <div className={styles['profile-selector']}>
      <Select
        className={styles.select}
        label={t('groups.existingGroups')}
        name={'group'}
        isMulti
        isSearchable
        options={selectOpts}
        onChange={(event) => {
          handleSelect(event as MultiValue<SelectOptionType>);
        }}
        isClearable
        menuPosition='fixed'
      />
      <ProfileSelection
        items={
          profilesToSelect?.map((profile) => {
            return { value: profile.uuid, label: `${profile.selectName}` };
          }) ?? []
        }
        selectedItems={
          courseProfiles.map((profile) => ({
            value: profile.uuid,
            label: `${profile.selectName}`,
          })) ?? []
        }
        onSelect={(items) => {
          setFieldValue('viewers', [...values.viewers, ...items.map((i) => i.value)]);
          setFieldTouched('viewers', true);
        }}
        onRemove={(items) => {
          const uuids = items.map((i) => i.value);
          setFieldValue('viewers', [
            ...values.viewers.filter((p) => {
              return !uuids.includes(p);
            }),
          ]);
          setFieldTouched('viewers', true);
        }}
      />
    </div>
  );
};
