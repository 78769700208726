import { FC, useMemo, useState } from 'react';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { Button, CoursesIcon, EditIcon, Modal, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useAbsenceReasonsQuery } from '../../../client/bp-graphql-client-defs';
import { AbsenceReasonForm } from '../../../components/AbsenceReasonForm/AbsenceReasonForm';

export type AbsenceReason = {
  uuid: string;
  reason: string;
  onlyForRoles: string[];
  internal: boolean;
};

export const AbsenceReasonsSubpage: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');

  const [currentReason, setCurrentReason] = useState<AbsenceReason | null>(null);

  const [{ data }] = useAbsenceReasonsQuery({
    variables: { where: { organization: { uuid: organizationUuid } } },
    context,
  });
  const { t } = useTranslation();
  const perms = usePermissionChecker();

  const [modalOpen, setModalOpen] = useState(false);

  const columns: TableColumns<AbsenceReason>[] = useMemo(() => {
    return [
      {
        header: t('absenceReason.reason'),
        id: 'reason',
        accessorKey: 'reason',
        size: 450,
      },
      {
        header: t('absenceReason.onlyForRoles'),
        id: 'onlyForRoles',
        accessorKey: 'onlyForRoles',
        size: 450,
        cell: ({ row }) => row.original.onlyForRoles.join(', '),
      },
      {
        header: t('absenceReason.internal'),
        id: 'internal',
        accessorKey: 'internal',
        type: 'boolean',
        size: 75,
      },
    ];
  }, [t]);

  function getRoleTranslation(role: string) {
    switch (role) {
      case 'TEACHER':
        return t('rolesInOrganization.teacher_other');
      case 'STUDENT':
        return t('rolesInOrganization.student_other');
      case 'PARENT':
        return t('rolesInOrganization.parent_other');
      case 'OTHER':
        return t('rolesInOrganization.other');
      default:
        return '';
    }
  }

  const tableData = useMemo(() => {
    return (
      ((data?.absenceReasons ?? []) as AbsenceReason[]).map((r) => {
        return { ...r, onlyForRoles: r.onlyForRoles.map((role) => getRoleTranslation(role)) };
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function handleClose() {
    setModalOpen(false);
    setCurrentReason(null);
  }

  return (
    <BpSubpage isForbidden={!perms?.isOrganizationAdmin({ uuid: organizationUuid })}>
      <Table<AbsenceReason>
        columns={columns}
        data={tableData}
        sorting={[{ id: 'reason', desc: false }]}
        showActionBar
        isOnWhite={false}
        breakpoint='580px'
        showBorderRadius
        showShadow
        actionBarSettings={{
          showAddButton: perms?.isOrganizationAdmin({ uuid: organizationUuid }),
          addButtonText: t('common.add'),
          showExpertFilter: true,
        }}
        onAddClick={() => setModalOpen(true)}
        emptyStateSettings={{ icon: <CoursesIcon className='svg-icon primary-light' /> }}
        lastColWidth='100px'
        lastCol={(row) => {
          return (
            <Button
              hierarchy='tertiary'
              onClick={() => {
                setCurrentReason(row.original);
                setModalOpen(true);
              }}
              icon={<EditIcon />}
            ></Button>
          );
        }}
      />

      <Modal
        isOpen={modalOpen}
        onRequestClose={() => handleClose()}
        title={t('common.addType', { type: t('absenceReason.title') })}
        width='s'
      >
        <AbsenceReasonForm reason={currentReason} onClose={() => handleClose()} />
      </Modal>
    </BpSubpage>
  );
};
