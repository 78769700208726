import * as Yup from 'yup';

import i18next from 'i18next';

export const validationSchema = Yup.object().shape({
  oauthAccountId: Yup.string().required(i18next.t('validation.required.oauthAccountId')),
  oauthClientId: Yup.string().required(i18next.t('validation.required.oauthClientId')),
  oauthClientSecret: Yup.string().required(i18next.t('validation.required.oauthClientSecret')),
  validUntil: Yup.date(),
});
