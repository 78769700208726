import classNames from 'classnames';
import styles from './ProfileSelection.module.scss';
import { SelectorType } from './ProfileSelection';
import { FC } from 'react';

type ProfileSelectionElementProps = {
  item: SelectorType;
  isSelected: boolean;
  onClick: (item: SelectorType) => void;
  onDoubleClick?: (item: SelectorType) => void;
};

export const ProfileSelectionElement: FC<ProfileSelectionElementProps> = ({
  item,
  isSelected,
  onClick,
  onDoubleClick,
}) => {
  return (
    <div
      tabIndex={0}
      className={classNames([
        styles.element,
        {
          [styles.selected]: isSelected,
        },
      ])}
      onClick={(e) => {
        if (e.detail === 1) {
          onClick(item);
        } else if (onDoubleClick) {
          onDoubleClick(item);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick(item);
        }
        if (e.code === 'Space') {
          onClick(item);
        }
      }}
    >
      {item.label}
    </div>
  );
};
