import {
  Button,
  DashboardIcon,
  MeetingActiveIcon,
  MeetingDefaultIcon,
  MenuIcon,
  MessagesDefaultIcon,
  NotificationDefaultIcon,
} from '@bp/ui-components';
import { AppContext } from '../../context/AppContext';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notifications } from '../Header/components/Notifications/Notifications';
import styles from './MobileMenu.module.scss';
import classNames from 'classnames';
import { useNotifications } from '../../hooks/matrix/notifications/useNotifications';
import { useRunningPlannedMeetings } from '../../hooks/useCalendarEvents';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { MeetingsFlyout } from '../MeetingFlyout/MeetingsFlyout';

type MobileMenuItemsType = {
  id: string;
  trigger: ReactNode;
  content?: ReactNode;
  onClick: () => void;
};

type MobileMenuProps = {
  className?: string | undefined;
};

export const MobileMenu: FC<MobileMenuProps> = ({ className }) => {
  const { pimAuthClaims } = useAuthClaims();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const notifications = useNotifications();

  const { setSidebarIsOpen } = useContext(AppContext);
  const { runningMeetings } = useRunningPlannedMeetings(pimAuthClaims.getProfile().uuid);

  const [active, setActive] = useState<string | null>(null);

  useEffect(() => {
    if (location.pathname === '/') {
      setActive(active ? active : 'dashboard');
    } else if (location.pathname === '/messages') {
      setActive(active ? active : 'messages');
    } else {
      setActive(active === 'dashboard' ? null : active);
    }
  }, [location.pathname, active]);

  const items: MobileMenuItemsType[] = [
    {
      id: 'dashboard',
      trigger: (
        <div className={styles.trigger}>
          <Button hierarchy='ghost' icon={<DashboardIcon className={styles.icon} />} />
          <div className={styles.title}>{t('dashboard.title')}</div>
        </div>
      ),
      onClick: () => {
        setActive('dashboard');
        navigate('/');
      },
    },
    {
      id: 'meetings',
      trigger: (
        <div className={styles.trigger}>
          <Button
            hierarchy='ghost'
            icon={
              runningMeetings.length > 0 ? (
                <MeetingActiveIcon className={styles.icon} />
              ) : (
                <MeetingDefaultIcon className={styles.icon} />
              )
            }
          />
          <div className={styles.title}>{t('meetings.titleShort')}</div>
        </div>
      ),
      content: <MeetingsFlyout />,
      onClick: () => setActive(active === 'meetings' ? null : 'meetings'),
    },
    {
      id: 'menu',
      trigger: (
        <div className={styles.trigger}>
          <Button hierarchy='ghost' icon={<MenuIcon className={styles.icon} />} />
          <div className={styles.title}>{t('common.menu')}</div>
        </div>
      ),
      onClick: () => {
        setSidebarIsOpen(true);
      },
    },
    {
      id: 'messages',
      trigger: (
        <div className={styles.trigger}>
          <Button hierarchy='ghost' icon={<MessagesDefaultIcon className={styles.icon} />} />
          <div className={styles.title}>{t('messages.title')}</div>
        </div>
      ),
      onClick: () => {
        setActive('messages');
        navigate('/messages');
      },
    },
    {
      id: 'notifications',
      trigger: (
        <div className={styles.trigger}>
          <Button hierarchy='ghost' icon={<NotificationDefaultIcon className={styles.icon} />} />
          <div className={styles.title}>{t('notifications.titleShort')}</div>
        </div>
      ),
      content: <Notifications notifications={notifications} onClick={navigate} />,
      onClick: () => setActive(active === 'notifications' ? null : 'notifications'),
    },
  ];

  return (
    <div className={classNames(styles['mobile-menu'], className)}>
      {items.map((item) => (
        <div
          key={item.id}
          className={`${styles['button-wrapper']} ${active === item.id ? styles.active : undefined}`}
          onClick={item.onClick}
        >
          {item.trigger}
          {active === item.id && item.content && <div className={styles.content}>{item.content}</div>}
        </div>
      ))}
    </div>
  );
};
