import { EmptyState, ExclamationmarkIcon, Input, SendIcon } from '@bp/ui-components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ActivityProtocol.module.scss';
import { MatrixEvent } from 'matrix-js-sdk';
import { Activity } from '../Activity/Activity';
import {
  Submission,
  SubmissionStatus,
  useBpSubmissionsQuery,
  useBpUpdateSubmissionsMutation,
} from '../../client/bp-graphql-client-defs';
import { showErrorToast } from '../../utils/showErrorToast';
import { useActivityProtocol } from '../../hooks/matrix/useActivityProtocol';
import { useMatrixRoomMessages } from '../../hooks/matrix/useMatrixRoomMessages';
import { useGetMatrixRoomByName } from '../../hooks/matrix/useGetMatrixRoomByName';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { NotificationReasons, RoomNames } from '../../utils/matrixClient';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';

type ActivityProtocolType = {
  submissionUuid: string;
  newSubmissionContent: () => void;
};

export const ActivityProtocol: FC<ActivityProtocolType> = ({ submissionUuid, newSubmissionContent }) => {
  const { t } = useTranslation();

  const { getRoom } = useGetMatrixRoomByName();
  const room = getRoom(submissionUuid, RoomNames.Submission);
  const messages = useMatrixRoomMessages(room?.roomId ?? '').filter(
    // filter redaction aka deleted  events
    (m) => m.getUnsigned()?.redacted_because?.type !== 'm.room.redaction',
  );

  const [, setOnline] = useState(false);
  const refresh = useRefreshOnEvent();
  const matrixAvailable = useMatrixAvailable();

  useEffect(() => {
    setOnline(matrixAvailable);
  }, [matrixAvailable, refresh]);

  const { sendMessage, redactMessage } = useActivityProtocol();
  const [, updateSubmissionMutation] = useBpUpdateSubmissionsMutation();

  const submissionContext = useMemoizedCacheTag('SUBMISSION');
  const [submission] = useBpSubmissionsQuery({
    variables: { where: { uuid: submissionUuid ?? '0' } },
    context: submissionContext,
  });

  const [comment, setComment] = useState('');

  const currentSubmission = (submission.data?.submissions[0] as Submission) ?? undefined;

  const redactErrorToast = (resp: Response) => {
    showErrorToast({
      name: 'Error',
      message: resp.statusText,
      graphQLErrors: [],
    });
  };

  const grantFeedback = async (eventId: string) => {
    if (submissionUuid !== '' && room) {
      await updateSubmissionMutation({
        where: {
          uuid: submissionUuid,
        },
        update: {
          status: SubmissionStatus.Feedback,
        },
      }).catch((err) => showErrorToast(err));

      // Matrix Stuff
      const resp = await redactMessage(room.roomId, eventId);
      !resp.ok ? redactErrorToast(resp) : await sendMessage(submissionUuid, NotificationReasons.SubmissionFeedback);
    }
  };

  const rejectFeedback = async (eventId: string) => {
    if (submissionUuid && room) {
      // Matrix Stuff
      const resp = await redactMessage(room.roomId, eventId);
      !resp.ok ? redactErrorToast(resp) : await sendMessage(submissionUuid, NotificationReasons.FeedbackRejected);
    }
  };

  const publishSubmission = async (isPublic: boolean, eventId: string) => {
    if (submissionUuid !== '' && room) {
      const updateResp = await updateSubmissionMutation({
        where: {
          uuid: submissionUuid,
        },
        update: {
          public: isPublic,
        },
      }).catch((err) => {
        showErrorToast(err);
      });

      // Matrix Stuff
      if (updateResp) {
        const resp = await redactMessage(room.roomId, eventId);
        !resp.ok
          ? redactErrorToast(resp)
          : await sendMessage(
              submissionUuid,
              isPublic ? NotificationReasons.SubmissionPublished : NotificationReasons.SubmissionDePublished,
              null,
              true,
            );
      }
    }
  };

  const sendComment = async () => {
    if (room && comment) {
      await sendMessage(submissionUuid, NotificationReasons.Comment, comment);
    }
    setComment('');
  };

  return (
    <div className={styles['activity-protocol']}>
      {!matrixAvailable ? (
        <EmptyState title={t('activityProtocol.noAvailable')} icon={<ExclamationmarkIcon />} fitParent />
      ) : (
        messages.map((event: MatrixEvent) => (
          <Activity
            key={event.getId()}
            message={event}
            submission={currentSubmission}
            grantFeedback={grantFeedback}
            rejectFeedback={rejectFeedback}
            publishSubmission={publishSubmission}
            newSubmissionContent={newSubmissionContent}
          />
        ))
      )}
      <Input
        name={'comment'}
        placeholder={t('submissions.askQuestion')}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') sendComment();
        }}
        value={comment}
        suffix={
          <div className={styles['input-icon']} onClick={sendComment}>
            <SendIcon />
          </div>
        }
      />
    </div>
  );
};
