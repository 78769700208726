import { useMemo } from 'react';
import { SelectOptionType } from '@bp/ui-components';

export function useCreateSelectOptions<T, K extends keyof T>(data: T[] | undefined, value: K, label: K) {
  return useMemo(
    () =>
      data
        ? (data.map((d) => {
            return { value: d[value] ?? '', label: d[label] ?? '' };
          }) as SelectOptionType[])
        : ([] as SelectOptionType[]),
    [data, value, label],
  );
}
