export const uniqueByUuid = <T extends { uuid: string }>(array: T[]): T[] => {
  const seenUuids = new Set<string>();
  return array.filter((profile) => {
    if (!seenUuids.has(profile.uuid)) {
      seenUuids.add(profile.uuid);
      return true;
    }
    return false;
  });
};
