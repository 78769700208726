import { urqlClient } from '../urqlClient';
import {
  BpSubmissionsDocument,
  BpSubmissionsQuery,
  BpSubmissionsQueryVariables,
} from '../../client/bp-graphql-client-defs';

export const submissionLoader = (submissionUuid?: string) => {
  return urqlClient
    .query<BpSubmissionsQuery, BpSubmissionsQueryVariables>(
      BpSubmissionsDocument,
      {
        where: { uuid: submissionUuid },
      },
      { additionalTypenames: ['BP_Submission'] },
    )
    .toPromise();
};
