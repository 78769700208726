import { FC, useState } from 'react';
import { Button } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { PageLayoutPlaceholder } from '../../components/PageLayoutPlaceholder/PageLayoutPlaceholder';
import { BpSubpage } from '../../components/BpSubpage/BpSubpage';
import { AboutModal } from '../../components/Modals/AboutModal/AboutModal';

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [showAbout, setShowAbout] = useState<boolean>(false);

  const login = async () => {
    await auth.signinRedirect();
  };

  return (
    <BpSubpage>
      <PageLayoutPlaceholder>
        <Button hierarchy='primary' onClick={() => login()} fullWidth>
          {t('auth.login')}
        </Button>
        <Button hierarchy='tertiary' onClick={() => setShowAbout(true)} fullWidth>
          {t('common.about')}
        </Button>
      </PageLayoutPlaceholder>
      <AboutModal state={showAbout} setState={(value) => setShowAbout(value)} />
    </BpSubpage>
  );
};
