import { createClient, cacheExchange, errorExchange, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { clearTokens, getTokens } from './authStore';
import { UNAUTHORIZED_QUERY } from './constants';
import dayjs from 'dayjs';

let _tokens: {
  access_token?: string;
  refresh_token?: string;
  expires_at?: number;
  id_token?: string;
} | null = null;

export const urqlClient = createClient({
  url: import.meta.env.VITE_APP_BACKEND_URI + import.meta.env.VITE_APP_BACKEND_GRAPHQL_PATH || '',
  suspense: true,
  exchanges: [
    cacheExchange,
    authExchange(async (utils) => {
      return {
        addAuthToOperation(operation) {
          if (_tokens) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${_tokens.access_token}`,
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          return !_tokens;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some((e) => e.extensions?.code === UNAUTHORIZED_QUERY);
        },
        async refreshAuth() {
          _tokens = await getTokens();
          if (
            !_tokens?.access_token ||
            (_tokens?.expires_at && dayjs(_tokens?.expires_at).isSameOrBefore(dayjs().unix()))
          ) {
            clearTokens();
            _tokens = null;
          }
        },
      };
    }),
    errorExchange({
      onError: (error) => {
        const isAuthError =
          error.graphQLErrors.some((e) => e.extensions?.code === UNAUTHORIZED_QUERY) ||
          error.networkError?.message === 'Unauthorized';
        if (isAuthError) {
          clearTokens();
          window.location.href = '/';
        }
      },
    }),
    fetchExchange,
  ],
});
