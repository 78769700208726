import classNames from 'classnames';
import { EventItem } from '../EventItem/EventItem';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import styles from './EventsList.module.scss';
import { AddIcon, Button, EmptyState, NoTasksIcon, SearchInput } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useIsFirstRender } from '@uidotdev/usehooks';
import { useWindowDimensions } from 'utils/dimensions';
import { GroupType } from '../../client/bp-graphql-client-defs';

export type BpEventType = {
  uuid: string;
  type?: string;
  title: string;
  description?: string;
  start: dayjs.Dayjs;
  duration?: string;
  keywords?: string[];
  categories?: string[];
  color?: string;
  locale?: string;
  status?: string;
  until?: dayjs.Dayjs | null;
  originUuid?: string;
  originDisplayName?: string;
  originType?: 'Profile' | 'Organization' | GroupType;
  owner: boolean;
  maxAttendees?: number;
  guestsAllowed: boolean;
  attendees: { uuid: string; displayName: string; classbookComment?: string | null }[];
  classbookNote?: string;
  classbookNoteContentType?: string;
  classbookHomework?: string;
  classbookHomeworkContentType?: string;
  locationsCount: number;
  virtualLocationsCount: number;
  virtualLocations: {
    uuid: string;
    features: string[];
    running: boolean;
    token: string;
    uri: string;
    currentAttendees?: number;
    currentVideoAttendees?: number;
  }[];
};

type EventsListProps = {
  isOnWhite?: boolean;
  fullHeight?: boolean;
  showHeader?: boolean;
  showCompactEvents?: boolean;
  hideAddButton?: boolean;
  hideMonthHeadlines?: boolean;
  showActive?: boolean;
  emptyStateHeight?: string;
  events: BpEventType[];
  scrollToUuid: string | null;
  randomRedrawString?: string | null;
  onEdit?: (uuid: string) => void;
  onAddClick?: () => void;
  onEventClick?: (bpEvent: BpEventType, ref?: RefObject<HTMLDivElement>) => void;
  className?: string;
  courseUuid?: string;
};
export const EventsList = ({
  isOnWhite = false,
  fullHeight = false,
  showHeader = false,
  showCompactEvents = false,
  hideAddButton = false,
  hideMonthHeadlines = false,
  showActive = false,
  emptyStateHeight = '400px',
  events,
  scrollToUuid,
  randomRedrawString,
  onEdit,
  onAddClick,
  onEventClick,
  className,
  courseUuid,
}: EventsListProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const perms = usePermissionChecker();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { isPhone } = useWindowDimensions();

  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const isFirstRender = useIsFirstRender();

  const filteredEvents: BpEventType[] = useMemo(() => {
    return events.filter((event) => event && event.title?.toLowerCase().includes(search.toLowerCase()));
  }, [search, events]);

  const refs: Record<string, RefObject<HTMLDivElement>> = useMemo(() => {
    return filteredEvents.reduce(
      (acc, bpEvent) => {
        acc[bpEvent.uuid] = React.createRef();
        return acc;
      },
      {} as Record<string, RefObject<HTMLDivElement>>,
    );
  }, [filteredEvents]);

  useEffect(() => {
    const currentEventRef = scrollToUuid ? refs[scrollToUuid] : null;
    if (scrollRef && scrollRef.current && currentEventRef && currentEventRef.current) {
      const eventTop = currentEventRef.current.offsetTop - (showCompactEvents ? 15 : 60);
      if (isFirstRender) {
        scrollRef.current.scrollTo({ top: eventTop, behavior: 'instant' });
      } else {
        scrollRef.current.scrollTo({ top: eventTop, behavior: 'smooth' });
      }
    }
  }, [scrollToUuid, randomRedrawString, refs, isFirstRender, showCompactEvents]);

  const classes = classNames(
    styles['events-list'],
    { [styles.white]: isOnWhite, [styles.compact]: showCompactEvents },
    className,
  );

  return (
    <div className={classes}>
      {showHeader && (
        <div className={styles.header}>
          <SearchInput
            name={'search'}
            placeholder={t('common.search')}
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
          {perms?.canCreateBpCourseEvents({ uuid: courseUuid ?? '', organization: { uuid: organizationUuid } }) &&
            !hideAddButton && (
              <Button
                icon={<AddIcon />}
                hierarchy={!isPhone ? 'secondary' : 'ghost'}
                onClick={() => onAddClick && onAddClick()}
              >
                {!isPhone && t('appointments.addAppointment')}
              </Button>
            )}
        </div>
      )}
      {filteredEvents.length === 0 ? (
        <EmptyState
          title={!showCompactEvents ? t('events.noEvents') : undefined}
          subtitle={showCompactEvents ? t('events.noEvents') : undefined}
          icon={<NoTasksIcon />}
          iconColor='var(--color-primary-light)'
          fitParent
          forcedHeight={emptyStateHeight}
          size={showCompactEvents ? 'small' : 'big'}
        />
      ) : (
        <div
          className={styles['scroll-container']}
          style={{
            height: fullHeight ? `calc(100vh - 400px)` : undefined,
          }}
        >
          {!showCompactEvents && <div className={classNames(styles.shadow, styles.top)}></div>}
          <div ref={scrollRef} className={styles.events}>
            {filteredEvents.map((event, index) => {
              let date: dayjs.Dayjs | null = null;
              if (index > 0) {
                const acc = filteredEvents[index - 1];
                if (acc.start.month() !== event.start.month() || acc.start.year() !== event.start.year()) {
                  date = event.start;
                }
              } else {
                date = event.start;
              }
              return (
                <div className={styles.event} key={event.uuid}>
                  {date && !hideMonthHeadlines && (
                    <div className={styles.date}>{`${dayjs(date).format('MMMM')} ${dayjs(date).format('YYYY')}`}</div>
                  )}
                  <EventItem
                    key={event.uuid}
                    event={event}
                    isActive={showActive && scrollToUuid === event.uuid}
                    isCompact={showCompactEvents}
                    ref={refs[event.uuid]}
                    onClick={
                      onEventClick
                        ? (bpEvent) => {
                            onEventClick(bpEvent);
                          }
                        : undefined
                    }
                    onEdit={onEdit}
                  />
                </div>
              );
            })}
          </div>
          {!showCompactEvents && <div className={classNames(styles.shadow, styles.bottom)}></div>}
        </div>
      )}
    </div>
  );
};
