import { Button, Dropdown, MeetingActiveIcon, MeetingDefaultIcon } from '@bp/ui-components';
import styles from '../_Flyouts.module.scss';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useRefreshOnEvent } from '../../../../hooks/matrix/useRefreshOnEvent';
import { MeetingsFlyout } from '../../../MeetingFlyout/MeetingsFlyout';
import { useRunningPlannedMeetings } from '../../../../hooks/useCalendarEvents';

const HeaderMeetingsFlyout = () => {
  useRefreshOnEvent();

  const { pimAuthClaims } = useAuthClaims();
  const { runningMeetings } = useRunningPlannedMeetings(pimAuthClaims.getProfile().uuid);

  return (
    <Dropdown
      noPadding
      className={styles['flyout']}
      trigger={
        <Button
          hierarchy='tertiary'
          icon={runningMeetings.length > 0 ? <MeetingActiveIcon /> : <MeetingDefaultIcon />}
        />
      }
    >
      <MeetingsFlyout />
    </Dropdown>
  );
};

export { HeaderMeetingsFlyout };
