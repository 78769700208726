import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Grid, GridRow, Input } from '@bp/ui-components';
import { useBpUpdateProfileSettingsMutation } from '../../client/bp-graphql-client-defs';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { schema } from './validation/schema';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';

export const CreateUserForm = ({
  setModalClosed,
  email,
  uuid,
}: {
  setModalClosed: (close: boolean) => void;
  email?: string | null;
  uuid: string;
}) => {
  const { t } = useTranslation();

  const [, updateUser] = useBpUpdateProfileSettingsMutation();

  const formikValues = {
    uuid: uuid,
    email: email,
  };

  const context = useMemoizedCacheTag(['GROUP', 'PROFILE']);

  const handleSubmit = async (values: typeof formikValues) => {
    const resp = await updateUser(
      {
        where: { uuid: values.uuid },
        update: {
          email: values.email ?? '',
          inviteUser: !!values.email,
          frontendUri: import.meta.env.VITE_APP_REDIRECT_URI,
        },
      },
      context,
    );

    resp.error ? showErrorToast(resp.error) : showSuccessToast(t('common.saved'));
    setModalClosed(true);
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues} validationSchema={schema}>
      {({ resetForm, isSubmitting, errors, handleChange, values }) => {
        const onClose = () => {
          resetForm();
          setModalClosed(false);
        };

        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s' spacingTop='m'>
                {t('profiles.addEmailInfo')}
              </GridRow>
              <GridRow spacingTop='s'>
                <Input
                  onChange={handleChange}
                  label={t('common.email')}
                  name={'email'}
                  value={values.email}
                  type={'email'}
                  required
                />
              </GridRow>
            </Grid>

            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
