import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { FC } from 'react';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { CourseOverviewStudentContent } from './StudentContent/CourseOverviewStudentContent';
import { CourseOverviewTeacherContent } from './TeacherContent/CourseOverviewTeacherContent';
import { useParams } from 'react-router-dom';

export const CourseOverviewSubpage: FC = () => {
  const perms = usePermissionChecker();

  const { courseUuid } = useParams<{ courseUuid: string }>();

  if (!courseUuid) {
    return <></>;
  }

  return (
    <BpSubpage>
      {perms?.canCreateCourse() ? (
        <CourseOverviewTeacherContent courseUuid={courseUuid} />
      ) : (
        <CourseOverviewStudentContent courseUuid={courseUuid} />
      )}
    </BpSubpage>
  );
};
