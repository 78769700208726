import { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  EmptyState,
  NoTasksIcon,
  NotificationActiveIcon,
  NotificationDefaultIcon,
} from '@bp/ui-components';
import styles from '../_Flyouts.module.scss';
import { BpRoomType, useNotifications } from '../../../../hooks/matrix/notifications/useNotifications';
import { useMarkAsRead } from '../../../../hooks/matrix/useMarkAsRead';
import { useRefreshOnEvent } from 'hooks/matrix/useRefreshOnEvent';
import classNames from 'classnames';
import { NotificationCountType } from 'matrix-js-sdk';
import { useTranslation } from 'react-i18next';
import { useMatrixAvailable } from '../../../../hooks/matrix/useMatrixAvailable';
import { useMatrixClient } from '../../../../hooks/matrix/useMatrixClient';
import { NotificationType } from '../../../../utils/matrixClient';
import { NotificationItem } from './NotificationItem';

type NotificationsFlyoutProps = { navigate: (to: string) => void };

const NotificationsFlyout = ({ navigate }: NotificationsFlyoutProps) => {
  const rerender = useRefreshOnEvent();
  const { t } = useTranslation();
  const online = useMatrixAvailable();
  const matrixClient = useMatrixClient();

  const rooms = matrixClient?.getVisibleRooms().filter((room) => {
    const parts = room?.name.split(':')[0].split('_');
    const roomType: BpRoomType = parts[0] as BpRoomType;
    return roomType !== BpRoomType.DM;
  });

  const [hasUnread, setHasUnread] = useState<boolean>(false);

  const _hasUnread =
    rooms?.some((room) => {
      return room.getRoomUnreadNotificationCount(NotificationCountType.Total) > 0;
    }) ?? false;

  const handleNotificationClick = async (notification: NotificationType) => {
    notification.targetUrl && navigate(notification.targetUrl);
    const eventId = notification.eventId;
    const roomId = notification.roomId;
    eventId && roomId && (await matrixClient?.setRoomReadMarkers(roomId, eventId));
  };

  useEffect(() => {
    setHasUnread(_hasUnread);
  }, [_hasUnread, online, rerender]);

  const { markAsRead } = useMarkAsRead();
  const notifications = useNotifications();

  return (
    <Dropdown
      noPadding
      className={styles.flyout}
      trigger={
        <Button
          hierarchy='tertiary'
          icon={
            hasUnread ? (
              <NotificationActiveIcon className={classNames('svg-icon', styles['notifications-active'])} />
            ) : (
              <NotificationDefaultIcon />
            )
          }
        />
      }
    >
      <div className={classNames(styles['flyout-body'], styles.notifications)}>
        <div className={styles.header}>
          <div className={styles.headline}>{t('notifications.title')}</div>
          <Button
            hierarchy='ghost'
            disabled={notifications?.length === 0}
            onClick={() => {
              setHasUnread(false);
              hasUnread &&
                rooms &&
                rooms.forEach((room) => {
                  markAsRead(room);
                });
            }}
          >
            {t('common.mark')}
          </Button>
        </div>
        <div className={classNames(styles.content, 'has-scrollbar')}>
          {notifications?.length === 0 ? (
            <EmptyState
              className={styles.empty}
              forcedHeight='400px'
              fitParent
              padding='xl'
              borderRadius='none'
              title={t('notifications.noNotifications')}
              icon={<NoTasksIcon />}
              iconColor='var(--color-primary-light)'
            />
          ) : (
            notifications?.map((n) => (
              <NotificationItem key={n.eventId} notification={n} onClick={handleNotificationClick} />
            ))
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export { NotificationsFlyout };
