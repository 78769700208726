import React, { forwardRef, Ref, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import styles from './GanttFlyout.module.scss';
import { ArrowRightIcon, ArrowLeftIcon, Button, MeetingDefaultIcon, EditIcon } from '@bp/ui-components';

export type GanttFlyoutEntry = {
  uuid: string;
  title: string;
  category: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  start: Date;
  end: Date;
  recurrence: string;
  onVideoClick?: () => void;
  onEditClick?: () => void;
};

type GanttFlyoutProps = {
  side?: 'top' | 'right' | 'bottom' | 'left';
  trigger: React.ReactNode;
  entries: GanttFlyoutEntry[];
  className?: string | undefined;
};

export const GanttFlyout = forwardRef(
  ({ side = 'left', trigger, entries, className }: GanttFlyoutProps, ref: Ref<HTMLDivElement>) => {
    const isMulti = entries.length > 1;

    const [selected, setSelected] = useState<GanttFlyoutEntry | null>(isMulti ? null : entries[0]);

    const classes = classNames(styles.flyout, className);
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div className={styles.trigger}>{trigger}</div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={classes}
            ref={ref}
            sideOffset={5}
            side={side}
            onInteractOutside={() => setSelected(isMulti ? null : entries[0])}
          >
            {!selected &&
              entries.length > 1 &&
              entries.map((entry, index) => {
                return (
                  <div key={index} className={styles['entry-menu']} onClick={() => setSelected(entry)}>
                    <div className={styles['entry-header-wrapper']}>
                      <div className={styles.title}>{entry.title}</div>
                      <div className={styles.category}>{entry.category}</div>
                    </div>
                    <ArrowRightIcon className='svg-icon white' />
                  </div>
                );
              })}
            {selected && (
              <div
                className={classNames(styles['flyout-entry'], {
                  [styles['collapsed']]: !selected,
                })}
              >
                <div className={styles['entry-header']}>
                  <div className={styles['entry-header-wrapper']}>
                    <div className={styles.title}>
                      {isMulti && (
                        <div className={styles['back-button']} onClick={() => setSelected(null)}>
                          <ArrowLeftIcon className='svg-icon white small' />
                        </div>
                      )}
                      {selected.title}
                    </div>
                    <div
                      className={classNames(styles.category, {
                        [styles.multiple]: isMulti,
                      })}
                    >
                      {selected.category}
                    </div>
                  </div>
                </div>
                <div className={styles['entry-actions']}>
                  {selected.onVideoClick && (
                    <Button
                      hierarchy='ghost'
                      icon={<MeetingDefaultIcon className='svg-icon small white' />}
                      onClick={() => selected.onVideoClick}
                    >
                      Video Konferenz
                    </Button>
                  )}
                  {selected.onEditClick && (
                    <Button
                      hierarchy='ghost'
                      icon={<EditIcon className='svg-icon small white' onClick={() => selected.onEditClick} />}
                    />
                  )}
                </div>
                <div className={styles['entry-content']}>
                  {selected.description && <div className={styles.description}>{selected.description}</div>}
                  <div className={styles['entry-date']}>
                    <div className={styles.date}>{selected.startDate}</div>
                    <div className={styles.time}>{selected.startTime}</div>
                    <div className={styles.date}>{selected.endDate}</div>
                    <div className={styles.time}>{selected.endTime}</div>
                  </div>
                  {selected.recurrence && <div className={styles['entry-recurrence']}>{selected.recurrence}</div>}
                </div>
              </div>
            )}

            <DropdownMenu.Arrow className={styles.arrow} />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  },
);
GanttFlyout.displayName = 'GanttFlyout';
