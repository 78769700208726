import React, { useEffect, useState } from 'react';
import { BpMatrixClient } from './utils/matrixClient';
import { ClientEvent } from 'matrix-js-sdk/lib/client';
import { LazyLoader } from '@bp/ui-components';

type RequireMatrixProps = {
  children?: React.ReactNode;
};

export const RequireMatrix = ({ children }: RequireMatrixProps) => {
  const [matrixReady, setMatrixReady] = useState(false);
  const [isTimeoutReached, setTimeoutReached] = useState(false);
  useEffect(() => {
    if (!isTimeoutReached) {
      BpMatrixClient.instance().then((client) => {
        if (client) {
          client.once(ClientEvent.Sync, function (state, prevState, res) {
            if (state === 'PREPARED') {
              setMatrixReady(true);
            }
          });
        }
      });
    }
  }, [isTimeoutReached]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimeoutReached(true);
      setMatrixReady(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <>{matrixReady ? children : <LazyLoader embedded={false} forceHeight={'100vh'} />}</>;
};
