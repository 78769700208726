import { Form, Formik } from 'formik';
import { Grid, GridColumn, GridRow, Input, Select, SelectOptionType, Switch } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useCreateAbsenceReasonMutation, useUpdateAbsenceReasonMutation } from '../../client/bp-graphql-client-defs';
import { ProfileRoles } from '@bp/pim-auth-constants';
import styles from './AbsenceReasonForm.module.scss';
import { MultiValue } from 'react-select';
import { AbsenceReason } from '../../pages/Settings/subpages/AbsenceReasonsSubpage';

type AbsenceReasonFormType = {
  uuid?: string | null;
  reason: string;
  onlyForRoles: string[];
  internal: boolean;
};

export const AbsenceReasonForm = ({ reason, onClose }: { reason: AbsenceReason | null; onClose: () => void }) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [, createReason] = useCreateAbsenceReasonMutation();
  const [, updateReason] = useUpdateAbsenceReasonMutation();

  const initialValues: AbsenceReasonFormType = {
    uuid: reason?.uuid,
    reason: reason?.reason ?? '',
    onlyForRoles: reason?.onlyForRoles ?? [],
    internal: reason?.internal ?? false,
  };

  function getRoleTranslation(role: ProfileRoles) {
    switch (role) {
      case ProfileRoles.Teacher:
        return t('rolesInOrganization.teacher_other');
      case ProfileRoles.Student:
        return t('rolesInOrganization.student_other');
      case ProfileRoles.Parent:
        return t('rolesInOrganization.parent_other');
      case ProfileRoles.Other:
        return t('rolesInOrganization.other');
      default:
        return '';
    }
  }

  const selectOpts = [ProfileRoles.Teacher, ProfileRoles.Student, ProfileRoles.Parent, ProfileRoles.Other].map(
    (r) =>
      ({
        value: r,
        label: getRoleTranslation(r),
      }) as SelectOptionType,
  );

  const selectedRoles = selectOpts.filter((opt) => {
    return initialValues.onlyForRoles?.includes(opt.label as string);
  });

  const onSubmit = async (values: AbsenceReasonFormType) => {
    if (!perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())) {
      onClose();
    }
    if (values.uuid === null || values.uuid === undefined) {
      await createReason(
        {
          input: [{ ...values, organization: { connect: { where: { node: { uuid: organizationUuid } } } } }],
        },
        context,
      );
    } else {
      await updateReason(
        {
          where: { uuid: values.uuid },
          update: { reason: values.reason, internal: values.internal, onlyForRoles: values.onlyForRoles },
        },
        context,
      );
    }
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ resetForm, setFieldValue, handleChange, handleBlur, values, isSubmitting, errors }) => {
        return (
          <Form className={styles['absence-reason-form']}>
            <Grid useFormGap>
              <GridRow spacingBottom='none'>
                <Input
                  label={t('absenceReason.reason')}
                  onChange={handleChange}
                  name={'reason'}
                  value={values.reason}
                  type={'text'}
                  error={errors.reason}
                />
              </GridRow>
              <GridRow spacingTop='none'>
                <GridColumn width={8}>
                  <Select
                    label={t('absenceReason.onlyForRoles')}
                    name='onlyForRoles'
                    isMulti
                    isClearable
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        'onlyForRoles',
                        (e as MultiValue<SelectOptionType>).map((e) => e.value),
                      );
                    }}
                    value={selectedRoles}
                    options={selectOpts}
                    menuPosition='fixed'
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <div className={styles['internal-switch']}>
                    <label htmlFor='internal'>{t('absenceReason.internal')}</label>
                    <Switch name='internal' onChange={handleChange} checked={values.internal} />
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  onClose();
                },
              }}
              submitButton={{ text: t('common.save') }}
              errors={errors}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
