import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { DatePicker, Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import * as Yup from 'yup';
import { useCreateBpSchoolyearMutation, useUpdateBpSchoolyearMutation } from '../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import dayjs from 'dayjs';
import { SchoolyearData } from '../../pages/Institution/subpages/InstitutionSchoolyearsSubpage';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { isAfter, isBefore } from 'utils/dateCalculations';

type SchoolyearFormType = {
  name: string;
  begin: dayjs.Dayjs;
  end: dayjs.Dayjs;
  organization?: string;
};

type SchoolyearFormProps = { onClose: () => void; schoolyear?: SchoolyearData };

export const SchoolyearForm = ({ onClose, schoolyear }: SchoolyearFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const perms = usePermissionChecker();
  const context = useMemoizedCacheTag('GROUP');
  const [, updateSchoolyear] = useUpdateBpSchoolyearMutation();
  const [, createSchoolyear] = useCreateBpSchoolyearMutation();

  const formikValues: SchoolyearFormType = {
    name: schoolyear?.name ?? '',
    begin: schoolyear?.start !== '' ? dayjs(schoolyear?.start) : dayjs(),
    end: schoolyear?.end && schoolyear?.end !== '' ? dayjs(schoolyear?.end) : dayjs().add(1, 'year'),
  };

  const handleSubmit = async (values: typeof formikValues) => {
    if (schoolyear && perms?.canUpdateSchoolyear({ uuid: organizationUuid })) {
      const { error } = await updateSchoolyear({
        update: {
          name: values.name,
          start: values.begin.toISOString(),
          end: values.end.toISOString(),
        },
        uuid: schoolyear?.uuid,
      });

      error ? showErrorToast(error) : showSuccessToast(t('schoolyears.saved'));
    }

    if (!schoolyear && perms?.canCreateSchoolyear({ uuid: organizationUuid })) {
      const { error } = await createSchoolyear(
        {
          input: {
            name: values.name,
            end: values.end.toString(),
            start: values.begin.toString(),
            organization: organizationUuid,
          },
        },
        context,
      );

      error ? showErrorToast(error) : showSuccessToast(t('schoolyears.saved'));
    }

    onClose();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required.designation')).min(2, t('validation.textTooShort')),
  });

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues} validationSchema={validationSchema}>
      {({ resetForm, isSubmitting, errors, handleChange, values, setFieldValue, setFieldTouched, setFieldError }) => {
        const onClick = () => {
          resetForm();
          onClose();
        };

        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <Input
                  label={t('common.designation')}
                  onChange={handleChange}
                  name='name'
                  value={values.name}
                  error={errors.name}
                />
              </GridRow>
              <GridRow spacingTop='s'>
                <GridColumn width={6}>
                  <DatePicker
                    className='mb-2'
                    label={t('common.start')}
                    onChange={(e) => {
                      if (isAfter(e, values.end)) {
                        setFieldError('begin', t('validation.startBeforeEnd'));
                      } else {
                        setFieldValue('begin', dayjs(e));
                        setFieldTouched('begin', true);
                      }
                    }}
                    error={errors.begin as string}
                    name='begin'
                    value={values.begin.toDate()}
                    showMonthYearDropdown
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <DatePicker
                    className='mb-2'
                    label={t('common.end')}
                    onChange={(e) => {
                      if (isBefore(e, values.end)) {
                        setFieldError('end', t('validation.endBeforeStart'));
                      } else {
                        setFieldValue('end', dayjs(e));
                        setFieldTouched('end', true);
                      }
                    }}
                    error={errors.end as string}
                    name='end'
                    value={values.end.toDate()}
                    showMonthYearDropdown
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <ModalBottomButtons closeButton={{ callback: onClick }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
