import { Checkbox, DatePicker } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { isAfter, isBefore } from '../../utils/dateCalculations';
import { AssignmentFormType } from '../AssignmentForm/AssignmentForm';

type DateSelectProps = {
  isModal?: boolean;
};

export const DatesSelect: FC<DateSelectProps> = ({ isModal }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, setFieldTouched } = useFormikContext<AssignmentFormType>();

  const onVisibleFromChange = (date: string) => {
    if (isAfter(date, values.dueDate)) onDueDateChange(date);
    setFieldValue('visibleFrom', new Date(date));
    setFieldTouched('visibleFrom');
  };

  const onDueDateChange = (date: string) => {
    if (isBefore(date, values.visibleFrom)) onVisibleFromChange(date);
    setFieldValue('dueDate', new Date(date));
    setFieldTouched('dueDate');
  };

  const onCheckboxChange = () => {
    const prev = values.allowSubmissionAfterDueDate;
    setFieldValue('allowSubmissionAfterDueDate', !prev);
    setFieldTouched('allowSubmissionAfterDueDate');
  };

  return (
    <BpCard isEmbedded={isModal} header={{ headline: t('common.due_noun') }}>
      <DatePicker
        name={'visibleFrom'}
        dateFormat='dd.MM.yyyy'
        label={t('common.visibleFrom')}
        onChange={(e) => onVisibleFromChange(e ? e.toISOString() : '')}
        value={new Date(values.visibleFrom)}
        showMonthYearDropdown
      />
      <DatePicker
        className='mt-2'
        name={'dueDate'}
        dateFormat='dd.MM.yyyy'
        label={t('common.dueDate')}
        onChange={(e) => onDueDateChange(e ? e.toISOString() : '')}
        value={new Date(values.dueDate)}
        showMonthYearDropdown
      />
      <Checkbox
        className='mt-2'
        label={t('submissions.allowAfterDue')}
        name='allowSubmissionAfterDueDate'
        checked={values.allowSubmissionAfterDueDate ?? false}
        onChange={onCheckboxChange}
      />
    </BpCard>
  );
};
