import { AddIcon, CheckIcon, Grid, GridColumn, GridRow, Modal, SpinnerIcon } from '@bp/ui-components';
import {
  SubmissionStatus,
  useBpGetAssignmentsByGroupQuery,
  useGroupAsCourseQuery,
  UserStatus,
} from '../../../../client/bp-graphql-client-defs';
import { BpCalendar } from '../../../../components/BpCalendar/BpCalendar';
import { BpCard } from '../../../../components/BpCard/BpCard';
import { MeetingsLinks } from '../../../../components/MeetingsLinks/MeetingsLinks';
import { TeachingUnitsTree } from '../../../../components/TeachingUnitsTree/TeachingUnitsTree';
import { usePermissionChecker } from '../../../../hooks/usePermissionChecker';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useCourseCalendarEvents, useRunningPlannedMeetings } from '../../../../hooks/useCalendarEvents';
import { AnnouncementsList } from '../../../../components/AnnouncementsList/AnnouncementsList';
import { useGetMatrixRoomByName } from '../../../../hooks/matrix/useGetMatrixRoomByName';
import { RoomNames } from '../../../../utils/matrixClient';
import { AnnouncementForm } from '../../../../components/AnnouncementForm/AnnouncementForm';
import { useGetAnnouncements } from '../../../../hooks/matrix/useGetAnnouncements';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import {
  ActiveAssignmentsTable,
  ActiveAssignmentsTableType,
} from '../../../../components/ActiveAssignmentsTable/ActiveAssignmentsTable';
import { uniqueByUuid } from '../../../../utils/uniquByUuid';
import { SendMatrixMessageForm } from '../../../../components/SendMatrixMessageForm/SendMatrixMessageForm';
import { TeachingUnitContext } from '../../../../context/TeachingUnitContext';
import dayjs from 'dayjs';
import { NotificationCountType } from 'matrix-js-sdk';
import { useMarkAsRead } from '../../../../hooks/matrix/useMarkAsRead';
import { GroupType } from '@bp/bp-graphql-types';
import { hasBlockingEvents } from '../../../../utils/hasBlockingEvents';
import { useAvailableServicesForOrganization } from '../../../../hooks/useAvailableServicesForOrganization';

type CourseOverviewTeacherContentProps = {
  courseUuid: string;
};

export const CourseOverviewTeacherContent = ({ courseUuid }: CourseOverviewTeacherContentProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const perms = usePermissionChecker();

  const [announcmentModal, setAnnouncmentModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const { getRoom } = useGetMatrixRoomByName();
  const { markAsRead } = useMarkAsRead();

  const assigmentContext = useMemoizedCacheTag('ASSIGNMENT');
  const courseContext = useMemoizedCacheTag('COURSE');
  const navigate = useNavigate();

  const [{ data: courseData }] = useGroupAsCourseQuery({
    variables: { where: { uuid: courseUuid ?? '', type: GroupType.Course } },
    context: courseContext,
  });

  const currentCourse = useMemo(() => {
    return courseData?.groups[0];
  }, [courseData]);

  const [{ data, error }] = useBpGetAssignmentsByGroupQuery({
    context: assigmentContext,
    variables: { where: { uuid: courseUuid } },
  });

  const room = getRoom(`${currentCourse?.organization.uuid}_${courseUuid}`, RoomNames.Group);
  const _hasUnread = room ? room.getRoomUnreadNotificationCount(NotificationCountType.Total) > 0 : false;

  const [hasUnread, setHasUnread] = useState<boolean>(false);

  useEffect(() => {
    setHasUnread(_hasUnread);
  }, [_hasUnread]);

  const { runningMeetings } = useRunningPlannedMeetings(pimAuthClaims.getProfile().uuid);

  const announcements = useGetAnnouncements(room);
  const events = useCourseCalendarEvents(courseUuid ?? '');

  const { teachingUnitUuid, setTeachingUnitUuid } = useContext(TeachingUnitContext);

  const groupSubject = { uuid: courseUuid ?? '', organization: { uuid: currentCourse?.organization.uuid ?? '' } };
  const availableServices = useAvailableServicesForOrganization(groupSubject.organization.uuid);

  const viewers = currentCourse?.viewers ?? [];
  const receiverProfiles = uniqueByUuid(
    viewers?.filter((profile) => {
      return (
        profile.uuid !== pimAuthClaims.getProfile().uuid &&
        (profile.userStatus === UserStatus.Full || profile.userStatus === UserStatus.ImpersonateOnly)
      );
    }),
  );

  const assignments: ActiveAssignmentsTableType[] = !error
    ? (data?.groups[0]?.teachingUnits
        ?.flatMap(({ assignments }) => assignments ?? [])
        .filter(
          (a) =>
            dayjs(a.dueDate?.date).isSameOrAfter(dayjs(), 'day') &&
            dayjs(a.visibleFrom?.date).isSameOrBefore(dayjs(), 'day'),
        )
        .map((assignment) => {
          return {
            title: assignment.title,
            uuid: assignment.uuid,
            openSubmissionsCount: assignment.submissions.filter((s) => {
              return s.status !== SubmissionStatus.New && s.status !== SubmissionStatus.Done;
            }).length,
          };
        }) ?? [])
    : [];

  error && showErrorToast(error);

  return (
    <>
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={8}>
            <BpCard
              className='mb-7'
              noPadding
              fixedHeight='360px'
              header={{ headline: t('assignments.active'), subHeadline: assignments?.length.toString() }}
            >
              <ActiveAssignmentsTable
                data={assignments}
                onClick={(uuid) => navigate(`/courses/${courseUuid}/assignments/${uuid}`)}
              />
            </BpCard>
            <BpCard
              canScroll
              fixedHeight='360px'
              noPadding
              hideActionTextBreakpoint='medium'
              header={{
                headline: t('announcements.title'),
                actions:
                  perms?.canCreateCourseAnnouncement(groupSubject) && room
                    ? [
                        {
                          text: t('common.mark'),
                          hierarchy: 'ghost',
                          disabled: !hasUnread,
                          icon: <CheckIcon />,
                          callback: async () => {
                            setHasUnread(false);
                            await markAsRead(room);
                          },
                        },
                        {
                          text: t('announcements.new'),
                          icon: <AddIcon />,
                          callback: () => {
                            setAnnouncmentModal(true);
                          },
                        },
                      ]
                    : [
                        {
                          text: t('announcements.new'),
                          icon: <AddIcon />,
                          disabled: true,
                        },
                      ],
              }}
            >
              <AnnouncementsList
                announcements={announcements}
                hasPermissionToDelete={perms?.canCreateCourseAnnouncement(groupSubject) ?? false}
              />
            </BpCard>
          </GridColumn>
          <GridColumn width={4}>
            <BpCard header={{ headline: t('teachingUnits.title') }} className='mb-7' alwaysCollapsible>
              <TeachingUnitsTree
                courseUuid={courseUuid}
                activeTeachingUnitUuid={teachingUnitUuid}
                onTeachingUnitSelect={(uuid: string) => {
                  setTeachingUnitUuid(uuid);
                  navigate(`/courses/${courseUuid}/lessons/${uuid}`);
                }}
              />
            </BpCard>
            <BpCard className='pb-6 pr-6 pl-6 mb-7' noPadding showBorder hideBackground>
              <BpCalendar
                events={events}
                showAddButton={!!courseUuid}
                onAdd={() => navigate(`/courses/${courseUuid}/appointments/create`)}
                eventClick={() => navigate(`/courses/${courseUuid}/appointments`)}
              />
            </BpCard>
            <MeetingsLinks
              groupUuid={courseUuid}
              availableServices={availableServices}
              hasBlockingEvents={hasBlockingEvents(events)}
              onLessonClick={() => navigate('/meetings/planned')}
              onMessageClick={() => setMessageModal(true)}
              runningEvent={runningMeetings.find((m) => m.originUuid === courseUuid)}
            />
          </GridColumn>
        </GridRow>
      </Grid>

      {room && (
        <Modal
          isOpen={announcmentModal}
          onRequestClose={() => setAnnouncmentModal(false)}
          isFormModal
          hideFooter
          title={t('announcements.new')}
          width='s'
        >
          <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
            <AnnouncementForm onClose={() => setAnnouncmentModal(false)} matrixRoomId={room.roomId} />
          </Suspense>
        </Modal>
      )}

      {receiverProfiles && (
        <Modal
          isOpen={messageModal}
          onRequestClose={() => setMessageModal(false)}
          isFormModal
          hideFooter
          width={'s'}
          title={t('common.sendToAllCourseViewers')}
        >
          <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
            <SendMatrixMessageForm onClose={() => setMessageModal(false)} receiverProfiles={receiverProfiles} />
          </Suspense>
        </Modal>
      )}
    </>
  );
};
