import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { FC, useState } from 'react';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { Button, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { getTokens } from '../../../utils/authStore';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { BpCard } from '../../../components/BpCard/BpCard';
import { restApiPath } from '../../../utils/backendApi';

export const InstitutionAdminSubpage: FC = () => {
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();

  const [returnLog, setReturnLog] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);

  const callActionAndHandleResult = async (url: string) => {
    setRunning(true);
    const tokens = await getTokens();
    const result = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${tokens?.access_token}` },
    });

    setReturnLog(JSON.stringify(await result.json(), null, 2));
    setRunning(false);
  };

  async function handleCleanupClick() {
    await callActionAndHandleResult(`${restApiPath()}/cleanup/${pimAuthClaims.getOrganizationUuid()}`);
  }

  async function handleSubmissionCleanupClick() {
    await callActionAndHandleResult(`${restApiPath()}/cleanup/${pimAuthClaims.getOrganizationUuid()}/submissions`);
  }

  async function handleMatrixCleanupClick() {
    await callActionAndHandleResult(`${restApiPath()}/cleanup/${pimAuthClaims.getOrganizationUuid()}/matrix`);
  }

  async function handleThreemaSetupClick() {
    await callActionAndHandleResult(`${restApiPath()}/setup/${pimAuthClaims.getOrganizationUuid()}/threema`);
  }

  async function handleGraphCleanupClick() {
    await callActionAndHandleResult(`${restApiPath()}/cleanup/${pimAuthClaims.getOrganizationUuid()}/graph`);
  }

  async function handlePimCleanupClick() {
    await callActionAndHandleResult(`${restApiPath()}/cleanup/${pimAuthClaims.getOrganizationUuid()}/pim`);
  }

  async function handleVpoClick() {
    await callActionAndHandleResult(`${restApiPath()}/import/vpo`);
  }

  async function handleProcuratClick() {
    await callActionAndHandleResult(`${restApiPath()}/import/procurat`);
  }

  if (!perms?.isOmniAdmin()) {
    return <></>;
  }

  return (
    <BpSubpage>
      <Grid>
        <GridRow>
          <GridColumn width={4}>
            <Button onClick={() => handleCleanupClick()} isLoading={running} disabled={running} fullWidth>
              Lösche alle Submissions, Assignments ohne Bezug zu einer TeachingUnit
            </Button>
            <br />
            <Button onClick={() => handleSubmissionCleanupClick()} isLoading={running} disabled={running} fullWidth>
              Submissions aufräumen
            </Button>
            <br />
            <Button onClick={() => handleMatrixCleanupClick()} isLoading={running} disabled={running} fullWidth>
              Matrix aufräumen
            </Button>
            <br />
            <Button onClick={() => handleThreemaSetupClick()} isLoading={running} disabled={running} fullWidth>
              Threema einrichten
            </Button>
            <br />
            <Button onClick={() => handleGraphCleanupClick()} isLoading={running} disabled={running} fullWidth>
              Neo4j aufräumen
            </Button>
            <Button onClick={() => handlePimCleanupClick()} isLoading={running} disabled={running} fullWidth>
              PIM aufräumen
            </Button>
            <br />
            <Button onClick={() => handleVpoClick()} isLoading={running} disabled={running} fullWidth>
              VPO Test
            </Button>
            <br />
            <Button onClick={() => handleProcuratClick()} isLoading={running} disabled={running} fullWidth>
              Procurat Test
            </Button>
          </GridColumn>
          <GridColumn width={8}>
            <BpCard header={{ headline: 'Ergebnis' }} canScroll={true}>
              <pre>{returnLog}</pre>
            </BpCard>
          </GridColumn>
        </GridRow>
      </Grid>
    </BpSubpage>
  );
};
