import { urqlClient } from '../urqlClient';
import {
  BpTeachingUnitsDocument,
  BpTeachingUnitsQuery,
  BpTeachingUnitsQueryVariables,
} from '../../client/bp-graphql-client-defs';
import { getCacheTag } from 'hooks/useMemoizedCacheTag';

export const teachingUnitLoader = async (teachingUnitUuid?: string, courseUuid?: string) => {
  const { data } = await urqlClient
    .query<
      BpTeachingUnitsQuery,
      BpTeachingUnitsQueryVariables
    >(BpTeachingUnitsDocument, { teachingUnitsWhere: { group: { uuid: courseUuid } } }, getCacheTag('TEACHING_UNIT'))
    .toPromise();
  return {
    data,
    teachingUnitUuid,
  };
};
