export type TMimeTypes = { [key: string]: string };

//TODO: extend and translate

const mimeTypes: TMimeTypes = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/bmp': 'BMP',
  'image/svg+xml': 'SVG',
  'application/json': 'JSON',
  'text/plain': 'Text',
  'text/javascript': 'JavaScript',
  'text/csv': 'CSV',
  'text/html': 'HTML',
  'application/msword': 'Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
  'application/vnd.oasis.opendocument.spreadsheet': 'Dokument',
  'application/vnd.oasis.opendocument.presentation': 'Präsentation',
  'application/x-yaml': 'YAML',
  'application/pdf': 'PDF',
  'application/gzip': 'GZIP',
  'audio/mpeg': 'MP3',
  'video/mp4': 'MP4',
  'video/mpeg': 'MPEG',
  'video/x-msvideo': 'Video',
};

export { mimeTypes };
