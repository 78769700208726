import { useEffect, useState } from 'react';
import styles from './MatrixChat.module.scss';
import { EmptyState, ExclamationmarkIcon, Input, SearchIcon } from '@bp/ui-components';
import { MatrixRoomList } from './MatrixRoomList/MatrixRoomList';
import { MatrixConversation } from './MatrixConversation/MatrixConversation';
import { useTranslation } from 'react-i18next';
import { MsgType } from 'matrix-js-sdk';
import { useMatrixClient } from '../../hooks/matrix/useMatrixClient';
import { useMatrixAvailable } from '../../hooks/matrix/useMatrixAvailable';
import { useMatrixDmRooms } from '../../hooks/matrix/useMatrixDmRooms';
import { useMatrixNameResolver } from '../../hooks/matrix/useMatrixNameResolver';
import { BpCard } from '../BpCard/BpCard';
import { useRefreshOnEvent } from '../../hooks/matrix/useRefreshOnEvent';
import { RoomMessageEventContent } from 'matrix-js-sdk/lib/types';

type MatrixChatProps = {
  matrixRoomId?: string;
};

export const MatrixChat = ({ matrixRoomId }: MatrixChatProps) => {
  const { t } = useTranslation();
  const matrixAvailable = useMatrixAvailable();
  const matrixClient = useMatrixClient();
  const [search, setSearch] = useState<string>('');
  const [roomId, setRoomId] = useState<string | null>(null);
  const { resolveRoomName } = useMatrixNameResolver();
  const rooms = useMatrixDmRooms();

  const currentRoom = roomId ? rooms?.find((room) => room.roomId === roomId) : null;
  const roomInformation = currentRoom ? resolveRoomName(currentRoom?.name) : '';

  useEffect(() => {
    if (matrixRoomId) {
      setRoomId(matrixRoomId);
    }
  }, [matrixRoomId]);

  useRefreshOnEvent();

  const handleSend = async (roomId: string, input: string) => {
    if (roomId) {
      const content: RoomMessageEventContent = {
        body: input,
        msgtype: MsgType.Text,
      };
      await matrixClient?.sendMessage(roomId, content);
    }
  };

  return (
    <div className={styles.messenger}>
      {!matrixAvailable ? (
        <BpCard fitParent noPadding>
          <EmptyState
            title={t('matrix.noAvailable')}
            icon={<ExclamationmarkIcon className='svg-icon error' />}
            fitParent
            forcedHeight='75vh'
          />
        </BpCard>
      ) : (
        <BpCard fitParent noPadding canScroll={false}>
          <div className={styles.wrapper}>
            <div className={styles['side-bar']}>
              <div className={styles.search}>
                <Input
                  className={styles.input}
                  onChange={(event) => setSearch(event.target.value)}
                  value={search}
                  name='search'
                  prefix={<SearchIcon />}
                  dense
                  placeholder={t('messages.searchPerson')}
                />
              </div>
              <div className={styles.messages}>
                <MatrixRoomList onSelect={(roomId) => setRoomId(roomId)} selected={roomId} searchValue={search} />
              </div>
            </div>
            <div className={styles.chat}>
              {roomInformation && (
                <div className={styles.room}>{t('messages.chatWith', { name: roomInformation.displayName })}</div>
              )}
              <div className={styles.conversation}>
                <MatrixConversation selectedRoom={currentRoom?.roomId} onSend={handleSend} />
              </div>
            </div>
          </div>
        </BpCard>
      )}
    </div>
  );
};
