import { Component, ErrorInfo } from 'react';
import styles from './ErrorBoundary.module.scss';
import { BpCard } from './components/BpCard/BpCard';
import { BpHeader } from './components/BpHeader/BpHeader';
import { useTranslation } from 'react-i18next';

const ErrorView = ({ error, errorInfo }: { error: Error | null; errorInfo: ErrorInfo | null }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles['card-wrap']}>
        <BpCard isBig={true} header={{ headline: t('errors.common'), showDivider: true }}>
          <BpHeader headline={t('errors.common')} />
          <details>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
          <div className={styles['link-login']}>
            Return to <a href='/login'>Login</a>
          </div>
        </BpCard>
      </div>
    </div>
  );
};

export class ErrorBoundary extends Component<
  { error?: Error; errorInfo?: ErrorInfo; children: JSX.Element },
  { error: Error | null; errorInfo: ErrorInfo | null }
> {
  // because of: Type 'new (props: never) => ErrorBoundary' is not assignable to type 'new (props: any, deprecatedLegacyContext?: any) => Component<any, any, any>'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render(): JSX.Element {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      // Error path
      return <ErrorView {...{ error, errorInfo }} />;
    }
    return <>{this.props.children}</>;
  }
}
