import { isString } from 'formik';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { t } from 'i18next';
import { AppointmentFormValues } from '../components/Appointments/types';

type UniversalDate = string | Date | Dayjs | undefined | null;

export type DayMonthYear = {
  name: string;
  day: number;
  month: number;
  year: number;
};

const getDaytimeGreeting = (name: string, withFirstname = true): string => {
  const currentHour = new Date().getHours();
  let greeting;
  if (currentHour >= 0 && currentHour <= 12) {
    greeting = t('dates.greetings.morning');
  } else if (currentHour >= 13 && currentHour <= 18) {
    greeting = t('dates.greetings.noon');
  } else {
    greeting = t('dates.greetings.evening');
  }
  if (name !== '' || withFirstname) {
    greeting = greeting + ` ${name}`;
  }
  return greeting;
};

const niceDate = (
  date: string | unknown | Dayjs,
  dateStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
  timeStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
) => {
  if (isString(date)) {
    const event = new Date(date);

    if (dateStyle && timeStyle) {
      return event.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? event.toLocaleString('de-DE', { dateStyle: dateStyle }) : event.toLocaleString('de-DE');
  } else if (date instanceof Date) {
    if (dateStyle && timeStyle) {
      return date.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? date.toLocaleString('de-DE', { dateStyle: dateStyle }) : date.toLocaleString('de-DE');
  }
};

const calculateDateOffset = (date: Date): string => {
  const time = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  const day = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const diff = new Date().valueOf() - date.valueOf();
  if (diff < 1000) {
    return t('dates.now');
  }

  const mins = Math.floor(diff / 60000);
  if (mins < 60) {
    return t('dates.minutesAgo', { count: mins });
  }

  const hours = Math.floor(diff / 3600000);
  if (hours < 24) {
    return t('dates.hoursAgo', {
      count: hours,
    });
  }

  const days = Math.floor(diff / 86400000);
  if (days <= 1) {
    return t('dates.yesterdayAgo', { time: time });
  } else {
    return day + ', ' + time;
  }
};

const dateToDayMonthYear = (date: Dayjs, short = false): DayMonthYear => {
  return {
    name: date.format(short ? 'dd' : 'dddd'),
    day: date.date(),
    month: date.month() + 1,
    year: date.year(),
  };
};

const dayjsToDayMonthYear = (date: Dayjs, short = false): DayMonthYear => {
  return dateToDayMonthYear(date, short);
};

const compareDayMonthYear = (a: DayMonthYear, b: DayMonthYear) => {
  return a.day === b.day && a.month === b.month && a.year === b.year;
};

const findClosestEventToNow = (events: { start: dayjs.Dayjs; uuid: string }[]) => {
  const today = new Date();
  if (events.length === 0) {
    return undefined;
  }
  return events.reduce((closest, current) => {
    const closestDiff = Math.abs(closest.start.toDate().getTime() - today.getTime());
    const currentDiff = Math.abs(current.start.toDate().getTime() - today.getTime());

    return currentDiff < closestDiff ? current : closest;
  }, events[0]);
};

const getTimespan = (a: Dayjs, b: Dayjs): DayMonthYear[] => {
  const days: Dayjs[] = [];

  const diff = dayjs.duration(b.diff(a)).asDays();
  for (let i = 0; i < diff + 1; i++) {
    days.push(a.add(i, 'day'));
  }
  return days.map((day) => {
    return dayjsToDayMonthYear(day);
  });
};

const isBefore = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isBefore(dayjs(b).startOf(unit), unit);
};

const isAfter = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isAfter(dayjs(b).startOf(unit), unit);
};

const isSameOrAfter = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrAfter(dayjs(b).startOf(unit), unit);
};

const isSameOrBefore = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrAfter(dayjs(b).startOf(unit), unit);
};

const isSame = (a: UniversalDate, b: UniversalDate): boolean => {
  return parseInt(dayjs.duration(dayjs(b).diff(a)).asDays().toFixed()) === 0;
};

const isBetween = (
  date: UniversalDate,
  a: UniversalDate,
  b: UniversalDate,
  unit: OpUnitType = 'day',
  d: '()' | '[]' | '[)' | '(]' = '[]',
): boolean => {
  return dayjs(date).startOf(unit).isBetween(dayjs(a).startOf(unit), dayjs(b).startOf(unit), unit, d);
};

const getDaysDifference = (a: UniversalDate, b: UniversalDate, including = true): number => {
  const diff = dayjs(b).startOf('day').diff(dayjs(a).startOf('day'), 'days');
  return including ? diff + 1 : diff;
};

const ensureDate = (possibleDateString: string | null) => {
  if (typeof possibleDateString === 'string' && possibleDateString.length > 0) return new Date(possibleDateString);
  return new Date();
};

const eventColors = (categories: string[], isBirthday?: boolean) => {
  if (isBirthday) {
    return 'purple';
  }
  if (categories.includes('birthday')) {
    return 'purple';
  }
  if (categories.includes('lesson')) {
    return 'green';
  }
  if (categories.includes('exam')) {
    return 'red';
  }
  return 'grey';
};

const buildRecurrences = (inputData: AppointmentFormValues): Dayjs[] => {
  const { startTime: start, until } = inputData;

  switch (inputData.recurrence) {
    case 'daily':
      return dayjs().recur({ start, end: until }).every(1, 'days').all();

    case 'weekly':
      return dayjs().recur({ start, end: until }).every(1, 'week').all();

    case 'monthly':
      return dayjs().recur({ start, end: until }).every(1, 'month').all();

    case 'yearly':
      return dayjs().recur({ start, end: until }).every(1, 'years').all();

    case 'workdays':
      return dayjs().recur({ start, end: until }).every([1, 2, 3, 4, 5], 'daysOfWeek').all();

    default:
      return dayjs().recur({ start, end: until }).all();
  }
};

const dateAndTime = (date: Dayjs, time: Dayjs) => {
  const d = date.format('YYYY-MM-DD');
  const t = time.format('HH:mm');
  return dayjs(`${d}T${t}`);
};

export {
  eventColors,
  ensureDate,
  niceDate,
  calculateDateOffset,
  getDaytimeGreeting,
  dateToDayMonthYear,
  dayjsToDayMonthYear,
  compareDayMonthYear,
  findClosestEventToNow,
  dateAndTime,
  getTimespan,
  isBefore,
  isAfter,
  isSameOrAfter,
  isSameOrBefore,
  isSame,
  isBetween,
  getDaysDifference,
  buildRecurrences,
};
