import { CoursesIcon, Row, Table, TableColumns, useDefaultSorting } from '@bp/ui-components';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseTableType } from './CoursesTableTeacher';
import { BpLink } from '../../components/BpLink/BpLink';

type CoursesTableStudentProps = {
  data: CourseTableType[];
  onNavigate: (uuid: string) => void;
};

export const CoursesTableStudent: FC<CoursesTableStudentProps> = ({ data, onNavigate }) => {
  const { t } = useTranslation();

  const { sorting, onSortingChange } = useDefaultSorting();

  // TODO: assignment count indicator
  // const [assignmentCount] = useBpAssignmentsCountQuery({
  //   context: context,
  //   variables: {
  //     where: {
  //       uuid: grpUuid,
  //     },
  //   },
  // });

  // const counts: (number | undefined)[] | undefined = assignmentsCount.data?.groups
  //   .map((group) => {
  //     return group.teachingUnitsConnection.edges.map((edge) => edge.node.assignmentsAggregate?.count);
  //   })
  //   .flat();

  const columns: TableColumns<CourseTableType>[] = useMemo(() => {
    return [
      {
        size: 500,
        canExpand: true,
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          return (
            <BpLink
              value={row.original.name}
              onNavigate={onNavigate ? () => onNavigate(row.original.uuid) : undefined}
              hasNews={row.original.newMessage}
              hasNewsHint={t('groups.newMessageHint')}
              inProgress={row.original.meetingInProgress}
              counter={row.original.count}
              isEditorOfCourse={false}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-cy='courseList'>
      <Table<CourseTableType>
        data={data}
        columns={columns}
        canScroll
        showSort
        sorting={sorting}
        onSortingChange={onSortingChange}
        showActionBar
        isOnWhite={false}
        hideHeader={data.length === 0}
        showBorderRadius
        showShadow
        emptyStateSettings={{
          icon: <CoursesIcon className='svg-icon primary-light' />,
          padding: 'xl',
        }}
      />
    </div>
  );
};
